/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ContainerIsoCode } from './containerIsoCode';
import { OperationalFile } from './operationalFile';
import { OperationalFileContainerGoods } from './operationalFileContainerGoods';
import { OperationalFileContainerSealNumber } from './operationalFileContainerSealNumber';
import { OperationalFileTransportOperationalFileContainer } from './operationalFileTransportOperationalFileContainer';

export interface OperationalFileContainer { 
    rowCreatedTimestamp?: Date;
    lastRowModificationTimestamp?: Date;
    isDeleted?: boolean;
    id?: number;
    containerNumber?: string;
    cargoWeight?: number;
    containerTare?: number;
    grossVolume?: number;
    temperature?: number;
    humidity?: number;
    ventSettings?: number;
    isGeneratorRequired?: boolean;
    isOperationalReefer?: boolean;
    isShipperOwned?: boolean;
    containerIsoCodeId?: number;
    containerIsoCode?: ContainerIsoCode;
    operationalFileId?: number;
    operationalFile?: OperationalFile;
    operationalFileContainerGoods?: Array<OperationalFileContainerGoods>;
    containerSealNumbers?: Array<OperationalFileContainerSealNumber>;
    operationalFileTransports?: Array<OperationalFileTransportOperationalFileContainer>;
    vgmTimestamp?: Date;
    vgmStatus?: OperationalFileContainer.VgmStatusEnum;
    vgmWeight?: number;
    equipmentAirFlow?: number;
    equipmentAirFlowUomType?: OperationalFileContainer.EquipmentAirFlowUomTypeEnum;
}
export namespace OperationalFileContainer {
    export type VgmStatusEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6;
    export const VgmStatusEnum = {
        NUMBER_0: 0 as VgmStatusEnum,
        NUMBER_1: 1 as VgmStatusEnum,
        NUMBER_2: 2 as VgmStatusEnum,
        NUMBER_3: 3 as VgmStatusEnum,
        NUMBER_4: 4 as VgmStatusEnum,
        NUMBER_5: 5 as VgmStatusEnum,
        NUMBER_6: 6 as VgmStatusEnum
    };
    export type EquipmentAirFlowUomTypeEnum = 0 | 1;
    export const EquipmentAirFlowUomTypeEnum = {
        NUMBER_0: 0 as EquipmentAirFlowUomTypeEnum,
        NUMBER_1: 1 as EquipmentAirFlowUomTypeEnum
    };
}