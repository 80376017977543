import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject, from, of } from "rxjs";
import { concatMap, switchMap, takeUntil, tap, take, delay } from "rxjs/operators";
import { ConfirmDialogData, ConfirmDialogResultData } from "../../../core/models/dialogs/confirm-dialog-data";
import { IncomingInvoicesService, UblResultDTO } from "../../../core/services/swagger-gen/fordesk";
import { ToasterService } from "../../layout/toaster/toaster.service";
import { ConfirmDialog } from "../confirm/confirm.component";
import { FilterCacheService } from "../../../core/services/utilities/filter-cache.service";
import { INCOMING_INVOICE_STORAGE } from "../../../core/services/helpers/local-storage-keys";
import { StoredListViewProperties } from "../../../core/models/utilities/stored-list-view-properties";

@Component({
  selector: "upload-ubl-dialog",
  templateUrl: "./upload-ubl-dialog.component.html",
  styleUrls: ["./upload-ubl-dialog.component.scss"]
})
// tslint:disable-next-line: component-class-suffix
export class UploadUblDialog implements OnInit, OnDestroy {
  public filesToUpload: File[] = [];
  @ViewChild("uploader", { static: false }) private uploaderInput: ElementRef;

  private unsubscribe: Subject<void> = new Subject();
  public hasFileName: boolean = false;

  constructor(
    public dialog: MatDialog,
    public modal: NgbActiveModal,
    private router: Router,
    private toaster: ToasterService,
    private translate: TranslateService,
    private incomingInvoicesService: IncomingInvoicesService,
    public filterCacheService: FilterCacheService<string>) {
  }

  public ngOnInit(): void {
  }

  public addFileToUploader(event) {
    if (event && event[0]) {

      const allowedTypes = new Set([
        'application/pdf',
        'text/xml',
        'image/png',
        'image/jpg',
        'image/jpeg',
        'image/tiff'
      ]);

      const files = event as any[];
      for (let i = 0; i < files.length; i++) {
        if (!allowedTypes.has(files[i].type)) {
          this.toaster.error(this.translate.instant("FINANCE.INCOMING_INVOICES_DETAILS.UPLOAD_PDF_OR_IMAGE"));
          return;
        }

        if (this.filesToUpload.some(x => x.name == files[i].name)) {
          continue;
        }
        else {
          this.filesToUpload.push(files[i]);
        }
      }
      this.hasFileName = true;
    }
  }

  public deleteAttachment(fileName) {
    this.filesToUpload = this.filesToUpload.filter(x => x.name != fileName)
    if (this.filesToUpload.length === 0) {
      this.hasFileName = false;
    }
    this.uploaderInput.nativeElement.value = null;
  }

  public uploadFile(): void {
    let savedInvoices: UblResultDTO[] = [];
    this.modal.close()//close selected documents modal window
    if (this.filesToUpload.length > 0) {
      from(this.filesToUpload)
        .pipe(
          concatMap(file =>//send request to create incoming invoice we use concat map to send request pairs (createInvoiceFromUBLForm, confirmDuplicate) one by one
            this.incomingInvoicesService.createInvoiceFromUBL(file).pipe(
              takeUntil(this.unsubscribe),
              switchMap((response: UblResultDTO) => {
                if (response.isDuplicate) {//If dublicate
                  return this.confirmDuplicate(file.name).pipe(
                    takeUntil(this.unsubscribe),
                    switchMap((result: ConfirmDialogResultData) => {
                      if (result.isConfirmed) {
                        return this.incomingInvoicesService.unDeleteIncomingInvoice(response.incomingInvoiceId).pipe(
                          takeUntil(this.unsubscribe),
                          switchMap(() => {
                            savedInvoices.push(response);
                            return of(null);
                          })
                        );
                      } else {
                        return of(null);
                      }
                    })
                  );
                } else {
                  savedInvoices.push(response);
                  return of(null);
                }
              })
            )
          ),
          takeUntil(this.unsubscribe)
        )
        .subscribe({
          complete: () => {
            if (savedInvoices?.length > 0) {
              this.openCreatedInvoicesList(savedInvoices);
            }
            this.modal.close()
          }
        });
    }
  }

  private confirmDuplicate(documentName: string): Observable<ConfirmDialogResultData> {
    const dialogData: ConfirmDialogData = {
      confirmationText: this.translate.instant("FINANCE.INCOMING_INVOICES_DETAILS.MESSAGES.ALREADY_EXISTS", { documentName }),
      dialogTitle: this.translate.instant("MESSAGES.CONFIRM_TO_CONTINUE")
    };

    return this.dialog
      .open(ConfirmDialog, { disableClose: true, data: dialogData })
      .afterClosed();
  }

  private openCreatedInvoicesList(ublResultDTOs: UblResultDTO[]) {
    if (!ublResultDTOs.length) {
      return;
    } else if ((ublResultDTOs.length == 1)) {
      const incomingInvoiceId = ublResultDTOs[0].incomingInvoiceId;
      this.router.navigateByUrl(`finance/incoming-invoices/${incomingInvoiceId}/details`);
    } else {
      const filterDescription = this.translate.instant("FINANCE.INCOMING_INVOICES_LIST.ACCOUNTING_NUMBER");
      const newIncomingInvoiceListFilter = <StoredListViewProperties<string>>{
        currentPage: 0,
        filters: ublResultDTOs.map(ublResultDTO => ({
          type: "accountingNumber",
          description: filterDescription,
          value: ublResultDTO.draftNumber,
        })),
      };

      const currentIncomingInvoiceListFilter = this.filterCacheService.get(INCOMING_INVOICE_STORAGE);
      if (currentIncomingInvoiceListFilter) {
        newIncomingInvoiceListFilter.pageSize = currentIncomingInvoiceListFilter.pageSize;
        newIncomingInvoiceListFilter.sortDirection = currentIncomingInvoiceListFilter.sortDirection;
        newIncomingInvoiceListFilter.sortProperty = currentIncomingInvoiceListFilter.sortProperty;
      }

      this.filterCacheService.save(INCOMING_INVOICE_STORAGE, newIncomingInvoiceListFilter, true);
      this.router.navigate(["/reload"], { skipLocationChange: true, queryParams: { returnUrl: "finance/incoming-invoices/list" } });
    }
  }

  public ngOnDestroy(): void {
    if (!this.unsubscribe || !this.unsubscribe.observers || this.unsubscribe.observers.length <= 0) {
      return;
    }

    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
