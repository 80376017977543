/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface IncomingInvoiceDetailsDTO { 
    invoiceCommentId?: number;
    invoiceTypeId?: number;
    protestReason?: string;
    protestTimestamp?: Date;
    invoiceNumber?: string;
    currencyId?: number;
    amountExclVat?: number;
    amountInclVat?: number;
    amountVat?: number;
    externalDocumentFileName?: string;
    payableAmount?: number;
    lineExtensionAmount?: number;
    status?: IncomingInvoiceDetailsDTO.StatusEnum;
    operationalFileNumbers?: Array<string>;
    supplierId?: number;
    issueDate?: Date;
    dueDate?: Date;
    paidDate?: Date;
    sepaExportedDate?: Date;
    defaultIncomingTaxCodeId?: number;
    defaultIncomingTaxSystemId?: number;
    exchangeRate?: number;
    accountingNumber?: string;
    draftNumber?: string;
    isCreditNote?: boolean;
    exportTimestamp?: Date;
    successfullyExported?: boolean;
    paymentReference?: string;
}
export namespace IncomingInvoiceDetailsDTO {
    export type StatusEnum = 1 | 2 | 3 | 4 | 5;
    export const StatusEnum = {
        NUMBER_1: 1 as StatusEnum,
        NUMBER_2: 2 as StatusEnum,
        NUMBER_3: 3 as StatusEnum,
        NUMBER_4: 4 as StatusEnum,
        NUMBER_5: 5 as StatusEnum
    };
}