/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FoundIncomingInvoiceDTO { 
    id?: number;
    documentReference?: string;
    fileReference?: string;
    protestReason?: string;
    protestTimestamp?: Date;
    invoiceNumber?: string;
    accountingNumber?: string;
    draftNumber?: string;
    supplierName?: string;
    amountExclVat?: number;
    amountVat?: number;
    amountInclVat?: number;
    issueDate?: Date;
    dueDate?: Date;
    creationTimestamp?: Date;
    paidDate?: Date;
    sepaExportedDate?: Date;
    currencyCode?: string;
    commentCode?: string;
    comment?: string;
    status?: FoundIncomingInvoiceDTO.StatusEnum;
    statusDescription?: string;
    accountingReference?: string;
    externalDocumentFileName?: string;
    type?: string;
    paymentReference?: string;
}
export namespace FoundIncomingInvoiceDTO {
    export type StatusEnum = 1 | 2 | 3 | 4 | 5;
    export const StatusEnum = {
        NUMBER_1: 1 as StatusEnum,
        NUMBER_2: 2 as StatusEnum,
        NUMBER_3: 3 as StatusEnum,
        NUMBER_4: 4 as StatusEnum,
        NUMBER_5: 5 as StatusEnum
    };
}