export enum PartyType {
  Default = 0,
  InvoiceCustomer = 1,
  ClearanceParty = 2,
  Customs = 3,
  SecondNotify = 4,
  Agent = 5,
  Consignee = 6,
  CustomsAgent = 7,
  Customer = 8,
  DeliveryAddress = 9,
  HConsignee = 10,
  HNotify = 11,
  HShipper = 12,
  InsuranceBroker = 13,
  LoadingAddress = 14,
  Notify = 15,
  Other = 16,

  /// <summary>
  ///     Also known as the carrier.
  /// </summary>
  ShippingAgent = 17,
  Shipper = 18,
  TransportCompany = 19,
  WarehouseOperator = 20,
  WeighingVGM = 21,
  AuthorizedVGM = 22,
  ResponsibleVGM = 23,
  HSecondNotify = 24,
  Importer = 25,
  Prospect = 26,
}
