import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, ReplaySubject } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { AuthUser } from '../../../models/user.model';
import { UserMgtUsersService } from '../../user-mgt/services/user-mgt.-users.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public authUser$: BehaviorSubject<AuthUser | undefined> = new BehaviorSubject<AuthUser | undefined>(undefined);

  constructor(
    private msalService: MsalService,
    private userMgtUsersService: UserMgtUsersService) { }

  public msalUser$ = new ReplaySubject<AccountInfo>(1);

  public getCurrentUser(): AuthUser | undefined {
    return this.authUser$.value;
  }

  public initializeUser(): Observable<boolean> {
    const user = this.msalService.instance.getActiveAccount();
    if (!user) {
      return of(true);
    }

    this.msalUser$.next(user);

    return of(true);
  }

  public loadUserProperties(user: AccountInfo): Observable<boolean> {
    const userId = user?.localAccountId;

    if (!userId) {
      throw new Error('UserId is null from AccountInfo. Unable to load User Details');
    }

    return this.userMgtUsersService.getUserProperties({ userId })
      .pipe(map((response) => this.handleUserProperties(response, user)));
  }

  private handleUserProperties(res: any, user: any): boolean {
    try {
      const authUser = new AuthUser(res.givenName, res.id, res.surname, res.displayName, res.email, res.properties);

      this.authUser$.next(authUser);

      return true;
    } catch (error) {
      return false;
    }
  }

  public logout() {
    localStorage.clear();
    this.msalService.logout();
  }
}
