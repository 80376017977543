/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FoundQuoteFileDTO { 
    id?: number;
    fileNumber?: string;
    invoiceCustomerName?: string;
    customerName?: string;
    customerReference?: string;
    containersAmount?: number;
    goodsAmount?: number;
    documentDate?: Date;
    validDate?: Date;
    deadlineDate?: Date;
    creationDate?: Date;
    totalExclVat?: number;
    totalInclVat?: number;
    vatAmount?: number;
    status?: FoundQuoteFileDTO.StatusEnum;
    portOfLoading?: string;
    portOfDischarge?: string;
    currencyCode?: string;
    internalSubject?: string;
    operationalFileId?: number;
    createdBy?: string;
    carrier?: string;
    result?: number;
    rowVersion?: string;
    forProspect?: boolean;
    prospectName?: string;
}
export namespace FoundQuoteFileDTO {
    export type StatusEnum = 0 | 1 | 2 | 3 | 4;
    export const StatusEnum = {
        NUMBER_0: 0 as StatusEnum,
        NUMBER_1: 1 as StatusEnum,
        NUMBER_2: 2 as StatusEnum,
        NUMBER_3: 3 as StatusEnum,
        NUMBER_4: 4 as StatusEnum
    };
}