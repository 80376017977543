import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  isAuthenticated = this.msalService.instance.getAllAccounts().length > 0;

  constructor(private msalService: MsalService, private userService: UserService) {
    // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalService.instance.enableAccountStorageEvents();
  }

  public checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    if (!this.msalService.instance.getActiveAccount() && this.msalService.instance.getAllAccounts().length > 0) {
      const accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
      this.isAuthenticated = true;
    }

    if (this.msalService.instance.getActiveAccount()) {
      this.userService.initializeUser();
    }
  }
}
