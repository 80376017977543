import { Component, Inject, OnInit } from "@angular/core";
import { ComponentBase } from "../../../core/components/abstractions/component-base";
import { CompanyDuplicateDTO } from "../../../core/services/swagger-gen/fordesk";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CompanyType } from "../../../core/enumerations/company-type.enum";

export class CompanyDuplicatesDialogData {
  public companies: CompanyDuplicateDTO[];
  public companyType: CompanyType;
  public isEditing?: boolean = false;
}

export class CompanyDuplicatesDialogResult {
  public isConfirmed: boolean;
}

@Component({
  selector: "company-duplicates-dialog",
  templateUrl: "./company-duplicates-dialog.html",
  styleUrls: ["./company-duplicates-dialog.scss"]
})
export class CompanyDuplicatesDialogComponent extends ComponentBase implements OnInit {
  public companies: CompanyDuplicateDTO[] = [];
  public currentCompanyType: CompanyType;
  public CompanyType = CompanyType;
  public isEditing: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CompanyDuplicatesDialogData,
    private dialogRef: MatDialogRef<CompanyDuplicatesDialogComponent>) {
    super();
  }

  public ngOnInit() {
    this.companies = this.data.companies;
    this.currentCompanyType = this.data.companyType;
    this.isEditing = this.data.isEditing;
  }

  public createNewCompany() {
    this.dialogRef.close(<CompanyDuplicatesDialogResult>{ isConfirmed: true });
  }

  public close() {
    this.dialogRef.close(<CompanyDuplicatesDialogResult>{ isConfirmed: false });
  }

  public navigateToCompany(companyId: number) {
    const url = window.location.origin + "/masterdata/companies/customers/" + companyId + "/details";
    window.open(url, '_blank');
  }
}