export const of_containers_locale = {
  RELEASE_RIGHT: {
    HANDLE_RELEASE_RIGHT: "handle release right",
    BILL_OF_LADING: "Bill of Lading",
    RELEASE_RIGHT: "Release right",
    CERTIFIED_CONTAINER_PICK_UP: "Certified container pick-up",
    CONTAINER: "Container",
    PARTY: "Party",
    ACCEPT: "Accept",
    DECLINE: "Decline",
    DECLINE_SUCCESS: "The container release right was successfully declined.",
    ACCEPT_SUCCESS: "The container release right was successfully accepted.",
    PICK_UP_LOCATION: "Pick-up location",
    STATUS: "Status",
    RECEIVED_FROM: "Received from",
    RECEIVED_ON: "Received on",
    VALID_FROM: "Valid from",
    VALID_TO: "Valid to",
    ACCEPT_CONTAINER: "Accept release right and link to a container",
    REJECT_CONTAINER: "Reject release right",
    OPERATIONAL_FILE: "Operational file",
    REASON: "Reason",
    TRANFER_RELEASE_RIGHT: "Transfer release right",
    REVOKE_RELEASE_RIGHT: "Revoke release right transfer",
    REVOKE_MESSAGE: "Since the next party has not yet accepted or declined the release right transfer you sent them, you can cancel this transfer, returning the right to you.",
    MESSAGES: {
      PLEASE_CONFIRM: "Please confirm to continue.",
      REVOKE_REQUEST_SENT: "Revoke request successfully sent.",
      TRANSFER_REQUEST_SENT: "Transfer request successfully sent.",
    },
    STATUSES: {
      OFFERED_TO_US: "Offered to us",
      DECLINED_BY_US: "Declined",
      ACCEPTED_BY_US: "Accepted",
      OWNED_BY_US: "Owned",
      OFFERED_BY_US: "Offered by us",
      NOT_OWNED_BY_US: "Not owned",
      REVOKED_BY_US: "Revoked by us",
      TRANSFERRED_TO_NEXT_PARTY: "Transferred",
      UNKNOWN: "Unknown"
    }
  },
  ADD_CONTAINER: "add container",
  TEMPERATURE: "Temperature",
  HUMIDITY: "Humidity",
  VENT_SETTINGS: "Vent settings",
  EQUIPMENT_AIR_FLOW: "Equipment air flow",
  UOM_TYPE: "UOM",
  REEFER: "Reefer",
  SHIPPER_OWNED: "Shipper owned",
  CONTAINER_TARE: "Tare container",
  IS_GENERATOR_REQUIRED: "Generator required",
  IS_OPERATIONAL_REEFER: "Operational reefer",
  CARGO_WEIGHT_OR_VOLUME_WAS_RECALCULATED: "The weight or volume of the cargo was recalculated. Do not forget to save the changes so as not to lose data.",
  NON_STANDARD_CONTAINERNUMBER: "Non-standard container no. detected.",
  NOT_EQUAL_GOODS_WEIGHT_AND_CARGO_WEIGHT: "The Cargo Weight does not match the sum of weights of goods in the container ({{goodsWeight}}).",
  NOT_EQUAL_GOODS_GROSS_VOLUME: "The Cargo volume does not match the sum of volume of goods in the container ({{calculatedGrossVolume}}).",
  CARGO_WEIGHT: "Cargo weight",
  CONTAINER_NUMBER: "Container number",
  SIZE_TYPE: "Size type",
  SEAL_TYPE: "Container seal type",
  VALUE: "Value",
  DETAILS: "Container details",
  SEALNUMBERS: "Seal numbers",
  NO_OF_GOODS: "# of goods",
  NO_OF_COLLI: "# of colli",
  TARE: "Tare container",
  GOODS: "Goods",
  COPY_CONTAINER_TIMES: "How many copies of this/these container(s) would you like?",
  COPY_CONTAINER_TITLE: "Container(s) copy",
  MESSAGES: {
    ADDED: "The containers have been successfully added!",
    COPIED: "The selected containers have been copied {{times}} times.",
    VGMS_SENT: "The VGM(s) have been sent successfully.",
    VGMS_CANCELLED: "The VGM(s) cancellation has been sent successfully.",
    VGMS_ERROR: "An error occurred while attempting to send the VGM(s).",
    VGMS_CANCEL_ERROR: "An error occured while attempting to cancel the selected VGM(s).",
    IMPORT_REQUEST_SUCCEEDED: "The import request for containers was successfully sent.",
    OPERATIONAL_FILE_CONTAINERS_MAX_QUANTITY_EXCEEDED: "Operational files cannot contain more than 200 containers."
  },
  DESCRIPTION: "Description",
  SEND_VGM: "send VGM",
  CANCEL_VGM: "cancel VGM",
  CARGO_WEIGHT_OR_VOLUME_WAS_RECALCULATED_TITLE: "The weight or volume of the cargo was recalculated",
  SEND_VGM_TITLE: "Send VGM information",
  SEND_VGM_CONFIRM_NO_NUMBER: "Are you sure you want to send the VGM information for the selected container?",
  CANCEL_VGM_CONFIRM_NO_NUMBER: "Are you sure you want to cancel the VGM message for the selected container?",
  SEND_VGM_CONFIRM_PLURAL: "Are you sure you want to send the VGM information for the selected {{count}} containers?",
  CANCEL_VGM_CONFIRM_PLURAL: "Are you sure you want to cancel the VGM message for the selected {{count}} containers?",
  SEND_VGM_CONFIRM_SINGULAR: "Are you sure you want to send the VGM information for container '{{containerNumber}}'?",
  CANCEL_VGM_CONFIRM_SINGULAR: "Are you sure you want to cancel the VGM message for container '{{containerNumber}}'?",
  VGM_STATUS: "VGM Status",
  VGM_TIMESTAMP: "VGM Timestamp",
  VGM_WEIGHING_PARTY: "A VGM weighing party has been included in this operational file. Please select the weighing method.",
  VGM_WEIGHING_METHOD: "Weighing method",
  VGM_WEIGHT: "VGM weight",
  SEAL_NUMBERS: "Seal numbers",
  GROSS_VOLUME: "Gross volume",
  INTTRA_STATE_MESSAGE: "VGM type",
  AUTHORIZED_CONTACT_PERSON: "Authorized contact person",
  SUBMISSION_DATE: "Submission date",
  AUTHORIZED_CONTACT_PERSON_PHONE_NR: "Phone nr.",
  NO_CONTAINERS_FOUND: "No containers in operational file.",
  NO_CONTAINERS_REMAINING: "No unassigned containers remaining.",
  NO_CONTAINERNUMBERS_FOUND: "No container numbers in operational file.",
  CONTAINERS: "Containers",
  ERRORS: {
    DUPLICATE_CONTAINER_NUMBER: "You already assigned this container number. They have to be unique in each operational file.",
    GROSS_VOLUME_PRECISION: "Gross volume can have up to 4 precision.",
    ONLY_UPLOAD_CSV: "Only .CSV files are allowed",
    UPLOAD_FILE: "Please select a file to upload"
  },
  VALIDATION: {
    BL_NUMBER_OR_BOOKING_REF_REQUIRED: "Either a bill of lading number or booking reference is required."
  },
  NO_DESCRIPTION: "no description",
  IMPORT_FROM_FILE: "Import containers from file",
  SELECT_CSV_OR_XLSX: "Please select a .CSV or .XLSX file to import containers in {{operationalFileNumber}}.",
  BILL_OF_LADING_NUMBER: "Bill of lading number",
  BOOKING_REFERENCE: "Booking reference",
  NO_CONTAINERS_GOODS_FOUND: "No goods in container."
};
