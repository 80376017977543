/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface TransportMode { 
    rowCreatedTimestamp?: Date;
    lastRowModificationTimestamp?: Date;
    isDeleted?: boolean;
    id?: number;
    code?: string;
    description?: string;
    value?: TransportMode.ValueEnum;
    nctsEdiValue?: TransportMode.NctsEdiValueEnum;
    pldaEdiValue?: TransportMode.PldaEdiValueEnum;
}
export namespace TransportMode {
    export type ValueEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
    export const ValueEnum = {
        NUMBER_0: 0 as ValueEnum,
        NUMBER_1: 1 as ValueEnum,
        NUMBER_2: 2 as ValueEnum,
        NUMBER_3: 3 as ValueEnum,
        NUMBER_4: 4 as ValueEnum,
        NUMBER_5: 5 as ValueEnum,
        NUMBER_6: 6 as ValueEnum,
        NUMBER_7: 7 as ValueEnum,
        NUMBER_8: 8 as ValueEnum,
        NUMBER_9: 9 as ValueEnum
    };
    export type NctsEdiValueEnum = 10 | 12 | 16 | 17 | 18 | 20 | 23 | 30 | 40 | 50 | 70 | 80 | 90;
    export const NctsEdiValueEnum = {
        NUMBER_10: 10 as NctsEdiValueEnum,
        NUMBER_12: 12 as NctsEdiValueEnum,
        NUMBER_16: 16 as NctsEdiValueEnum,
        NUMBER_17: 17 as NctsEdiValueEnum,
        NUMBER_18: 18 as NctsEdiValueEnum,
        NUMBER_20: 20 as NctsEdiValueEnum,
        NUMBER_23: 23 as NctsEdiValueEnum,
        NUMBER_30: 30 as NctsEdiValueEnum,
        NUMBER_40: 40 as NctsEdiValueEnum,
        NUMBER_50: 50 as NctsEdiValueEnum,
        NUMBER_70: 70 as NctsEdiValueEnum,
        NUMBER_80: 80 as NctsEdiValueEnum,
        NUMBER_90: 90 as NctsEdiValueEnum
    };
    export type PldaEdiValueEnum = 1 | 2 | 3 | 4 | 5 | 7 | 8 | 9;
    export const PldaEdiValueEnum = {
        NUMBER_1: 1 as PldaEdiValueEnum,
        NUMBER_2: 2 as PldaEdiValueEnum,
        NUMBER_3: 3 as PldaEdiValueEnum,
        NUMBER_4: 4 as PldaEdiValueEnum,
        NUMBER_5: 5 as PldaEdiValueEnum,
        NUMBER_7: 7 as PldaEdiValueEnum,
        NUMBER_8: 8 as PldaEdiValueEnum,
        NUMBER_9: 9 as PldaEdiValueEnum
    };
}