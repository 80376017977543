import { Component, Input } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "please-configure-user-data",
  templateUrl: "./please-configure-user-data.component.html",
  styleUrls: ["./please-configure-user-data.component.scss"]
})
export class ConfugureUserDataComponent {
  constructor(public dialogRef: NgbActiveModal
  ) { }

  public openProfile() {
    this.dialogRef.close();
  }
}
