export const quotes_displayed_columns = {
  fileNumber: "Accounting number",
  customerInvoiceCustomer: "Customer / Invoice customer",
  containersGoods: "Containers / Goods ",
  ports: "P.o.L / P.o.D",
  validDate: "Document date / Valid date",
  deadlineDate: "Deadline date",
  amountExclVat: "Total excluding VAT",
  vatAmount: "VAT amount",
  amountInclVat: "Total including VAT",
  internalSubject: "Internal subject",
  carrier: "Carrier",
  customerReference: "Customer reference",
  result: "Result",
  createdBy: "Created by",
  status: "Status",
  creationDate: "Creation date"
};
