export enum MasterDataEntityTypeEnum {
  Place = 0,
  Country,
  Port,
  Terminal,
  Depot,
  Vessel,
  InvoiceComment,
  StepTemplate,
  UnitOfMeasurement,
  ContainerIsoCode,
  HsCode,
  Incoterm,
  ContactPersonType,
  ImoClassification,
  SealNumberType,
  PartyType,
  PackageType,
  ActivityCode,
  Step,
  StandardTransportStop,
  Company,
  CompanyAddress,
  OcrSetting
}
