import { Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TenantConfigurationService } from "../../core/services/client-tenant-configuration/tenant-config.service";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { TenantSynchronizationService } from "../../core/services/tenants-synchronization.service";
import { filter, switchMap, take } from "rxjs";

@Component({
  selector: "default-page-routing",
  template: "",
})
export class DefaultPageRoutingComponent implements OnInit {
  constructor(
    @Inject(LOCAL_STORAGE) private storage: StorageService,
    private router: Router,
    private tenantConfig: TenantConfigurationService,
    private tenantSynchronizationService: TenantSynchronizationService) { }

  public ngOnInit() {
    this.redirectToDefaultPage();
  }

  redirectToDefaultPage() {
    this.tenantSynchronizationService.getTenantChangedObservable$()
      .pipe(
        filter(tenant => !!tenant),
        take(1),
        switchMap(() => this.tenantConfig.initialize())
      )
      .subscribe(config => {
        if (config?.moduleSettings?.dashboards && this.storage.get("SET_DASHBOARD_PAGE_AS_DEFAULT") !== 'false') {
          this.router.navigate(['/dashboards']);
        } else {
          this.router.navigate(['/operational/files']);
        }
      });
  }

}
