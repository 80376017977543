/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FoundCompanyDTO { 
    id?: number;
    accountingNumber?: string;
    companyName?: string;
    zipCode?: string;
    place?: string;
    countryName?: string;
    messageType?: string;
    status?: FoundCompanyDTO.StatusEnum;
    vatNumber?: string;
    isDeleted?: boolean;
    addressLine1?: string;
    addressLine2?: string;
    alias?: string;
    stateProvinceRegion?: string;
    isBlocked?: boolean;
}
export namespace FoundCompanyDTO {
    export type StatusEnum = 0 | 1 | 2;
    export const StatusEnum = {
        NUMBER_0: 0 as StatusEnum,
        NUMBER_1: 1 as StatusEnum,
        NUMBER_2: 2 as StatusEnum
    };
}