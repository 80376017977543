/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { OutgoingTaxCode } from './outgoingTaxCode';

export interface ActivityCodeGroup { 
    rowCreatedTimestamp?: Date;
    lastRowModificationTimestamp?: Date;
    isDeleted?: boolean;
    id?: number;
    masterDataStatus?: ActivityCodeGroup.MasterDataStatusEnum;
    timeOfBlocking?: Date;
    description?: string;
    vatChargeCode?: ActivityCodeGroup.VatChargeCodeEnum;
    outgoingTaxCodes?: Array<OutgoingTaxCode>;
}
export namespace ActivityCodeGroup {
    export type MasterDataStatusEnum = 0 | 1;
    export const MasterDataStatusEnum = {
        NUMBER_0: 0 as MasterDataStatusEnum,
        NUMBER_1: 1 as MasterDataStatusEnum
    };
    export type VatChargeCodeEnum = 0 | 1 | 2 | 3;
    export const VatChargeCodeEnum = {
        NUMBER_0: 0 as VatChargeCodeEnum,
        NUMBER_1: 1 as VatChargeCodeEnum,
        NUMBER_2: 2 as VatChargeCodeEnum,
        NUMBER_3: 3 as VatChargeCodeEnum
    };
}