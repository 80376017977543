import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { BehaviorSubject, forkJoin, Observable, of, Subject } from "rxjs";
import { concatMap, map, mergeMap, switchMap, takeUntil, tap, throttleTime } from "rxjs/operators";
import AddGridDialogData from "../../../models/utilities/grid-layouts/add-grid-dialog-data.model";
import { GetAllResponseGridLayoutLookupDTO, GridLayoutLookupDTO, GridLayoutsService } from "../../../services/swagger-gen/fordesk";
import { GridLayoutCacheService } from "../../../services/utilities/grid-layout-cache.service";
import { GridType } from "../../../types/grid-type.type";
import { ComponentBase } from "../../abstractions/component-base";
import { AddGridLayoutComponent } from "../add-grid-layout/add-grid-layout.component";
import { ManageGridLayoutsDialogData } from "../../../models/utilities/grid-layouts/manage-grid-layout-dialog-data.model";
import { ManageGridLayoutsComponent } from "../manage-grid-layouts/manage-grid-layouts.component";
import { ToasterService } from "../../../../content/layout/toaster/toaster.service";
import { TranslateService } from "@ngx-translate/core";
import { GridLayoutCommunicationService } from "../../../services/grid/grid-layout-communication.service";
import { GridLayoutUpserted } from "../../../models/utilities/grid-layouts/grid-layout-upserted.model";
import { UserPermissionService } from "../../../auth/fordesk/services/user.permissions.service";

@Component({
  selector: "grid-layout-dropdown",
  templateUrl: "./grid-layout-dropdown.component.html",
  styleUrls: ["./grid-layout-dropdown.component.scss"]
})
export class GridLayoutDropdownComponent extends ComponentBase implements OnInit {
  @Input()
  public gridType: GridType;

  @Input()
  public currentSelectedGridId: number;

  @Output()
  public layoutSelected: EventEmitter<GridLayoutLookupDTO> = new EventEmitter<GridLayoutLookupDTO>();

  @Output()
  public popupClosed: EventEmitter<void> = new EventEmitter<void>();

  public loadLayoutsAfterPanelSwitch$: Observable<GridLayoutLookupDTO[][]>;
  public loadLayoutsAfterNewGridLayoutCreated$: Observable<[string, GridLayoutLookupDTO[][]]>;

  public isSharedPanel$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private newGridCreated$: Subject<string> = new Subject<string>();

  public sharedLayouts: GridLayoutLookupDTO[];
  public personalLayouts: GridLayoutLookupDTO[];
  public hasAdminRole: boolean = false;

  constructor(private gridLayoutService: GridLayoutsService,
    private gridLayoutCacheService: GridLayoutCacheService,
    private dialog: MatDialog,
    private toaster: ToasterService,
    private translate: TranslateService,
    private gridLayoutCommunicationService: GridLayoutCommunicationService,
    private permissionService: UserPermissionService) {
    super();
  }

  public ngOnInit(): void {
    this.setLoaders();
    this.setAdminStatus();
    this.setSubscriptions();
  }

  private setLoaders() {
    this.loadLayoutsAfterPanelSwitch$ = this.isSharedPanel$.pipe(
      throttleTime(10000),
      takeUntil(this.unsubscribe),
      mergeMap(() => this.setLayoutApiCall())
    );

    this.loadLayoutsAfterNewGridLayoutCreated$ = this.newGridCreated$.pipe(
      takeUntil(this.unsubscribe),
      switchMap((name) => forkJoin([of(name), this.setLayoutApiCall()])));
  }

  private setAdminStatus() {
    this.permissionService.userHasAdminPermission$().pipe(takeUntil(this.unsubscribe))
      .subscribe(hasRole => this.hasAdminRole = hasRole);
  }

  private setSubscriptions() {
    this.loadLayoutsAfterPanelSwitch$
      .subscribe((layouts) => this.setLayoutsAfterPanelSwitch(layouts));

    this.loadLayoutsAfterNewGridLayoutCreated$
      .subscribe(([name, layouts]) => {
        this.setLayoutsAfterNewGridLayoutCreated(name, layouts);
        this.popupClosed.emit();
      });

    this.gridLayoutCommunicationService.getLayoutDeleted$()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(deletedView => {
        if (!deletedView) {
          return;
        }

        if (deletedView.shared) {
          this.sharedLayouts = this.sharedLayouts.filter(x => x.id !== deletedView.id);
        } else {
          this.personalLayouts = this.personalLayouts.filter(x => x.id !== deletedView.id);
        }
      });

    this.gridLayoutCommunicationService.getLayoutEdited$()
      .pipe(takeUntil(this.unsubscribe),
        tap(editedView => this.selectPanelType(editedView.shared)),
        switchMap(_ => {
          return this.setLayoutApiCall();
        }))
      .subscribe(layouts => {
        [this.sharedLayouts, this.personalLayouts] = layouts;
      });
  }

  private setLayoutsAfterPanelSwitch(layouts: GridLayoutLookupDTO[][]): void {
    [this.sharedLayouts, this.personalLayouts] = layouts;
    this.initSelectedTab([...this.sharedLayouts, ...this.sharedLayouts], this.currentSelectedGridId);
  }

  private setLayoutsAfterNewGridLayoutCreated(name: string, layouts: GridLayoutLookupDTO[][]): void {
    [this.sharedLayouts, this.personalLayouts] = layouts;

    const layout = this.sharedLayouts.find(x => x.name === name) ?? this.personalLayouts.find(x => x.name === name);

    if (layout) {
      this.onLayoutSelected(layout);
    }
    this.initSelectedTab([...this.sharedLayouts, ...this.sharedLayouts], this.currentSelectedGridId);
  }

  public selectPanelType(type: boolean): void {
    this.isSharedPanel$.next(type);
  }

  public onLayoutSelected(dto: GridLayoutLookupDTO) {
    if (dto.id !== this.currentSelectedGridId) {
      this.layoutSelected.emit(dto);
    }
  }

  private setLayoutApiCall(): Observable<GridLayoutLookupDTO[][]> {
    const enumType = this.gridLayoutCacheService.mapGridTypeToEnum(this.gridType);

    return this.gridLayoutService.getAllGridLayoutsByType(enumType)
      .pipe(takeUntil(this.unsubscribe),
        map(({ results }: GetAllResponseGridLayoutLookupDTO) =>
          [results.filter(dto => dto.shared),
          results.filter(dto => !dto.shared)]
        ));
  }

  public addNewView(): void {
    const data: AddGridDialogData = {
      isShared: this.isSharedPanel$.value,
      hasAdminRole: this.hasAdminRole,
      gridType: this.gridType,
      isLoggedInUserCreator: true
    };

    this.dialog
      .open(AddGridLayoutComponent, { data: data, disableClose: true, autoFocus: true })
      .afterClosed()
      .subscribe((addedView: GridLayoutUpserted) => {
        if (addedView) {
          this.newGridCreated$.next(addedView.name);
          this.translate.instant("MESSAGES.NEW_ITEM_WITH_NAME_ADDED", { name: addedView.name });
        }
      });
  }

  public manageViews(): void {
    const data: ManageGridLayoutsDialogData = {
      hasAdminRole: this.hasAdminRole,
      gridType: this.gridType
    };

    this.dialog
      .open(ManageGridLayoutsComponent, { data: data, disableClose: true, autoFocus: true, width: "400px" })
      .afterClosed()
      .subscribe(() => this.popupClosed.emit());
  }

  private initSelectedTab(layouts: GridLayoutLookupDTO[], currentSelectedGridId: number): void {
    if (!currentSelectedGridId || layouts.length === 0) {
      this.selectPanelType(true);
      return;
    }

    const currentLayout = layouts.filter(x => x.id === currentSelectedGridId)[0];
    this.selectPanelType(currentLayout?.shared);
  }
}
