/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { OperationalFile } from './operationalFile';
import { OperationalFileContainerGoodsBooking } from './operationalFileContainerGoodsBooking';
import { OperationalFilePartyBooking } from './operationalFilePartyBooking';

export interface Booking { 
    rowCreatedTimestamp?: Date;
    lastRowModificationTimestamp?: Date;
    isDeleted?: boolean;
    id?: number;
    remarks?: string;
    chargePaymentType?: Booking.ChargePaymentTypeEnum;
    chargeType?: Booking.ChargeTypeEnum;
    haulageArrangementId?: number;
    isHaulageArrangementManuallyChanged?: boolean;
    hasCarrierAMSFiling?: boolean;
    contractNumber?: string;
    freightTariffNumber?: string;
    bookingOfficeId?: number;
    confirmationStatus?: Booking.ConfirmationStatusEnum;
    messageSentTimestamp?: Date;
    proposedMessageState?: Booking.ProposedMessageStateEnum;
    status?: Booking.StatusEnum;
    lastBookingStatus?: Booking.LastBookingStatusEnum;
    operationalFileId?: number;
    operationalFile?: OperationalFile;
    linkedContainerGoods?: Array<OperationalFileContainerGoodsBooking>;
    linkedChargeCategoryParties?: Array<OperationalFilePartyBooking>;
}
export namespace Booking {
    export type ChargePaymentTypeEnum = 0 | 1 | 2;
    export const ChargePaymentTypeEnum = {
        NUMBER_0: 0 as ChargePaymentTypeEnum,
        NUMBER_1: 1 as ChargePaymentTypeEnum,
        NUMBER_2: 2 as ChargePaymentTypeEnum
    };
    export type ChargeTypeEnum = 0 | 1 | 2 | 3 | 4 | 5;
    export const ChargeTypeEnum = {
        NUMBER_0: 0 as ChargeTypeEnum,
        NUMBER_1: 1 as ChargeTypeEnum,
        NUMBER_2: 2 as ChargeTypeEnum,
        NUMBER_3: 3 as ChargeTypeEnum,
        NUMBER_4: 4 as ChargeTypeEnum,
        NUMBER_5: 5 as ChargeTypeEnum
    };
    export type ConfirmationStatusEnum = 0 | 1 | 2 | 3 | 4;
    export const ConfirmationStatusEnum = {
        NUMBER_0: 0 as ConfirmationStatusEnum,
        NUMBER_1: 1 as ConfirmationStatusEnum,
        NUMBER_2: 2 as ConfirmationStatusEnum,
        NUMBER_3: 3 as ConfirmationStatusEnum,
        NUMBER_4: 4 as ConfirmationStatusEnum
    };
    export type ProposedMessageStateEnum = 0 | 1;
    export const ProposedMessageStateEnum = {
        NUMBER_0: 0 as ProposedMessageStateEnum,
        NUMBER_1: 1 as ProposedMessageStateEnum
    };
    export type StatusEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6;
    export const StatusEnum = {
        NUMBER_0: 0 as StatusEnum,
        NUMBER_1: 1 as StatusEnum,
        NUMBER_2: 2 as StatusEnum,
        NUMBER_3: 3 as StatusEnum,
        NUMBER_4: 4 as StatusEnum,
        NUMBER_5: 5 as StatusEnum,
        NUMBER_6: 6 as StatusEnum
    };
    export type LastBookingStatusEnum = 0 | 1;
    export const LastBookingStatusEnum = {
        NUMBER_0: 0 as LastBookingStatusEnum,
        NUMBER_1: 1 as LastBookingStatusEnum
    };
}