import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TenantDTO } from './swagger-gen/fordesk';

@Injectable({ providedIn: "root" })
export class TenantSynchronizationService {

  private currentTenantSubject$: BehaviorSubject<SelectedTenant | null> = new BehaviorSubject<SelectedTenant | null>(null)

  private allowedTenants: TenantDTO[];

  public get getCurrentTenant(): SelectedTenant | null {
    return this.currentTenantSubject$.value
  }

  public emitTenantChangedEvent(tenant: SelectedTenant) {
    this.currentTenantSubject$.next(tenant);
  }

  public setAllowedTenants(tenants: TenantDTO[]) {
    this.allowedTenants = tenants;
  }

  public get getAllowedTenants(): TenantDTO[] {
    return this.allowedTenants;
  }

  public getTenantChangedObservable$(): Observable<SelectedTenant | null> {
    return this.currentTenantSubject$.asObservable();
  }
}

export class SelectedTenant {
  public tenantId: number;
  public externalTenantId: string;
}
