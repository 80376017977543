export const import_finance_data_locale = {
  SELECT_CODA_FILE: "Select CODA file to import",
  ONLY_ONE_FILE_AVAILABLE_FOR_UPLOAD: "Only one CODA file should be uploaded. Multiple files cannot be processed at once",
  ONLY_UNPAID_INCOMING_INVOICES_CAN_BE_SELECTED: "Only unpaid incoming invoices can be selected",
  ONLY_UNPAID_OUTGOING_INVOICES_CAN_BE_SELECTED: "Only unpaid outgoing invoices can be selected",
  ALL_SELECTED_INVOICES_ALREADY_ADDED: "All selected invoices have already been added",
  UPLOAD_CORRECT_CODA_FILE: "Only the following file extensions are allowed: .cod and .coda.",
  SEARCH_INCOMING_INVOICES: "search incoming invoices",
  SEARCH_OUTGOING_INVOICES: "search outgoing invoices",
  SELECT_INVOICES: "select invoices",
  SELECTED: "selected",
  SELECTED_INCOMING_INVOICES: "Selected Incoming invoices",
  SELECTED_OUTGOING_INVOICES: "Selected Outgoing invoices",
  NO_INVOICES_SELECTED: "No invoices selected.",
  INVOICES_AMOUNT_NOT_MATCH_TRANSACTION: "The invoice amounts do not match the transaction amount.",
  INVOICES_MATCHS_TRANSACTION_AMOUNT_ONLY: "The invoice only matches the transaction amount. Please double-check its validity.",
  BOTH_INCOMING_OUTGOING_LINKING_ERROR: "Linking both incoming and outgoing invoices is not allowed.",
  FOUND_INVOICES: "Found invoices",
  FOUND_INCOMING_INVOICES: "Found Incoming invoices",
  FOUND_OUTGOING_INVOICES: "Found Outgoing invoices",
  STATEMENTS: "Imported CODA statements",
  ACCOUNT: "Account",
  SWIFT_NUMBER: "SWIFT (BIC) code",
  COMPANY_NUMBER: "Company number",
  COUNTRY_CODE: "Country code",
  CURRENCY_CODE: "Currency",
  ACCOUNT_NAME: "Name",
  ACCOUNT_NUMBER: "Number",
  STATEMENT_DETAILS: "Details",
  PAYMENT_DATE: "Payment date",
  INITIAL_BALANCE: "Initial balance",
  NEW_BALANCE: "New balance",
  TRANSACTIONS: "Transactions",
  TRANSACTION_DETAILS: "Details",
  TRANSACTION_AMOUNT: "Amount",
  TRANSACTION_MESSAGE: "Message",
  TRANSACTION_STRUCTURED_MESSAGE: "Structured message",
  TRANSACTION_DATE: "Date",
  MARK_INVOICES_AS_PAID: "mark linked invoices as paid",
  SELECTED_INVOICES_MARKED_AS_PAID: "Selected invoices marked as paid",
  MODAL: {
    NO_INVOICES_SELECTED: "No invoices selected based on imported CODA file",
    TITLE_NO_INVOICES_SELECTED: "No invoices selected to be marked as paid",
    BOTH_INCOMING_OUTGOING_LINKING_ERROR: "It is not allowed to link both incoming and outgoing invoices to a single transaction. Please fix the errors and try again",
    TITLE_BOTH_INCOMING_OUTGOING_LINKING_ERROR: "Invoices linking error",
    INVOICES_AMOUNT_NOT_MATCH_TRANSACTION: "Some invoice amounts do not match the transaction amount. Do you want to proceed?",
    TITLE_INVOICES_AMOUNT_NOT_MATCH_TRANSACTION: "Invoice amounts do not match",
    REUPLOAD_CODA_FILE: "The last file will be deleted and the searches will be cleared when uploading a new file. Are you sure?",
    TITLE_REUPLOAD_CODA_FILE: "Uploading a new CODA file",
    SET_PAID_DATE_TITLE: "Payment date",
    SET_PAID_DATE: "Choose a payment date",
  }
};
