/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { ApiConfiguration } from '../api-configuration';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CreateUserInviteResponse } from '../models/create-user-invite-response';
import { CreateUserPropertiesPermitRequest } from '../models/create-user-properties-permit-request';
import { DeleteUserPropertiesPermitRequest } from '../models/delete-user-properties-permit-request';
import { UserGetByIdResponse } from '../models/user-get-by-id-response';
import { EmptyResponse } from '../models/empty-response';
import { UserRole } from '../models/user-role';
import { UpdateUsersStateRequest } from '../models/update-users-state-request';
import { UpdateUserStateRequest } from '../models/update-user-state-request';
import { PartialListOfUserListItemResponse } from '../models/partial-list-of-user-list-item-response';
import { UserTenantListItemResponse } from '../models/user-tenant-list-item-response';
import { GetUserPropertiesResponse } from '../models/get-user-properties-response';
import { PatchUserPropertiesRequest } from '../models/patch-user-properties-request';
import { UserPropertiesPermitListItemResponse } from '../models/user-properties-permit-list-item-response';
import { CreateUserInviteOldRequest } from '../models/create-user-invite-old-request';
import { RequestBuilder } from '../request-builder';
import { StrictHttpResponse } from '../strict-http-response';
import { BaseService } from '../base-service';
@Injectable({
  providedIn: 'root',
})
export class UserMgtUsersService extends BaseService {
  constructor(
    public config: ApiConfiguration,
    public http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation userGetByIdEndpoint
   */
  static readonly UserGetByIdEndpointPath = '/users/{userId}';

  /**
   * Get a user base profile by id.
   *
   * Returns specific user base profile, searched per provided user ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGetByIdEndpoint()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGetByIdEndpoint$Response(params: {

    /**
     * ID of user, whose data should be found
     */
    userId: string;
    context?: HttpContext
  }
  ): Observable<StrictHttpResponse<UserGetByIdResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserMgtUsersService.UserGetByIdEndpointPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserGetByIdResponse>;
      })
    );
  }

  /**
   * Get a user base profile by id.
   *
   * Returns specific user base profile, searched per provided user ID
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userGetByIdEndpoint$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGetByIdEndpoint(params: {

    /**
     * ID of user, whose data should be found
     */
    userId: string;
    context?: HttpContext
  }
  ): Observable<UserGetByIdResponse> {

    return this.userGetByIdEndpoint$Response(params).pipe(
      map((r: StrictHttpResponse<UserGetByIdResponse>) => r.body as UserGetByIdResponse)
    );
  }

  /**
   * Path part for operation deleteUser
   */
  static readonly DeleteUserPath = '/users/{userId}';

  /**
   * Delete a user.
   *
   * Remove the selected user's account, only if they are not the last admin or global admin.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUser$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser$Plain$Response(params: {

    /**
     * ID of the user to be deleted.
     */
    userId: string;
    context?: HttpContext
  }
  ): Observable<StrictHttpResponse<EmptyResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserMgtUsersService.DeleteUserPath, 'delete');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptyResponse>;
      })
    );
  }

  /**
   * Delete a user.
   *
   * Remove the selected user's account, only if they are not the last admin or global admin.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteUser$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser$Plain(params: {

    /**
     * ID of the user to be deleted.
     */
    userId: string;
    context?: HttpContext
  }
  ): Observable<EmptyResponse> {

    return this.deleteUser$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EmptyResponse>) => r.body as EmptyResponse)
    );
  }

  /**
   * Delete a user.
   *
   * Remove the selected user's account, only if they are not the last admin or global admin.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUser$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser$Json$Response(params: {

    /**
     * ID of the user to be deleted.
     */
    userId: string;
    context?: HttpContext
  }
  ): Observable<StrictHttpResponse<EmptyResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserMgtUsersService.DeleteUserPath, 'delete');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptyResponse>;
      })
    );
  }

  /**
   * Delete a user.
   *
   * Remove the selected user's account, only if they are not the last admin or global admin.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteUser$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser$Json(params: {

    /**
     * ID of the user to be deleted.
     */
    userId: string;
    context?: HttpContext
  }
  ): Observable<EmptyResponse> {

    return this.deleteUser$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EmptyResponse>) => r.body as EmptyResponse)
    );
  }

  /**
   * Path part for operation getUserRoles
   */
  static readonly GetUserRolesPath = '/users/{userId}/roles';

  /**
   * List all roles attached to user.
   *
   * Provides a list of all roles assigned to a user across the entire system.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserRoles$Response(params: {

    /**
     * User&#x27;s ID for whom roles should be found
     */
    userId: string;
    context?: HttpContext
  }
  ): Observable<StrictHttpResponse<Array<UserRole>>> {

    const rb = new RequestBuilder(this.rootUrl, UserMgtUsersService.GetUserRolesPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserRole>>;
      })
    );
  }

  /**
   * List all roles attached to user.
   *
   * Provides a list of all roles assigned to a user across the entire system.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserRoles(params: {

    /**
     * User&#x27;s ID for whom roles should be found
     */
    userId: string;
    context?: HttpContext
  }
  ): Observable<Array<UserRole>> {

    return this.getUserRoles$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserRole>>) => r.body as Array<UserRole>)
    );
  }

  /**
   * Path part for operation updateUsersState
   */
  static readonly UpdateUsersStatePath = '/users/state';

  /**
   * Update users state.
   *
   * Toggle the account status for a group of users specified by their user IDs. Disabled users within the array will no longer have the ability to log in.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUsersState()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUsersState$Response(params: {
    context?: HttpContext
    body: UpdateUsersStateRequest
  }
  ): Observable<StrictHttpResponse<{
    [key: string]: string;
  }>> {

    const rb = new RequestBuilder(this.rootUrl, UserMgtUsersService.UpdateUsersStatePath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
          [key: string]: string;
        }>;
      })
    );
  }

  /**
   * Update users state.
   *
   * Toggle the account status for a group of users specified by their user IDs. Disabled users within the array will no longer have the ability to log in.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUsersState$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUsersState(params: {
    context?: HttpContext
    body: UpdateUsersStateRequest
  }
  ): Observable<{
    [key: string]: string;
  }> {

    return this.updateUsersState$Response(params).pipe(
      map((r: StrictHttpResponse<{
        [key: string]: string;
      }>) => r.body as {
        [key: string]: string;
      })
    );
  }

  /**
   * Path part for operation updateUserState
   */
  static readonly UpdateUserStatePath = '/users/{userId}/state';

  /**
   * Update a user state.
   *
   * Toggle the user account's status to enable or disable it. Disabled users will no longer be able to log in.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserState$Plain()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserState$Plain$Response(params: {

    /**
     * ID of user, whose state should be updated
     */
    userId: string;
    context?: HttpContext
    body: UpdateUserStateRequest
  }
  ): Observable<StrictHttpResponse<EmptyResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserMgtUsersService.UpdateUserStatePath, 'put');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptyResponse>;
      })
    );
  }

  /**
   * Update a user state.
   *
   * Toggle the user account's status to enable or disable it. Disabled users will no longer be able to log in.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUserState$Plain$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserState$Plain(params: {

    /**
     * ID of user, whose state should be updated
     */
    userId: string;
    context?: HttpContext
    body: UpdateUserStateRequest
  }
  ): Observable<EmptyResponse> {

    return this.updateUserState$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EmptyResponse>) => r.body as EmptyResponse)
    );
  }

  /**
   * Update a user state.
   *
   * Toggle the user account's status to enable or disable it. Disabled users will no longer be able to log in.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserState$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserState$Json$Response(params: {

    /**
     * ID of user, whose state should be updated
     */
    userId: string;
    context?: HttpContext
    body: UpdateUserStateRequest
  }
  ): Observable<StrictHttpResponse<EmptyResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserMgtUsersService.UpdateUserStatePath, 'put');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptyResponse>;
      })
    );
  }

  /**
   * Update a user state.
   *
   * Toggle the user account's status to enable or disable it. Disabled users will no longer be able to log in.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUserState$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserState$Json(params: {

    /**
     * ID of user, whose state should be updated
     */
    userId: string;
    context?: HttpContext
    body: UpdateUserStateRequest
  }
  ): Observable<EmptyResponse> {

    return this.updateUserState$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EmptyResponse>) => r.body as EmptyResponse)
    );
  }

  /**
   * Path part for operation userListEndpoint
   */
  static readonly UserListEndpointPath = '/users';

  /**
   * Get list of users.
   *
   * Retrieve a list of all users that can be accessed by the current user.
   * Additionally, the user can make queries based on users belonging to a specified tenant
   * or query list of user IDs to be returned.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userListEndpoint()` instead.
   *
   * This method doesn't expect any request body.
   */
  userListEndpoint$Response(params?: {

    /**
     * Tenant&#x27;s ID where the user is currently assigned with
     */
    tenantId?: string;

    /**
     * Optional parameter that accepts one or multiple user IDs to display their data
     */
    ids?: Array<string>;

    /**
     * Optional parameter that enables paginated retrieval of users by specifying the point from which to continue fetching records
     */
    continuationToken?: string;
    context?: HttpContext
  }
  ): Observable<StrictHttpResponse<PartialListOfUserListItemResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserMgtUsersService.UserListEndpointPath, 'get');
    if (params) {
      rb.query('tenantId', params.tenantId, {});
      rb.query('ids', params.ids, { "style": "form", "explode": true });
      rb.query('continuationToken', params.continuationToken, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PartialListOfUserListItemResponse>;
      })
    );
  }

  /**
   * Get list of users.
   *
   * Retrieve a list of all users that can be accessed by the current user.
   * Additionally, the user can make queries based on users belonging to a specified tenant
   * or query list of user IDs to be returned.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userListEndpoint$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userListEndpoint(params?: {

    /**
     * Tenant&#x27;s ID where the user is currently assigned with
     */
    tenantId?: string;

    /**
     * Optional parameter that accepts one or multiple user IDs to display their data
     */
    ids?: Array<string>;

    /**
     * Optional parameter that enables paginated retrieval of users by specifying the point from which to continue fetching records
     */
    continuationToken?: string;
    context?: HttpContext
  }
  ): Observable<PartialListOfUserListItemResponse> {

    return this.userListEndpoint$Response(params).pipe(
      map((r: StrictHttpResponse<PartialListOfUserListItemResponse>) => r.body as PartialListOfUserListItemResponse)
    );
  }

  /**
   * Path part for operation userTenantGlobalPermissionListEndpoint
   */
  static readonly UserTenantGlobalPermissionListEndpointPath = '/users/{userId}/tenants/{tenantId}/global-permissions';

  /**
   * Retrieve user global permissions in tenant.
   *
   * Retrieve a list of all permissions held by user, within scope of selected tenant.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userTenantGlobalPermissionListEndpoint()` instead.
   *
   * This method doesn't expect any request body.
   */
  userTenantGlobalPermissionListEndpoint$Response(params: {

    /**
     * ID of user, whose global permissions should be found
     */
    userId: string;

    /**
     * Tenant ID within which scope permissions should be found
     */
    tenantId: string;
    context?: HttpContext
  }
  ): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, UserMgtUsersService.UserTenantGlobalPermissionListEndpointPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.path('tenantId', params.tenantId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Retrieve user global permissions in tenant.
   *
   * Retrieve a list of all permissions held by user, within scope of selected tenant.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userTenantGlobalPermissionListEndpoint$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userTenantGlobalPermissionListEndpoint(params: {

    /**
     * ID of user, whose global permissions should be found
     */
    userId: string;

    /**
     * Tenant ID within which scope permissions should be found
     */
    tenantId: string;
    context?: HttpContext
  }
  ): Observable<Array<string>> {

    return this.userTenantGlobalPermissionListEndpoint$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation userTenantListEndpoint
   */
  static readonly UserTenantListEndpointPath = '/users/{userId}/tenants';

  /**
   * Retrieve list of user's tenants.
   *
   * Retrieve a list of all tenants to which the selected user is assigned.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userTenantListEndpoint()` instead.
   *
   * This method doesn't expect any request body.
   */
  userTenantListEndpoint$Response(params: {

    /**
     * ID of user, whose tenants should be found
     */
    userId: string;
    context?: HttpContext
  }
  ): Observable<StrictHttpResponse<Array<UserTenantListItemResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, UserMgtUsersService.UserTenantListEndpointPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserTenantListItemResponse>>;
      })
    );
  }

  /**
   * Retrieve list of user's tenants.
   *
   * Retrieve a list of all tenants to which the selected user is assigned.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userTenantListEndpoint$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userTenantListEndpoint(params: {

    /**
     * ID of user, whose tenants should be found
     */
    userId: string;
    context?: HttpContext
  }
  ): Observable<Array<UserTenantListItemResponse>> {

    return this.userTenantListEndpoint$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserTenantListItemResponse>>) => r.body as Array<UserTenantListItemResponse>)
    );
  }

  /**
   * Path part for operation getUserProperties
   */
  static readonly GetUserPropertiesPath = '/users/{userId}/properties';

  /**
   * List all properties attached to user.
   *
   * Returns list of all properties that are attached to specific user in Azure B2C
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserProperties()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserProperties$Response(params: {

    /**
     * ID of user, whose properties should be found
     */
    userId: string;
    context?: HttpContext
  }
  ): Observable<StrictHttpResponse<GetUserPropertiesResponse>> {
    const rb = new RequestBuilder(this.rootUrl, UserMgtUsersService.GetUserPropertiesPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetUserPropertiesResponse>;
      })
    );
  }

  /**
   * List all properties attached to user.
   *
   * Returns list of all properties that are attached to specific user in Azure B2C
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserProperties$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserProperties(params: {

    /**
     * ID of user, whose properties should be found
     */
    userId: string;
    context?: HttpContext
  }
  ): Observable<GetUserPropertiesResponse> {

    return this.getUserProperties$Response(params).pipe(
      map((r: StrictHttpResponse<GetUserPropertiesResponse>) => r.body as GetUserPropertiesResponse)
    );
  }

  /**
   * Path part for operation updateUserProperties
   */
  static readonly UpdateUserPropertiesPath = '/users/{userId}/properties';

  /**
   * Patch properties attached to user.
   *
   * Update properties associated with the user. These properties must be preexisting attributes in Azure B2C, before they can be patched.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserProperties()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserProperties$Response(params: {

    /**
     * ID of user, whose properties should be updated
     */
    userId: string;
    context?: HttpContext
    body: PatchUserPropertiesRequest
  }
  ): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserMgtUsersService.UpdateUserPropertiesPath, 'patch');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Patch properties attached to user.
   *
   * Update properties associated with the user. These properties must be preexisting attributes in Azure B2C, before they can be patched.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUserProperties$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserProperties(params: {

    /**
     * ID of user, whose properties should be updated
     */
    userId: string;
    context?: HttpContext
    body: PatchUserPropertiesRequest
  }
  ): Observable<void> {

    return this.updateUserProperties$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation createUserPropertiesPermitEndpoint
   */
  static readonly CreateUserPropertiesPermitEndpointPath = '/users/{userId}/properties/permit';

  /**
   * Create a tenant permit to access user properties.
   *
   * Enables the tenant to access all properties of the selected user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUserPropertiesPermitEndpoint$Plain()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUserPropertiesPermitEndpoint$Plain$Response(params: {
    userId: string;
    context?: HttpContext
    body: CreateUserPropertiesPermitRequest
  }
  ): Observable<StrictHttpResponse<EmptyResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserMgtUsersService.CreateUserPropertiesPermitEndpointPath, 'post');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptyResponse>;
      })
    );
  }

  /**
   * Create a tenant permit to access user properties.
   *
   * Enables the tenant to access all properties of the selected user.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createUserPropertiesPermitEndpoint$Plain$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUserPropertiesPermitEndpoint$Plain(params: {
    userId: string;
    context?: HttpContext
    body: CreateUserPropertiesPermitRequest
  }
  ): Observable<EmptyResponse> {

    return this.createUserPropertiesPermitEndpoint$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EmptyResponse>) => r.body as EmptyResponse)
    );
  }

  /**
   * Create a tenant permit to access user properties.
   *
   * Enables the tenant to access all properties of the selected user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUserPropertiesPermitEndpoint$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUserPropertiesPermitEndpoint$Json$Response(params: {
    userId: string;
    context?: HttpContext
    body: CreateUserPropertiesPermitRequest
  }
  ): Observable<StrictHttpResponse<EmptyResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserMgtUsersService.CreateUserPropertiesPermitEndpointPath, 'post');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptyResponse>;
      })
    );
  }

  /**
   * Create a tenant permit to access user properties.
   *
   * Enables the tenant to access all properties of the selected user.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createUserPropertiesPermitEndpoint$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUserPropertiesPermitEndpoint$Json(params: {
    userId: string;
    context?: HttpContext
    body: CreateUserPropertiesPermitRequest
  }
  ): Observable<EmptyResponse> {

    return this.createUserPropertiesPermitEndpoint$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EmptyResponse>) => r.body as EmptyResponse)
    );
  }

  /**
   * Path part for operation deleteUserPropertiesPermitEndpoint
   */
  static readonly DeleteUserPropertiesPermitEndpointPath = '/users/{userId}/properties/revoke';

  /**
   * Revoke tenant permit to access user properties.
   *
   * Removes  permission for a tenant to access specific user properties
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUserPropertiesPermitEndpoint$Plain()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteUserPropertiesPermitEndpoint$Plain$Response(params: {
    userId: string;
    context?: HttpContext
    body: DeleteUserPropertiesPermitRequest
  }
  ): Observable<StrictHttpResponse<EmptyResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserMgtUsersService.DeleteUserPropertiesPermitEndpointPath, 'post');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptyResponse>;
      })
    );
  }

  /**
   * Revoke tenant permit to access user properties.
   *
   * Removes  permission for a tenant to access specific user properties
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteUserPropertiesPermitEndpoint$Plain$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteUserPropertiesPermitEndpoint$Plain(params: {
    userId: string;
    context?: HttpContext
    body: DeleteUserPropertiesPermitRequest
  }
  ): Observable<EmptyResponse> {

    return this.deleteUserPropertiesPermitEndpoint$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<EmptyResponse>) => r.body as EmptyResponse)
    );
  }

  /**
   * Revoke tenant permit to access user properties.
   *
   * Removes  permission for a tenant to access specific user properties
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUserPropertiesPermitEndpoint$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteUserPropertiesPermitEndpoint$Json$Response(params: {
    userId: string;
    context?: HttpContext
    body: DeleteUserPropertiesPermitRequest
  }
  ): Observable<StrictHttpResponse<EmptyResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserMgtUsersService.DeleteUserPropertiesPermitEndpointPath, 'post');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmptyResponse>;
      })
    );
  }

  /**
   * Revoke tenant permit to access user properties.
   *
   * Removes  permission for a tenant to access specific user properties
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteUserPropertiesPermitEndpoint$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteUserPropertiesPermitEndpoint$Json(params: {
    userId: string;
    context?: HttpContext
    body: DeleteUserPropertiesPermitRequest
  }
  ): Observable<EmptyResponse> {

    return this.deleteUserPropertiesPermitEndpoint$Json$Response(params).pipe(
      map((r: StrictHttpResponse<EmptyResponse>) => r.body as EmptyResponse)
    );
  }

  /**
   * Path part for operation userPropertiesPermitListEndpoint
   */
  static readonly UserPropertiesPermitListEndpointPath = '/users/{userId}/properties/permits';

  /**
   * Retrieve all permits given by a user.
   *
   * Retrieve all tenants to which the selected user has delegated access permiossion
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userPropertiesPermitListEndpoint()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPropertiesPermitListEndpoint$Response(params: {
    userId: string;
    context?: HttpContext
  }
  ): Observable<StrictHttpResponse<Array<UserPropertiesPermitListItemResponse>>> {

    const rb = new RequestBuilder(this.rootUrl, UserMgtUsersService.UserPropertiesPermitListEndpointPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserPropertiesPermitListItemResponse>>;
      })
    );
  }

  /**
   * Retrieve all permits given by a user.
   *
   * Retrieve all tenants to which the selected user has delegated access permiossion
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userPropertiesPermitListEndpoint$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userPropertiesPermitListEndpoint(params: {
    userId: string;
    context?: HttpContext
  }
  ): Observable<Array<UserPropertiesPermitListItemResponse>> {

    return this.userPropertiesPermitListEndpoint$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserPropertiesPermitListItemResponse>>) => r.body as Array<UserPropertiesPermitListItemResponse>)
    );
  }

  /**
   * Path part for operation createUserInviteOldEndpoint
   */
  static readonly CreateUserInviteOldEndpointPath = '/users/invites';

  /**
   * Create a user invitation to register.
   *
   * Create an invitation for user registration. This endpoint invites user only as
   * a local user, please use new version of this endpoint in order to create link
   * to external accounts. If a single account with the provided email address exists,
   * it will be selected for the invitation. If multiple accounts with the provided
   * email address exist, no invitation will be created. If the email address has
   * never been registered, a new user account will be created.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUserInviteOldEndpoint()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   *
   * @deprecated
   */
  createUserInviteOldEndpoint$Response(params: {
    context?: HttpContext
    body: CreateUserInviteOldRequest
  }
  ): Observable<StrictHttpResponse<CreateUserInviteResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserMgtUsersService.CreateUserInviteOldEndpointPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreateUserInviteResponse>;
      })
    );
  }

  /**
   * Create a user invitation to register.
   *
   * Create an invitation for user registration. This endpoint invites user only as
   * a local user, please use new version of this endpoint in order to create link
   * to external accounts. If a single account with the provided email address exists,
   * it will be selected for the invitation. If multiple accounts with the provided
   * email address exist, no invitation will be created. If the email address has
   * never been registered, a new user account will be created.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createUserInviteOldEndpoint$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   *
   * @deprecated
   */
  createUserInviteOldEndpoint(params: {
    context?: HttpContext
    body: CreateUserInviteOldRequest
  }
  ): Observable<CreateUserInviteResponse> {

    return this.createUserInviteOldEndpoint$Response(params).pipe(
      map((r: StrictHttpResponse<CreateUserInviteResponse>) => r.body as CreateUserInviteResponse)
    );
  }

}
