/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { OperationalFileContainerGoodsDTO } from './operationalFileContainerGoodsDTO';
import { SealNumberDTO } from './sealNumberDTO';

export interface OperationalFileContainerDTO { 
    operationalFileId: number;
    operationalFileContainerId?: number;
    sizeTypeId?: number;
    containerNumber?: string;
    cargoWeight?: number;
    grossVolume?: number;
    containerTare?: number;
    temperature?: number;
    humidity?: number;
    ventSettings?: number;
    isReefer?: boolean;
    isGeneratorRequired?: boolean;
    isOperationalReefer?: boolean;
    isShipperOwned?: boolean;
    vgmWeight?: number;
    releaseRightStatus?: OperationalFileContainerDTO.ReleaseRightStatusEnum;
    equipmentAirFlow?: number;
    equipmentAirFlowUomType?: OperationalFileContainerDTO.EquipmentAirFlowUomTypeEnum;
    operationalFileContainerGoods?: Array<OperationalFileContainerGoodsDTO>;
    operationalFileSealNumbers?: Array<SealNumberDTO>;
}
export namespace OperationalFileContainerDTO {
    export type ReleaseRightStatusEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
    export const ReleaseRightStatusEnum = {
        NUMBER_0: 0 as ReleaseRightStatusEnum,
        NUMBER_1: 1 as ReleaseRightStatusEnum,
        NUMBER_2: 2 as ReleaseRightStatusEnum,
        NUMBER_3: 3 as ReleaseRightStatusEnum,
        NUMBER_4: 4 as ReleaseRightStatusEnum,
        NUMBER_5: 5 as ReleaseRightStatusEnum,
        NUMBER_6: 6 as ReleaseRightStatusEnum,
        NUMBER_7: 7 as ReleaseRightStatusEnum
    };
    export type EquipmentAirFlowUomTypeEnum = 0 | 1;
    export const EquipmentAirFlowUomTypeEnum = {
        NUMBER_0: 0 as EquipmentAirFlowUomTypeEnum,
        NUMBER_1: 1 as EquipmentAirFlowUomTypeEnum
    };
}