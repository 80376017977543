/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { InviteNewUserDTO } from '../model/inviteNewUserDTO';
import { Response } from '../model/response';
import { TenantDTO } from '../model/tenantDTO';
import { TenantRelatedUserPropertiesDTO } from '../model/tenantRelatedUserPropertiesDTO';
import { UserRelationDTO } from '../model/userRelationDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TenantsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get all tenant user relations
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllTenantUserRelations(options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<string>>;
    public getAllTenantUserRelations(options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<string>>>;
    public getAllTenantUserRelations(options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<string>>>;
    public getAllTenantUserRelations(options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<string>>(`${this.basePath}/api/tenants/users/relations`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get current user allowed tenants
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllowedTenants(options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<TenantDTO>>;
    public getAllowedTenants(options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<TenantDTO>>>;
    public getAllowedTenants(options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<TenantDTO>>>;
    public getAllowedTenants(options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<TenantDTO>>(`${this.basePath}/api/tenants/allowed-tenants`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get tenant related user details
     * 
     * @param tenantId 
     * @param externalUserId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTenantRelatedUserDetails(tenantId: number, externalUserId: string, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<TenantRelatedUserPropertiesDTO>;
    public getTenantRelatedUserDetails(tenantId: number, externalUserId: string, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<TenantRelatedUserPropertiesDTO>>;
    public getTenantRelatedUserDetails(tenantId: number, externalUserId: string, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<TenantRelatedUserPropertiesDTO>>;
    public getTenantRelatedUserDetails(tenantId: number, externalUserId: string, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling getTenantRelatedUserDetails.');
        }

        if (externalUserId === null || externalUserId === undefined) {
            throw new Error('Required parameter externalUserId was null or undefined when calling getTenantRelatedUserDetails.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<TenantRelatedUserPropertiesDTO>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/users/${encodeURIComponent(String(externalUserId))}/details`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get user allowed tenants
     * 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserAllowedTenants(userId?: string, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<TenantDTO>>;
    public getUserAllowedTenants(userId?: string, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<TenantDTO>>>;
    public getUserAllowedTenants(userId?: string, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<TenantDTO>>>;
    public getUserAllowedTenants(userId?: string, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<TenantDTO>>(`${this.basePath}/api/tenants/user-allowed-tenants`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Get user relations
     * 
     * @param externalTenantId 
     * @param externalUserId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserRelationsForTenant(externalTenantId: string, externalUserId: string, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<UserRelationDTO>>;
    public getUserRelationsForTenant(externalTenantId: string, externalUserId: string, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<UserRelationDTO>>>;
    public getUserRelationsForTenant(externalTenantId: string, externalUserId: string, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<UserRelationDTO>>>;
    public getUserRelationsForTenant(externalTenantId: string, externalUserId: string, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (externalTenantId === null || externalTenantId === undefined) {
            throw new Error('Required parameter externalTenantId was null or undefined when calling getUserRelationsForTenant.');
        }

        if (externalUserId === null || externalUserId === undefined) {
            throw new Error('Required parameter externalUserId was null or undefined when calling getUserRelationsForTenant.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.get<Array<UserRelationDTO>>(`${this.basePath}/api/tenants/${encodeURIComponent(String(externalTenantId))}/users/${encodeURIComponent(String(externalUserId))}/relations`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Invite new user to tenant
     * 
     * @param tenantId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inviteNewUserToTenant(tenantId: number, body?: InviteNewUserDTO, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<UserRelationDTO>>;
    public inviteNewUserToTenant(tenantId: number, body?: InviteNewUserDTO, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<UserRelationDTO>>>;
    public inviteNewUserToTenant(tenantId: number, body?: InviteNewUserDTO, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<UserRelationDTO>>>;
    public inviteNewUserToTenant(tenantId: number, body?: InviteNewUserDTO, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling inviteNewUserToTenant.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.post<Array<UserRelationDTO>>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/users/invite`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Update user relations for tenant
     * 
     * @param externalTenantId 
     * @param externalUserId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateRelationsForTenant(externalTenantId: string, externalUserId: string, body?: Array<UserRelationDTO>, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Array<UserRelationDTO>>;
    public updateRelationsForTenant(externalTenantId: string, externalUserId: string, body?: Array<UserRelationDTO>, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Array<UserRelationDTO>>>;
    public updateRelationsForTenant(externalTenantId: string, externalUserId: string, body?: Array<UserRelationDTO>, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Array<UserRelationDTO>>>;
    public updateRelationsForTenant(externalTenantId: string, externalUserId: string, body?: Array<UserRelationDTO>, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (externalTenantId === null || externalTenantId === undefined) {
            throw new Error('Required parameter externalTenantId was null or undefined when calling updateRelationsForTenant.');
        }

        if (externalUserId === null || externalUserId === undefined) {
            throw new Error('Required parameter externalUserId was null or undefined when calling updateRelationsForTenant.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.patch<Array<UserRelationDTO>>(`${this.basePath}/api/tenants/${encodeURIComponent(String(externalTenantId))}/users/${encodeURIComponent(String(externalUserId))}/relations`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * Update tenant related user details
     * 
     * @param tenantId 
     * @param externalUserId 
     * @param body 
     * @param userNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTenantRelatedUserDetails(tenantId: number, externalUserId: string, body?: Array<number>, userNumber?: number, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<Response>;
    public updateTenantRelatedUserDetails(tenantId: number, externalUserId: string, body?: Array<number>, userNumber?: number, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<Response>>;
    public updateTenantRelatedUserDetails(tenantId: number, externalUserId: string, body?: Array<number>, userNumber?: number, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<Response>>;
    public updateTenantRelatedUserDetails(tenantId: number, externalUserId: string, body?: Array<number>, userNumber?: number, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling updateTenantRelatedUserDetails.');
        }

        if (externalUserId === null || externalUserId === undefined) {
            throw new Error('Required parameter externalUserId was null or undefined when calling updateTenantRelatedUserDetails.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userNumber !== undefined && userNumber !== null) {
            queryParameters = queryParameters.set('userNumber', <any>userNumber);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.patch<Response>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/users/${encodeURIComponent(String(externalUserId))}/details`,
            body,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

    /**
     * check if user number is taken already
     * 
     * @param tenantId 
     * @param userNumber 
     * @param externalUserId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateUserNumberQueryHandler(tenantId: number, userNumber?: number, externalUserId?: string, options?: { observe?: 'body', reportProgress?: boolean, disableLoader?: boolean }): Observable<boolean>;
    public validateUserNumberQueryHandler(tenantId: number, userNumber?: number, externalUserId?: string, options?: { observe?: 'response', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpResponse<boolean>>;
    public validateUserNumberQueryHandler(tenantId: number, userNumber?: number, externalUserId?: string, options?: { observe?: 'events', reportProgress?: boolean, disableLoader?: boolean }): Observable<HttpEvent<boolean>>;
    public validateUserNumberQueryHandler(tenantId: number, userNumber?: number, externalUserId?: string, options: { observe?: any, reportProgress?: boolean, disableLoader?: boolean } = { observe: 'body', reportProgress: false, disableLoader: false }): Observable<any> {

        if (tenantId === null || tenantId === undefined) {
            throw new Error('Required parameter tenantId was null or undefined when calling validateUserNumberQueryHandler.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userNumber !== undefined && userNumber !== null) {
            queryParameters = queryParameters.set('userNumber', <any>userNumber);
        }
        if (externalUserId !== undefined && externalUserId !== null) {
            queryParameters = queryParameters.set('externalUserId', <any>externalUserId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        if (options?.disableLoader) {
            headers = headers.set('Disable-Loader', 'true');
        }

        return this.httpClient.patch<boolean>(`${this.basePath}/api/tenants/${encodeURIComponent(String(tenantId))}/users/validate/user-number`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: options?.observe,
                reportProgress: options?.reportProgress
            }
        );
    }

}
