/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { OperationalFile } from './operationalFile';
import { OperationalFileParty } from './operationalFileParty';

export interface OperationalFileStep { 
    rowCreatedTimestamp?: Date;
    lastRowModificationTimestamp?: Date;
    isDeleted?: boolean;
    id?: number;
    days?: number;
    hours?: number;
    startWhen?: OperationalFileStep.StartWhenEnum;
    description?: string;
    completionTimestamp?: Date;
    hasBeenAdded?: boolean;
    order?: number;
    deadline?: Date;
    autoCompletionType?: OperationalFileStep.AutoCompletionTypeEnum;
    operationalFileId?: number;
    operationalFile?: OperationalFile;
    previousOperationalFileStepId?: number;
    previousOperationalFileStep?: OperationalFileStep;
    basedOn?: OperationalFileStep.BasedOnEnum;
    notifyCustomer?: OperationalFileStep.NotifyCustomerEnum;
    notifyCustomerText?: string;
    customerNotifiedOn?: Date;
    operationalFilePartyId?: number;
    operationalFileParty?: OperationalFileParty;
    hasMissedLinkedParty?: boolean;
}
export namespace OperationalFileStep {
    export type StartWhenEnum = 0 | 1;
    export const StartWhenEnum = {
        NUMBER_0: 0 as StartWhenEnum,
        NUMBER_1: 1 as StartWhenEnum
    };
    export type AutoCompletionTypeEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
    export const AutoCompletionTypeEnum = {
        NUMBER_0: 0 as AutoCompletionTypeEnum,
        NUMBER_1: 1 as AutoCompletionTypeEnum,
        NUMBER_2: 2 as AutoCompletionTypeEnum,
        NUMBER_3: 3 as AutoCompletionTypeEnum,
        NUMBER_4: 4 as AutoCompletionTypeEnum,
        NUMBER_5: 5 as AutoCompletionTypeEnum,
        NUMBER_6: 6 as AutoCompletionTypeEnum,
        NUMBER_7: 7 as AutoCompletionTypeEnum
    };
    export type BasedOnEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15;
    export const BasedOnEnum = {
        NUMBER_0: 0 as BasedOnEnum,
        NUMBER_1: 1 as BasedOnEnum,
        NUMBER_2: 2 as BasedOnEnum,
        NUMBER_3: 3 as BasedOnEnum,
        NUMBER_4: 4 as BasedOnEnum,
        NUMBER_5: 5 as BasedOnEnum,
        NUMBER_6: 6 as BasedOnEnum,
        NUMBER_7: 7 as BasedOnEnum,
        NUMBER_8: 8 as BasedOnEnum,
        NUMBER_9: 9 as BasedOnEnum,
        NUMBER_10: 10 as BasedOnEnum,
        NUMBER_11: 11 as BasedOnEnum,
        NUMBER_12: 12 as BasedOnEnum,
        NUMBER_13: 13 as BasedOnEnum,
        NUMBER_14: 14 as BasedOnEnum,
        NUMBER_15: 15 as BasedOnEnum
    };
    export type NotifyCustomerEnum = 0 | 1 | 2 | 3;
    export const NotifyCustomerEnum = {
        NUMBER_0: 0 as NotifyCustomerEnum,
        NUMBER_1: 1 as NotifyCustomerEnum,
        NUMBER_2: 2 as NotifyCustomerEnum,
        NUMBER_3: 3 as NotifyCustomerEnum
    };
}