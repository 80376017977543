/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Company } from './company';
import { CompanyAddress } from './companyAddress';
import { Currency } from './currency';
import { InvoiceType } from './invoiceType';
import { OutgoingInvoiceLine } from './outgoingInvoiceLine';

export interface OutgoingInvoice { 
    rowCreatedTimestamp?: Date;
    lastRowModificationTimestamp?: Date;
    isDeleted?: boolean;
    id?: number;
    number?: string;
    interfaceNumber?: string;
    invoiceDate?: Date;
    invoiceExpirationDate?: Date;
    paidDate?: Date;
    orderReference?: string;
    lastReminderSentDate?: Date;
    amountExcludingVat?: number;
    vatAmount?: number;
    proformaReference?: string;
    invoicedCustomerId?: number;
    status?: OutgoingInvoice.StatusEnum;
    invoiceText?: string;
    invoiceTypeId?: number;
    currencyId?: number;
    invoiceType?: InvoiceType;
    invoicedCustomer?: Company;
    outgoingInvoiceLines?: Array<OutgoingInvoiceLine>;
    currency?: Currency;
    vatNumber?: string;
    exchangeRate?: number;
    companyAddressId?: number;
    companyName?: string;
    addressLine1?: string;
    addressLine2?: string;
    stateProvinceRegion?: string;
    place?: string;
    zipCode?: string;
    country?: string;
    companyAddress?: CompanyAddress;
    exportTimestamp?: Date;
    successfullyExported?: boolean;
    numberOfExportAttempts?: number;
}
export namespace OutgoingInvoice {
    export type StatusEnum = 0 | 1 | 2 | 3;
    export const StatusEnum = {
        NUMBER_0: 0 as StatusEnum,
        NUMBER_1: 1 as StatusEnum,
        NUMBER_2: 2 as StatusEnum,
        NUMBER_3: 3 as StatusEnum
    };
}