import { Injectable } from "@angular/core";
import { ToasterService } from "../../../content/layout/toaster/toaster.service";

@Injectable({
  providedIn: "root"
})
export class XhrInterceptorService {
  private xhrResponse: any = null;
  private onLoadCallBack: any = null;
  private subscribe: boolean = false;
  private addedEventListener: boolean = false;
  private errorMessages: string[] = null;

  constructor(private toaster: ToasterService) { }

  public setupXHRInterceptor() {
    this.subscribe = true;
    const oldXHROpen = XMLHttpRequest.prototype.open;
    const context = this;
    const toaster = this.toaster;

    if (!context.addedEventListener) {
      XMLHttpRequest.prototype.open = function (method: any, url: any, async: any, user: any, password: any) {
        if (context.subscribe) {
          context.onLoadCallBack = (): void => {
            context.handleResponse(context, this.response, toaster);
          };

          this.addEventListener("load", context.onLoadCallBack);
          context.addedEventListener = true;
        } else {
          this.removeEventListener("load", context.onLoadCallBack);
        }

        return oldXHROpen.apply(this, arguments);
      } as any;
    }
  }

  private handleResponse(context: this, response: any, toaster: ToasterService) {
    try {
      context.xhrResponse = (context.isString(response) && context.needsNoStringify(response))
        ? JSON.parse(response as string)
        : JSON.parse(JSON.stringify(response)); // only sends string when errors

      if (context.isXHRError()) {
        context.errorMessages = context.xhrResponse.detail
          ? context.xhrResponse.detail
            .split(";").map((e: string) => e.trim()) : [];

        context.errorMessages.forEach(err => {
          // This error does not need to be output as it is handled in the component itself
          if (err !== "A file with the given name already exists.") {
            toaster.error(err);
          }
        });
      } else {
        context.errorMessages = [];
      }
    } catch (e) {
      console.error(e);
    }
  }

  private isString(response: any) {
    return (typeof response === "string" || response instanceof String);
  }

  private needsNoStringify(response: string) {
    return response.length > 1 && response[0] === "{" && response[response.length - 1] === "}";
  }

  public isXHRError() {
    return this.xhrResponse.status === 400 && this.xhrResponse.type === "FluentValidation.ValidationException";
  }

  public getXHRError() {
    return this.xhrResponse;
  }

  public folderOrFileNameMustBeDifferentFromInitial() {
    return this.errorMessages.includes("The name of the new file/folder must be different from the original name.");
  }

  public folderNameConnotContainsExtension() {
    return this.errorMessages.includes("The folder name cannot contain an extension.");
  }

  public operationFileIsClosed() {
    return this.errorMessages.includes("This operational file has been closed. Please refresh the page.");
  }

  public incorrectQuoteStatus() {
    return this.errorMessages.includes("The quote status does not allow to perform this operation.");
  }

  public unsubscribe() {
    this.subscribe = false;
  }
}
