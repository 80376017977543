/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ActivityCodeGroup } from './activityCodeGroup';

export interface OutgoingTaxCode { 
    rowCreatedTimestamp?: Date;
    lastRowModificationTimestamp?: Date;
    isDeleted?: boolean;
    id?: number;
    taxSystem?: string;
    taxPercentage?: number;
    taxClauseCode?: string;
    taxClauseDescription?: string;
    debitLine?: string;
    creditLine?: string;
    vat1CodeDebit?: string;
    vat2CodeDebit?: string;
    vat1CodeCredit?: string;
    vat2CodeCredit?: string;
    vatTransportType?: OutgoingTaxCode.VatTransportTypeEnum;
    vatCustomerType?: OutgoingTaxCode.VatCustomerTypeEnum;
    activityCodeGroupId?: number;
    taxCodeIdentifier?: string;
    euTaxCodeIdentifier?: string;
    activityCodeGroup?: ActivityCodeGroup;
    invoiceCustomerGoodsOwnershipType?: OutgoingTaxCode.InvoiceCustomerGoodsOwnershipTypeEnum;
}
export namespace OutgoingTaxCode {
    export type VatTransportTypeEnum = 0 | 1 | 2 | 3 | 4;
    export const VatTransportTypeEnum = {
        NUMBER_0: 0 as VatTransportTypeEnum,
        NUMBER_1: 1 as VatTransportTypeEnum,
        NUMBER_2: 2 as VatTransportTypeEnum,
        NUMBER_3: 3 as VatTransportTypeEnum,
        NUMBER_4: 4 as VatTransportTypeEnum
    };
    export type VatCustomerTypeEnum = 0 | 1 | 2 | 3 | 4;
    export const VatCustomerTypeEnum = {
        NUMBER_0: 0 as VatCustomerTypeEnum,
        NUMBER_1: 1 as VatCustomerTypeEnum,
        NUMBER_2: 2 as VatCustomerTypeEnum,
        NUMBER_3: 3 as VatCustomerTypeEnum,
        NUMBER_4: 4 as VatCustomerTypeEnum
    };
    export type InvoiceCustomerGoodsOwnershipTypeEnum = 0 | 1 | 2;
    export const InvoiceCustomerGoodsOwnershipTypeEnum = {
        NUMBER_0: 0 as InvoiceCustomerGoodsOwnershipTypeEnum,
        NUMBER_1: 1 as InvoiceCustomerGoodsOwnershipTypeEnum,
        NUMBER_2: 2 as InvoiceCustomerGoodsOwnershipTypeEnum
    };
}