export const users = {
  LIST: {
    TITLE: "Users",
    //fields
    EMAIL: "Email",
    GIVEN_NAME: "First name",
    SURNAME: "Last name",
    DISPLAY_NAME: "Display name",
    ACCOUNT_TYPE: "Account type",
    STATUS: "Status",
    ACTIONS: "Actions",
  },
  DETAILS: {
    GENERAL: "General",
    TITLE: "User details",
    ADDITIONAL_INFORMATION: "Additional information",
    //fields
    EMAIL: "Email",
    GIVEN_NAME: "First name",
    SURNAME: "Last name",
    DISPLAY_NAME: "Display name",
    PHONE_NUMBER: "Phone number",
    ACCOUNT_TYPE: "Account type",
    STATUS: "Status",

    INITIALS: "Initials",
    USER_NUMBER: "User number",

    DEPARTMENTS: "Departments",
    ACCESSIBLE_TENANTS: "Accessible tenants",
    FORWARDING_RELATED_ACCESS: "Forwarding related access",
    CP_RELATED_ACCESS: "ClientPortal related access",
    TEMPLATES_MGT_RELATED_ACCESS: "Templates MGT related access",

    VALIDATION: {
      SELECT_AT_LEAST_ONE_DEPARTMENT: "At least one department should be selected",
    }
  },
  NEW: {
    TITLE: "Invite user",
    USER_INFORMATION: "User information",
    DEPARTMENTS: "Departments",
    USER_ACCESS: "User access",
    //fields
    EMAIL: "Email",
    GIVEN_NAME: "First name",
    SURNAME: "Last name",
    DISPLAY_NAME: "Display name",
    PHONE_NUMBER: "Phone number",
    TENANT: "Tenant",
    INITIALS: "Initials",
    USER_NUMBER: "User number",
  },
  STATUSES: {
    ACTIVE: "Active",
    DEACTIVATED: "Deactivated",
  },
  ACCOUNT_TYPES: {
    LOCAL: 'Local',
    EXTERNAL: 'External'
  },
  MESSAGES: {
    USER_NUMBER_TAKEN: "User number is taken already please use another one"
  }
}
