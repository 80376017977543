/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface DocumentLookup { 
    id?: number;
    description?: string;
    hasParty?: boolean;
    hasCustomsAgentParty?: boolean;
    hasGoods?: boolean;
    hasContainers?: boolean;
    hasTransport?: boolean;
    hasShippingInstructions?: boolean;
    isCustomsAgentPartyRequired?: boolean;
    isTransportRequired?: boolean;
    isContainersRequired?: boolean;
    isGoodsRequired?: boolean;
    isShippingInstructionsRequired?: boolean;
    hasBillOfLadingNumbers?: boolean;
    additionalTextValue?: string;
    hasTransportStops?: boolean;
    isTransportStopRequired?: boolean;
    isPartyRequired?: boolean;
    hasBooking?: boolean;
    isBookingRequired?: boolean;
    hasCustomerAddress?: boolean;
    isCustomerAddressRequired?: boolean;
    hasContactPerson?: boolean;
    isContactPersonRequired?: boolean;
    isGoodsDependOnContainers?: boolean;
    emailsAutocompleteSource?: DocumentLookup.EmailsAutocompleteSourceEnum;
}
export namespace DocumentLookup {
    export type EmailsAutocompleteSourceEnum = 0 | 1;
    export const EmailsAutocompleteSourceEnum = {
        NUMBER_0: 0 as EmailsAutocompleteSourceEnum,
        NUMBER_1: 1 as EmailsAutocompleteSourceEnum
    };
}