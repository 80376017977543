/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Place } from './place';
import { Port } from './port';

export interface Terminal { 
    rowCreatedTimestamp?: Date;
    lastRowModificationTimestamp?: Date;
    isDeleted?: boolean;
    id?: number;
    masterDataStatus?: Terminal.MasterDataStatusEnum;
    timeOfBlocking?: Date;
    code?: string;
    description?: string;
    street?: string;
    houseNumber?: string;
    placeId?: number;
    portId?: number;
    place?: Place;
    port?: Port;
    quayCode?: string;
    waterwaySection?: string;
    validationOfficeCode?: string;
    exitOfficeCode?: string;
    locationCode?: string;
}
export namespace Terminal {
    export type MasterDataStatusEnum = 0 | 1;
    export const MasterDataStatusEnum = {
        NUMBER_0: 0 as MasterDataStatusEnum,
        NUMBER_1: 1 as MasterDataStatusEnum
    };
}