import { Component, OnInit, NgZone, ChangeDetectorRef, Input } from "@angular/core";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { SendEmailDto } from "../../../../../core/models/utilities/send-email-dto.model";
import { ToasterService } from "../../../../layout/toaster/toaster.service";
import { LoadingOverlayService } from "../../../../../core/services/loading-overlay/loading-overlay.service";
import { EntityDetailsComponent } from "../../../../../core/components/abstractions/entity-details.component";
import { ActivatedRoute } from "@angular/router";
import { NamedBlob } from "../../../../../core/models/files/named-blob";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { map, switchMap, takeUntil, tap, toArray } from "rxjs/operators";
import { multiEmailValidator } from "../../../../../core/validators/email.validator";
import { MailService } from "../../../../../core/services/utilities/mail.service";
import { UnsavedChangesService } from "../../../../../core/services/helpers/unsaved-changes.service";
import { UblConfiguration } from "../../../../../core/models/dialogs/ubl-configuration";
import { concat, Observable, of } from "rxjs";
import { AzureDocument, CompanyRelatedEmailDTO, DocumentEditorService, EmailTemplateTypeDTO, FormattedEmailTemplateDTO, Response } from "../../../../../core/services/swagger-gen/fordesk";
import { PostEmailSendDTO } from "./models/post-email-send-dto";
import { DigitalArchiveService } from "../../../../../core/services/digital-archive.service";
import { DocumentFile } from "../../../../../core/models/documents/document-file";
import { IMailingSourceBehavior } from "../../../../../core/helpers/mailing/mailing-source-behavior";


@Component({
  selector: "mail-component",
  templateUrl: "./mail.component.html",
  styleUrls: ["./mail.component.scss"]
})
export class MailComponent extends EntityDetailsComponent implements OnInit {

  public editorConfig: AngularEditorConfig = {
    minHeight: "300px",
    editable: true,
    spellcheck: true,
    defaultFontName: "Arial"
  };

  public allowedExtensions: string[] = [
    "text/plain",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.ms-excel.sheet.macroenabled.12",
    "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
    "application/pdf",
    "application/rtf",
    "application/msword",
    "image/tiff",
    "text/rtf",
    "message/rfc822",
    "application/vnd.ms-outlook",
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/gif"
  ];

  public get getAllowedExtension(): string {
    return this.allowedExtensions.join(",");
  }

  public files: NamedBlob[] = [];

  public hasFileName: boolean = false;

  public pdfFile: NamedBlob = null;
  public uploadPdfFileAfterSendAction$: (arg: PostEmailSendDTO) => Observable<AzureDocument>;

  @Input()
  public bccMailRecipients: string[];

  @Input()
  public preFilledRecipients: any[] = [];

  @Input()
  public ublConfig: UblConfiguration;

  @Input()
  public allDocuments: DocumentFile[] = [];

  @Input()
  public actionAfterSend: Function;

  @Input()
  mailingSourceBehavior: IMailingSourceBehavior;

  @Input()
  public emailTemplates: EmailTemplateTypeDTO[] = [];

  @Input()
  public getEmailsAutocompleteSuggestions: (text: string) => Observable<CompanyRelatedEmailDTO[]>;

  @Input()
  public getAutocompleteSuggestions: (text: string) => Observable<EmailDisplayModel[]>;

  @Input()
  public getDocuments: () => Observable<DocumentFile[]>;

  private preFilledRecipientEmails: EmailDisplayModel[] = [];

  constructor(
    private documentEditorService: DocumentEditorService,
    private digitalArchive: DigitalArchiveService,
    public fb: UntypedFormBuilder,
    public emailService: MailService,
    public dialog: NgbModal,
    public translate: TranslateService,
    public toaster: ToasterService,
    public overlay: LoadingOverlayService,
    public activatedRoute: ActivatedRoute,
    public dialogRef: NgbActiveModal,
    public zone: NgZone,
    unsavedChangedService: UnsavedChangesService,
    public cdr: ChangeDetectorRef
  ) {
    super(activatedRoute, unsavedChangedService);
  }

  public addFileToUploader(event) {
    if (event && event[0]) {

      if (event.length > 1) {
        this.toaster.error(this.translate.instant("FINANCE.INCOMING_INVOICES_DETAILS.ONLY_ONE_FILE_AVAILABLE_FOR_UPLOAD"));
        return;
      }

      const file = event[0];

      if (!(this.allowedExtensions.find(x => x == file.type))) {
        this.toaster.error(this.translate.instant("FINANCE.INCOMING_INVOICES_DETAILS.UPLOAD_PDF_OR_IMAGE"));
        return;
      }

      const namedBlob: NamedBlob = new NamedBlob();
      namedBlob.fileName = event[0]?.name;
      namedBlob.blob = event[0];

      this.files.push(namedBlob);
    }
  }

  public ngOnInit() {
    if (this.getDocuments != null) {
      this.getDocuments().pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
        this.allDocuments = res;
        this.cdr.detectChanges();
      });
    }
    this.buildForm();
    this.loadEmailTemplates();
    this.applyEmailAutocompleteTransormations();
  }

  private buildForm() {
    this.preFilledRecipientEmails = this.preFilledRecipients.map(x => {
      return typeof x === "string"
        ? { display: x, value: x }
        : { display: this.formatCompanyRelatedEmail(x), value: x.email };
    });
    this.detailsForm = this.fb.group({
      selectedDocuments: [null],
      recipients: [this.preFilledRecipientEmails, [Validators.required, multiEmailValidator()]],
      subject: [null, Validators.required],
      body: [null, Validators.required]
    });
  }

  private loadEmailTemplates() {
    if (!this.mailingSourceBehavior) {
      return;
    }

    this.overlay.startLoading();
    this.mailingSourceBehavior.getEmailTemplates()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((emailTemplates: EmailTemplateTypeDTO[]) => {
        this.overlay.stopLoading();
        this.emailTemplates = emailTemplates;
        this.applyDefaultEmailTemplate();
      });
  }

  private applyDefaultEmailTemplate() {
    if (this.emailTemplates.length) {
      const emailTemplate = this.emailTemplates.find(t => t.isDefault);
      if (emailTemplate) {
        this.applyEmailTemplate(emailTemplate);
      }
    }
  }

  public applyEmailTemplate(emailTemplate: EmailTemplateTypeDTO) {
    this.overlay.startLoading();
    this.mailingSourceBehavior.formatEmailTemplate(emailTemplate)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((formattedTemplate: FormattedEmailTemplateDTO) => {
        this.overlay.stopLoading();
        this.detailsForm.controls.subject.setValue(formattedTemplate.subject);
        this.detailsForm.controls.body.setValue(formattedTemplate.body);
      });
  }

  public applyEmailAutocompleteTransormations() {
    this.getAutocompleteSuggestions = (text: string) => {
      return this.getEmailsAutocompleteSuggestions ? this.getEmailsAutocompleteSuggestions(text).pipe(
        map((values: CompanyRelatedEmailDTO[]) => (
          values.map(v => ({ display: this.formatCompanyRelatedEmail(v), value: v.email } as EmailDisplayModel))
        )), takeUntil(this.unsubscribe)
      ) : of([]);
    };
  }

  public save() {
    this.removeHover("send-mail-btn");
    super.save();
  }

  protected saveInternal() {
    this.send();
  }

  public documentSearchFn(search: string, documents: DocumentFile): boolean {
    const lowerSearch = search.toLocaleLowerCase();
    const labelToSearch: string = `${documents?.folder ? `${documents?.folder}/` : ""}${documents?.name}`.toLocaleLowerCase()
    return labelToSearch.includes(lowerSearch);
  }

  private send() {
    const emailDto = this.mapToEmailDto();
    this.overlay.startLoading();
    this.emailService.sendEmail(emailDto, this.files, this.ublConfig, this.bccMailRecipients?.filter(mail => !!mail))
      .pipe(takeUntil(this.unsubscribe),
        switchMap(response => {
          if (this.actionAfterSend && response.success) {
            this.actionAfterSend();
          }

          return of(response);
        }),
        switchMap((response) => {

          if (this.uploadPdfFileAfterSendAction$ && this.pdfFile) {
            return this.uploadPdfFileAfterSendAction$({ emails: emailDto.recipientAddresses, subject: emailDto.subject, message: emailDto.body, pdfFile: this.pdfFile.blob, pdfFileName: this.pdfFile.fileName })
              .pipe(
                takeUntil(this.unsubscribe),
                tap(() => this.digitalArchive.notifyRefresh()),
                map(() => response),
              );
          }

          return of(response);
        })
      ).subscribe((response: Response) => {
        this.overlay.stopLoading();

        if (response.success) {
          const message: string = this.translate.instant("MAIL_COMPONENT.MAIL_SEND_SUCCESS");
          this.toaster.success(message);
          this.closeDialog();
        } else {
          const message: string = this.translate.instant("MAIL_COMPONENT.MAIL_SEND_ERROR");
          this.toaster.error(message);
        }
      });
  }

  private mapToEmailDto(): SendEmailDto {
    const recipients = this.detailsForm.controls.recipients.value.map(x => x.value);
    return {
      body: this.detailsForm.controls.body.value,
      subject: this.detailsForm.controls.subject.value,
      recipientAddresses: recipients
    };
  }

  public cancel() {
    this.closeDialog();
  }

  private closeDialog() {
    this.dialogRef.close();
  }

  private removeHover(elementId: string) {
    const element = document.getElementById(elementId);
    if (element) {
      element.blur();
    }
  }

  public removeAttachment(fileName: string) {
    const index = this.files.findIndex(x => x.fileName === fileName);
    if (index !== -1) {
      this.files.splice(index, 1);
    }
  }
  public addAttachment(event) {
    if (event && event?.length > 0) {
      const files = [...event];

      if (files.some(file => {
        return !this.allowedExtensions.includes(file.type);
      })) {
        return;
      }
      const reader = new FileReader();
      files.forEach(element => {
        const namedBlob: NamedBlob = new NamedBlob();
        namedBlob.fileName = element.name;
        namedBlob.blob = element;

        this.files.push(namedBlob);
      });
    }
  }

  public isFileSelected(item: any) {
    return this.files.find(x => x.fileName == `${item?.folder ? `${item?.folder}/` : ""}${item?.name}`)
  }

  public attachDocument() {
    if (!this.f.selectedDocuments.value && !this.f.selectedDocuments.value?.length) {
      this.toaster.error(this.translate.instant("MAIL_COMPONENT.NO_DOCUMENT_IS_CHOSEN"));
      return;
    }
    const uploading$ = this.f.selectedDocuments.value.map(file => {
      const fileToLoad = this.allDocuments.find((x) => x?.id == file?.id);

      return this.documentEditorService.downloadByPath(fileToLoad?.prefix, fileToLoad?.name).pipe(tap(res => {
        const namedBlob: NamedBlob = new NamedBlob();
        namedBlob.fileName = `${fileToLoad?.folder ? `${fileToLoad?.folder}/` : ""}${fileToLoad?.name}`;
        namedBlob.blob = res;
        this.files.push(namedBlob);
      }), takeUntil(this.unsubscribe));
    })

    concat(...uploading$).pipe(takeUntil(this.unsubscribe), toArray()).subscribe(() => this.f.selectedDocuments.reset());
  }

  private formatCompanyRelatedEmail(model: CompanyRelatedEmailDTO) {
    return model.partyTypeDescription
      ? `${model.email} (${model.partyTypeDescription})`
      : `${model.email}`
  }
}

interface EmailDisplayModel {
  value: any;
  display: string;
}
