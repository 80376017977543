/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Booking } from './booking';
import { Currency } from './currency';
import { OperationalFileBudgetSummary } from './operationalFileBudgetSummary';
import { OperationalFileContainer } from './operationalFileContainer';
import { OperationalFileCustomsDeclaration } from './operationalFileCustomsDeclaration';
import { OperationalFileGeneral } from './operationalFileGeneral';
import { OperationalFileGoods } from './operationalFileGoods';
import { OperationalFileParty } from './operationalFileParty';
import { OperationalFileShippingInstructions } from './operationalFileShippingInstructions';
import { OperationalFileStep } from './operationalFileStep';
import { OperationalFileSummary } from './operationalFileSummary';
import { OperationalFileTransport } from './operationalFileTransport';
import { OperationalFileUser } from './operationalFileUser';

export interface OperationalFile { 
    rowCreatedTimestamp?: Date;
    lastRowModificationTimestamp?: Date;
    isDeleted?: boolean;
    id?: number;
    fileNumber?: string;
    customerReference?: string;
    isBudgetClosed?: boolean;
    operationalFileType?: OperationalFile.OperationalFileTypeEnum;
    transportType?: OperationalFile.TransportTypeEnum;
    shipmentType?: OperationalFile.ShipmentTypeEnum;
    status?: OperationalFile.StatusEnum;
    currencyId?: number;
    currency?: Currency;
    operationalFileGeneral?: OperationalFileGeneral;
    operationalFileUserId?: number;
    operationalFileUser?: OperationalFileUser;
    departmentId?: number;
    department?: string;
    operationalFileBudgetSummary?: OperationalFileBudgetSummary;
    operationalFileSummary?: OperationalFileSummary;
    operationalFileShippingInstructions?: Array<OperationalFileShippingInstructions>;
    operationalFileCustomsDocuments?: Array<OperationalFileCustomsDeclaration>;
    operationalFileContainers?: Array<OperationalFileContainer>;
    operationalFileGoods?: Array<OperationalFileGoods>;
    operationalFileParties?: Array<OperationalFileParty>;
    operationalFileSteps?: Array<OperationalFileStep>;
    operationalFileTransports?: Array<OperationalFileTransport>;
    bookings?: Array<Booking>;
    fileDate?: Date;
}
export namespace OperationalFile {
    export type OperationalFileTypeEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6;
    export const OperationalFileTypeEnum = {
        NUMBER_0: 0 as OperationalFileTypeEnum,
        NUMBER_1: 1 as OperationalFileTypeEnum,
        NUMBER_2: 2 as OperationalFileTypeEnum,
        NUMBER_3: 3 as OperationalFileTypeEnum,
        NUMBER_4: 4 as OperationalFileTypeEnum,
        NUMBER_5: 5 as OperationalFileTypeEnum,
        NUMBER_6: 6 as OperationalFileTypeEnum
    };
    export type TransportTypeEnum = 0 | 1 | 2 | 3;
    export const TransportTypeEnum = {
        NUMBER_0: 0 as TransportTypeEnum,
        NUMBER_1: 1 as TransportTypeEnum,
        NUMBER_2: 2 as TransportTypeEnum,
        NUMBER_3: 3 as TransportTypeEnum
    };
    export type ShipmentTypeEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6;
    export const ShipmentTypeEnum = {
        NUMBER_0: 0 as ShipmentTypeEnum,
        NUMBER_1: 1 as ShipmentTypeEnum,
        NUMBER_2: 2 as ShipmentTypeEnum,
        NUMBER_3: 3 as ShipmentTypeEnum,
        NUMBER_4: 4 as ShipmentTypeEnum,
        NUMBER_5: 5 as ShipmentTypeEnum,
        NUMBER_6: 6 as ShipmentTypeEnum
    };
    export type StatusEnum = 0 | 1 | 2;
    export const StatusEnum = {
        NUMBER_0: 0 as StatusEnum,
        NUMBER_1: 1 as StatusEnum,
        NUMBER_2: 2 as StatusEnum
    };
}