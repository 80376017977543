import { throwError, Observable, interval } from "rxjs";
import { Injectable, Injector } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { LoadingOverlayService } from "../services/loading-overlay/loading-overlay.service";
import { catchError, filter, mergeMap, take, tap } from "rxjs/operators";
import { ToasterService } from "../../content/layout/toaster/toaster.service";
import { TranslateService } from "@ngx-translate/core";
import { BAD_REQUEST, CONFLICT, INTERNAL_SERVER_ERROR, NOT_FOUND, UNAUTHORIZED } from "http-status-codes";
import { ProblemDetails } from "../models/problem-details";
import { ErrorComponent } from "../../content/dialogs/error/error.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RestError } from "@azure/ms-rest-js";
import { LostInternetConnectionService } from "../services/http/lost-internet-connection.service";
import { DigitalArchiveService } from "../services/digital-archive.service";
import { MsalService } from "@azure/msal-angular";

interface ForwardingError {
  occuredAt: Date;
  translationKey: string;
  errorGuid: string;
  payload: string;
}

class ErrorMessage {
  public title: string;
  public message: string;
}

@Injectable({ providedIn: "root" })
export class GlobalHTTPErrorInterceptorService implements HttpInterceptor {
  private toaster: ToasterService;
  private overlay: LoadingOverlayService;
  private translate: TranslateService;
  private lostInternetConnectionService: LostInternetConnectionService;
  private digitalArchiveService: DigitalArchiveService;
  private msalService: MsalService;
  private shouldLogOut: boolean = false;

  private customErrorMessages: CustomInterceptorErrorMesage[] = [
    {
      statusCode: 400,
      url: "finance/incoming-invoices/upload-ubl",
      titleKey: "MESSAGES.ERROR",
      messageKey: "MESSAGES.UPLOADED_DOCUMENT_NOT_RECOGNISED",
    },
    {
      statusCode: 504,
      url: "finance/incoming-invoices/upload-ubl",
      titleKey: "MESSAGES.ERROR",
      messageKey: "MESSAGES.UPLOADED_DOCUMENT_NOT_RECOGNISED",
    },
  ]

  constructor(private injector: Injector, private modalService: NgbModal) {
    this.toaster = this.injector.get(ToasterService);
    this.overlay = this.injector.get(LoadingOverlayService);
    this.translate = this.injector.get(TranslateService);
    this.lostInternetConnectionService = this.injector.get(LostInternetConnectionService);
    this.digitalArchiveService = this.injector.get(DigitalArchiveService);
    this.msalService = this.injector.get(MsalService);
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // List of requests that should not show error responses to the user.
    if (request.url.search("login") !== -1
      || request.url.search("refresh") !== -1
      || request.url.search("ping.json") !== -1
      || request.url.endsWith("api/notifications/count")) {
      return next.handle(request);
    }

    return next
      .handle(request)
      .pipe(
        tap((response) => this.tryHandleWarnings(response)),
        catchError((error: HttpErrorResponse) => {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(`Backend returned code ${error.status}.`);

          if (error.url.includes(".well-known/openid-configuration")) {

            if (!this.shouldLogOut) {
              this.shouldLogOut = true;
              const subscription = interval(1000).pipe(
                mergeMap(_ => {
                  return this.lostInternetConnectionService
                    .isOffline().pipe(take(1));
                }),
                filter(isOffline => !isOffline)
              )
                .subscribe(_ => {
                  subscription.unsubscribe();
                  this.msalService.logout();
                  this.shouldLogOut = false;
                  window.location.href = window.location.origin;
                });
            }
            return throwError(error);
          }
          this.lostInternetConnectionService
            .isOffline()
            .pipe(take(1))
            .subscribe(isOffline => {
              if (isOffline) {
                this.lostInternetConnectionService.notifyUser();
              } else {
                if (error.status == CONFLICT) {
                  return throwError(error);
                }
                if (this.isBlobError(error)) {
                  this.parseErrorBlob(error).subscribe((err) => this.handleError(err));
                }
                else {
                  this.handleError(error);
                }
              }
            });
          this.handleAlways();
          return throwError(error);
        })
      );
  }

  private handleError(error: HttpErrorResponse) {
    const customErrorMessage: ErrorMessage = this.getCustomErrorMessage(error);
    if (!customErrorMessage && this.isProblemDetails(error.error)) {
      const title = this.getTitleByError(error);
      const problemDetails = error.error as ProblemDetails;
      const details = this.getMessageFromProblemDetails(problemDetails);
      if (details.length === 1) {
        // This error does not need to be output as it is handled in the component itself
        if (details.join() !== "A file with the given name already exists.") {
          if (error.status === INTERNAL_SERVER_ERROR) {
            const errorMessage = this.getGenericErrorMessage();
            this.toaster.showError(errorMessage.title, errorMessage.message);
          } else {
            this.toaster.showError(title, details.join());
          }

          if (details.join() === "This file could not be opened because it's corrupted or contains unsupported characters or has an unknown format.") {
            this.digitalArchiveService.notifyCloseModal();
          }
        }
      } else {
        const modalRef = this.modalService.open(ErrorComponent, {
          backdrop: "static",
          keyboard: false,
        });
        modalRef.componentInstance.data = { title: title, errors: details };
      }
    } else {
      if (error.error instanceof Error) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error("An error occurred:", error.error.message);
      }

      let errorMessage: ErrorMessage = customErrorMessage;
      if (!errorMessage) {
        // TODO: Eventually, we'll need to be able to translate backend errors into the client's language.
        // TODO: We'll only use English for now.
        if (error.status === BAD_REQUEST) {
          errorMessage = this.getBadRequestMessage(error);
        } else if (error.status === NOT_FOUND) {
          errorMessage = this.getNotFoundMessage(error);
        } else if (error.status === UNAUTHORIZED) {
          // rest of logic is handled by auth.interceptor
          // don't show an error here
          errorMessage = null;
        } else {
          errorMessage = this.getGenericErrorMessage();
        }
      }

      if (errorMessage) {
        this.toaster.showError(errorMessage.title, errorMessage.message);
      }
    }
  }

  private getMessageFromProblemDetails(problemDetails: ProblemDetails): string[] {
    let details: string[] = problemDetails.detail.split(";");
    while (this.isJSON(details.join(";"))) {
      const problemDetailsInDetails = JSON.parse(details.join(";")) as ProblemDetails;
      details = problemDetailsInDetails.detail.split(";");
    }
    return details;
  }

  private getTitleByError(response: HttpErrorResponse): string {

    switch (this.getStatusCode(response)) {
      case BAD_REQUEST:
        return this.translate.instant("MESSAGES.BAD_REQUEST");

      case NOT_FOUND:
        return this.translate.instant("MESSAGES.NOT_FOUND");

      default:
        return this.translate.instant("MESSAGES.ERROR");
    }
  }

  private getStatusCode(response: HttpErrorResponse) {
    return response.error.errors ? response.error.errors.status : response.error.status;
  }

  private getCustomErrorMessage(error: HttpErrorResponse): ErrorMessage {
    const statusCode = this.getStatusCode(error)
    const customMessage = this.customErrorMessages
      .find(m => m.statusCode === statusCode && error.url.endsWith(m.url));
    if (customMessage) {
      return {
        title: this.translate.instant(customMessage.titleKey),
        message: this.translate.instant(customMessage.messageKey),
      };
    }
  }

  private isProblemDetails(err: any): boolean {
    return err.hasOwnProperty("type") && err.hasOwnProperty("status") && err.hasOwnProperty("detail");
  }

  private handleAlways() {
    this.overlay.stopLoading();
  }

  private isJSON(value: string): boolean {
    try {
      return (JSON.parse(value) && !!value);
    } catch (e) {
      return false;
    }
  }

  private getBadRequestMessage(error: HttpErrorResponse): ErrorMessage {
    const forwardingError = error.error as ProblemDetails;
    if (forwardingError.detail) {
      return {
        title: this.translate.instant("MESSAGES.BAD_REQUEST"),
        message: forwardingError.detail
      };
    } else {
      let errorMessage: string = this.getErrorMessage(error);
      if (!errorMessage) {
        errorMessage = `${this.translate.instant("MESSAGES.INVALID_OR_MISSING_DATA")} ${this.translate.instant("MESSAGES.CORRECT_AND_RETRY")}`;
      }

      return {
        title: this.translate.instant("MESSAGES.BAD_REQUEST"),
        message: errorMessage
      };
    }
  }

  private getNotFoundMessage(error: HttpErrorResponse): ErrorMessage {
    const errorMessage: string = this.getErrorMessage(error);

    return {
      title: this.translate.instant("MESSAGES.NOT_FOUND"),
      message: errorMessage
    };
  }

  private getGenericErrorMessage(): ErrorMessage {
    return {
      title: this.translate.instant("MESSAGES.ERROR"),
      message: `${this.translate.instant("MESSAGES.GENERIC_QUERY_ERROR")} ${this.translate.instant("MESSAGES.TRY_AGAIN")}`
    };
  }

  private getErrorMessage(error: HttpErrorResponse): string {
    let errors: string[];
    if (typeof error.error === "string") {
      errors = [error.error];
      return errors.join();
    } else {
      // TODO: causes type error, fix
      return `${this.translate.instant("MESSAGES.GENERIC_QUERY_ERROR")} ${this.translate.instant("MESSAGES.TRY_AGAIN")}`;
      // errors = error.error as string[];
      // if (errors.length === 1) {
      //   return errors.join();
      // } else {
      //   // TODO: ugly
      //   return `<ul class="list-unstyled"><li>${errors.join("</li><li>")}</li></ul>`;
      // }
    }
  }

  private parseErrorBlob(err: HttpErrorResponse): Observable<HttpErrorResponse> {
    const reader: FileReader = new FileReader();
    const obs = new Observable<HttpErrorResponse>((observer: any) => {
      reader.onloadend = (e) => {
        observer.next({
          ...err,
          error: JSON.parse(reader.result as string),
        } as HttpErrorResponse);
        observer.complete();
      };
    });
    reader.readAsText(err.error);
    return obs;
  }

  private isBlobError(err: RestError): boolean {
    return err instanceof HttpErrorResponse && err.error instanceof Blob && err.error.type === "application/problem+json";
  }

  private tryHandleWarnings(response: any) {
    const body = response?.body;

    if (body?.success && body?.messages) {
      for (const message of body?.messages) {
        if (message) {
          this.toaster.warning(message);
        }
      }
    }
  }
}

interface CustomInterceptorErrorMesage {
  statusCode: number,
  url: string,
  titleKey: string,
  messageKey: string,
}
