/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CompanyDTO { 
    companyName: string;
    addressLine1: string;
    addressLine2?: string;
    alias?: string;
    stateProvinceRegion?: string;
    phone?: string;
    mobile?: string;
    email?: string;
    ibanNumber?: string;
    swiftCode?: string;
    externalReference?: string;
    placeId?: number;
    languageId?: number;
    currencyId?: number;
    paymentTermId?: number;
    messageTypeId?: number;
    carrierIdentifier?: string;
    shortScacCode?: string;
    isBillable?: boolean;
    isVatLiable?: boolean;
    vatNumber?: string;
    legacyAccountingNumber?: string;
    accountingGroupId?: number;
    isGoodsOwner?: boolean;
    status?: CompanyDTO.StatusEnum;
    communicationType?: CompanyDTO.CommunicationTypeEnum;
}
export namespace CompanyDTO {
    export type StatusEnum = 0 | 1 | 2;
    export const StatusEnum = {
        NUMBER_0: 0 as StatusEnum,
        NUMBER_1: 1 as StatusEnum,
        NUMBER_2: 2 as StatusEnum
    };
    export type CommunicationTypeEnum = 0 | 1;
    export const CommunicationTypeEnum = {
        NUMBER_0: 0 as CommunicationTypeEnum,
        NUMBER_1: 1 as CommunicationTypeEnum
    };
}