/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface FoundSupplierIntegrationDTO { 
    integrationId?: number;
    isEnabled?: boolean;
    supplierIntegrationImplementationType?: FoundSupplierIntegrationDTO.SupplierIntegrationImplementationTypeEnum;
}
export namespace FoundSupplierIntegrationDTO {
    export type SupplierIntegrationImplementationTypeEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13;
    export const SupplierIntegrationImplementationTypeEnum = {
        NUMBER_0: 0 as SupplierIntegrationImplementationTypeEnum,
        NUMBER_1: 1 as SupplierIntegrationImplementationTypeEnum,
        NUMBER_2: 2 as SupplierIntegrationImplementationTypeEnum,
        NUMBER_3: 3 as SupplierIntegrationImplementationTypeEnum,
        NUMBER_4: 4 as SupplierIntegrationImplementationTypeEnum,
        NUMBER_5: 5 as SupplierIntegrationImplementationTypeEnum,
        NUMBER_6: 6 as SupplierIntegrationImplementationTypeEnum,
        NUMBER_7: 7 as SupplierIntegrationImplementationTypeEnum,
        NUMBER_8: 8 as SupplierIntegrationImplementationTypeEnum,
        NUMBER_9: 9 as SupplierIntegrationImplementationTypeEnum,
        NUMBER_10: 10 as SupplierIntegrationImplementationTypeEnum,
        NUMBER_11: 11 as SupplierIntegrationImplementationTypeEnum,
        NUMBER_12: 12 as SupplierIntegrationImplementationTypeEnum,
        NUMBER_13: 13 as SupplierIntegrationImplementationTypeEnum
    };
}