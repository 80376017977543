import { HttpCancelService } from "./core/services/http/http-cancel.service";
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, HostListener, OnInit, ViewChild } from "@angular/core";
import { LayoutConfigService } from "./core/services/layout-config.service";
import { ClassInitService } from "./core/services/class-init.service";
import * as objectPath from "object-path";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivationEnd, NavigationEnd, NavigationStart, Router } from "@angular/router";
import { PageConfigService } from "./core/services/page-config.service";
import { concatMap, filter, switchMap, take, takeUntil, tap } from "rxjs/operators";
import { SplashScreenService } from "./core/services/splash-screen.service";
import { LoadingOverlayService } from "./core/services/loading-overlay/loading-overlay.service";
import { NgSelectConfig } from "@ng-select/ng-select";
import { TranslateService } from "@ngx-translate/core";
import { OutgoingInvoiceRefreshService } from "./core/services/finance/outgoing-invoice-refresh.service";
import { ReleaseNoteService, TenantDTO, TenantsService, UsersService } from "./core/services/swagger-gen/fordesk";
import { EMPTY, forkJoin, Observable, of } from "rxjs";
import { ReleasePreviewDialogComponent } from "./content/dialogs/release-preview-dialog/release-preview-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { ComponentBase } from "./core/components/abstractions/component-base";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { InteractionStatus } from "@azure/msal-browser";
import { environment } from "../environments/environment";
import { AuthenticationService } from "./core/auth/fordesk/services/authentication.service";
import { UserService } from "./core/auth/fordesk/services/user.service";
import { SelectedTenant, TenantSynchronizationService } from "./core/services/tenants-synchronization.service";
import { SELECTED_TENANT } from "./core/services/helpers/session-storage-keys";
import { UserPermissionService } from "./core/auth/fordesk/services/user.permissions.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ConfugureUserDataComponent } from "./content/dialogs/please-configure-user-data/please-configure-user-data.component";
import { ToastrService } from "ngx-toastr";
// LIST KNOWN ISSUES
// [Violation] Added non-passive event listener; https://github.com/angular/angular/issues/8866

@Component({
  // tslint:disable-next-line:component-selector
  selector: "body[m-root]",
  templateUrl: "./app.component.html",
  changeDetection: ChangeDetectionStrategy.Default
})
export class AppComponent extends ComponentBase implements AfterViewInit, OnInit {
  private isOverlayVisible = false;

  @HostBinding("style") public style: any;
  @HostBinding("class") public classes: any = "";

  @ViewChild("splashScreen", { read: ElementRef, static: false })
  public splashScreen: ElementRef;
  public splashScreenImage: string;

  constructor(
    private toastr: ToastrService,
    private layoutConfigService: LayoutConfigService,
    private classInitService: ClassInitService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private pageConfigService: PageConfigService,
    private splashScreenService: SplashScreenService,
    private overlay: LoadingOverlayService,
    private translate: TranslateService,
    private httpCancelService: HttpCancelService,
    private outgoingInvoiceRefreshService: OutgoingInvoiceRefreshService,
    private releaseNoteService: ReleaseNoteService,
    private dialog: MatDialog,
    config: NgSelectConfig,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private authService: AuthenticationService,
    private userPermissionsService: UserPermissionService,
    private userService: UserService,
    private apiUserService: UsersService,
    private tenantsService: TenantsService,
    private tenantSynchronizationService: TenantSynchronizationService,
    public ngbModal: NgbModal,
  ) {
    super();
    // subscribe to class update event
    this.classInitService
      .onClassesUpdated$
      .subscribe(classes => {
        // get body class array, join as string classes and pass to host binding class
        setTimeout(() => this.classes = classes.body.join(" "));
      });

    this.layoutConfigService
      .onLayoutConfigUpdated$
      .subscribe(model => {
        this.classInitService.setConfig(model);

        this.style = "";
        if (objectPath.get(model.config, "self.layout") === "boxed") {
          const backgroundImage = objectPath.get(model.config, "self.background");
          if (backgroundImage) {
            const bgStyle: string = `background-image: url("${objectPath.get(model.config, "self.background")}")`;
            this.style = this.sanitizer.bypassSecurityTrustStyle(bgStyle);
          }
        }

        // splash screen image
        this.splashScreenImage = objectPath.get(model.config, "loader.image");
      });

    // register translations
    // this.translationService.loadTranslations(enLang);

    this.overlay.getLoadingSubjectAsObservable()
      .subscribe(loading => {
        this.isOverlayVisible = loading;
      });

    // show the loading overlay when navigating between lazy-loaded modules
    router.events.subscribe(event => {
      if (event instanceof ActivationEnd) {
        this.httpCancelService.cancelPendingRequests();
      } else if (event instanceof NavigationStart) {
        this.overlay.startLoading();
      } else if (event instanceof NavigationEnd) {
        this.overlay.stopLoading();
      }
    });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe)
      )
      .subscribe(() => {
        // override config by router change from pages config
        const model: object = { page: objectPath.get(this.pageConfigService.getCurrentPageConfig(), "config") };
        this.layoutConfigService.setModel(model, true);

        this.outgoingInvoiceRefreshService.check();
      });

    this.setGlobalNgSelectConfig(config);
  }

  public ngOnInit(): void {
    this.msalBroadcastService.inProgress$.pipe(filter((status: InteractionStatus) => status === InteractionStatus.None)).subscribe(() => {
      if (this.msalService.instance.getAllAccounts().length === 0) {
        this.msalService.loginRedirect({ prompt: 'select_account', scopes: [environment.authentication.fordeskApiScope] });
      } else if (!this.router.url.includes('preventRedirect')) {
        this.authService.checkAndSetActiveAccount();

        //Load allowed tenants and user permissions
        this.loadAllowedTenants().pipe(
          switchMap(() =>
            forkJoin({
              userPermissions: this.apiUserService.getCurrentUserPermissions(),
              hasGlobalAdminPermission: this.apiUserService.isCurrentUserGlobalAdmin()
            })
          ),
          tap((data: any) => {
            this.userPermissionsService.emitPermissionsChanged(data.userPermissions);
            this.userPermissionsService.emitUserHasGlobalAdminPemissions(data.hasGlobalAdminPermission);
          }),
          takeUntil(this.unsubscribe),
          switchMap(() => this.userService.msalUser$
            .pipe(
              takeUntil(this.unsubscribe),
              switchMap((user) => {
                return this.userService.loadUserProperties(user);
              }),
              tap(() => {
                const user = this.userService.getCurrentUser()
                const profileIsIncomplete = user.properties["extension_ProfileIsIncomplete"]
                if (profileIsIncomplete || profileIsIncomplete == undefined) {
                  const modalRef: NgbModalRef = this.ngbModal.open(ConfugureUserDataComponent, {
                    backdrop: 'static',
                    keyboard: false,
                    size: "md"
                  });

                  modalRef.componentInstance.userId = user.id

                  modalRef.result.then((result) => {
                    this.router.navigate(['config', 'users', user.id, 'details']);
                  }).catch((reason) => {
                  });
                }
              })
            ))
        ).subscribe(() => {
          this.loadReleaseNotes();
        });
      }
    });
  }

  private loadReleaseNotes() {
    setTimeout(() => {
      this.releaseNoteService.getAllActiveReleaseNotes({ disableLoader: true }).pipe(take(1), switchMap(data => {
        if (data && data.length > 0) {
          return forkJoin({
            releaseNotes: of(data),
            userSawNewRelease: this.releaseNoteService.checkUserOnViewedReleaseNote(data[0].id, { disableLoader: true }).pipe(take(1),
              takeUntil(this.unsubscribe))
          });
        }

        return EMPTY;
      }), takeUntil(this.unsubscribe)).subscribe(result => {
        if (!result.userSawNewRelease) {
          this.dialog.open(ReleasePreviewDialogComponent, {
            disableClose: true,
            height: "90vh",
            width: "90vw",
            maxWidth: "90vw",
            maxHeight: "90vh",
            autoFocus: false,
            data: { releases: result.releaseNotes, title: this.translate.instant("MODALS.FORDESK_HAS_BEEN_UPDATED") }
          });
        }
      })

    }, 1700);
  }

  private loadAllowedTenants(): Observable<TenantDTO[]> {
    return this.tenantsService.getAllowedTenants().pipe(tap((data: TenantDTO[]) => {
      this.tenantSynchronizationService.setAllowedTenants(data);
      //try set saved tenant
      const selectedTenantFromStorage = JSON.parse(localStorage.getItem(SELECTED_TENANT)) as SelectedTenant;
      if (data.some(x => x.id == selectedTenantFromStorage?.tenantId) && selectedTenantFromStorage?.externalTenantId && selectedTenantFromStorage?.tenantId) {
        this.tenantSynchronizationService.emitTenantChangedEvent(selectedTenantFromStorage);
      }
      //set default tenant
      else {
        const selectedTenant: SelectedTenant = {
          tenantId: data[0].id,
          externalTenantId: data[0].externalTenantId,
        }
        localStorage.setItem(SELECTED_TENANT, JSON.stringify(selectedTenant))
        this.tenantSynchronizationService.emitTenantChangedEvent(selectedTenant);
      }
    }), take(1))
  }

  public ngAfterViewInit(): void {
    if (this.splashScreen) {
      this.splashScreenService.init(this.splashScreen.nativeElement);
    }
  }

  @HostListener('document:keydown', ['$event'])
  public onKeyboardEvent(event: KeyboardEvent) {
    if (this.isOverlayVisible && event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  private setGlobalNgSelectConfig(config: NgSelectConfig) {
    if (!config) {
      return;
    }

    config.placeholder = this.translate.instant("TYPEAHEAD_MAKE_CHOICE");
  }
}
