import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense(environment.syncfusionAspNetCoreLicenseKey)

if (environment.production) {
  enableProdMode();
} else {
  console.log("environment:");
  console.log(environment);
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => {console.log(err);

  });
