/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PaymentTerm { 
    rowCreatedTimestamp?: Date;
    lastRowModificationTimestamp?: Date;
    isDeleted?: boolean;
    id?: number;
    masterDataStatus?: PaymentTerm.MasterDataStatusEnum;
    timeOfBlocking?: Date;
    code?: string;
    description?: string;
    sortOrder?: number;
    value?: PaymentTerm.ValueEnum;
}
export namespace PaymentTerm {
    export type MasterDataStatusEnum = 0 | 1;
    export const MasterDataStatusEnum = {
        NUMBER_0: 0 as MasterDataStatusEnum,
        NUMBER_1: 1 as MasterDataStatusEnum
    };
    export type ValueEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13;
    export const ValueEnum = {
        NUMBER_0: 0 as ValueEnum,
        NUMBER_1: 1 as ValueEnum,
        NUMBER_2: 2 as ValueEnum,
        NUMBER_3: 3 as ValueEnum,
        NUMBER_4: 4 as ValueEnum,
        NUMBER_5: 5 as ValueEnum,
        NUMBER_6: 6 as ValueEnum,
        NUMBER_7: 7 as ValueEnum,
        NUMBER_8: 8 as ValueEnum,
        NUMBER_9: 9 as ValueEnum,
        NUMBER_10: 10 as ValueEnum,
        NUMBER_11: 11 as ValueEnum,
        NUMBER_12: 12 as ValueEnum,
        NUMBER_13: 13 as ValueEnum
    };
}