/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AccountingGroup } from './accountingGroup';
import { ClientPortalInvitation } from './clientPortalInvitation';
import { CompanyAddress } from './companyAddress';
import { CompanyType } from './companyType';
import { Language } from './language';
import { MessageType } from './messageType';
import { Prospect } from './prospect';
import { SupplierIntegration } from './supplierIntegration';

export interface Company { 
    rowCreatedTimestamp?: Date;
    lastRowModificationTimestamp?: Date;
    isDeleted?: boolean;
    id?: number;
    name?: string;
    exportTimestamp?: Date;
    legacyAccountingNumber?: string;
    accountingNumber?: string;
    comment?: string;
    email?: string;
    phone?: string;
    externalReference?: string;
    mobile?: string;
    isBillable?: boolean;
    carrierIdentifier?: string;
    shortScacCode?: string;
    website?: string;
    blockReason?: string;
    isVatLiable?: boolean;
    vatNumber?: string;
    status?: Company.StatusEnum;
    isGoodsOwner?: boolean;
    ibanNumber?: string;
    ocrCompanyName?: string;
    ocrCompanyAddress?: string;
    ocrVatNumber?: string;
    ocrHash?: string;
    communicationType?: Company.CommunicationTypeEnum;
    swiftCode?: string;
    messageTypeId?: number;
    messageType?: MessageType;
    accountingGroup?: AccountingGroup;
    accountingGroupId?: number;
    languageId?: number;
    language?: Language;
    isToBeReviewed?: boolean;
    supplierIntegrations?: Array<SupplierIntegration>;
    clientPortalInvitations?: Array<ClientPortalInvitation>;
    companyAddresses?: Array<CompanyAddress>;
    companyTypeId?: number;
    companyType?: CompanyType;
    prospectId?: number;
    prospect?: Prospect;
    defaultAddress?: CompanyAddress;
}
export namespace Company {
    export type StatusEnum = 0 | 1 | 2;
    export const StatusEnum = {
        NUMBER_0: 0 as StatusEnum,
        NUMBER_1: 1 as StatusEnum,
        NUMBER_2: 2 as StatusEnum
    };
    export type CommunicationTypeEnum = 0 | 1;
    export const CommunicationTypeEnum = {
        NUMBER_0: 0 as CommunicationTypeEnum,
        NUMBER_1: 1 as CommunicationTypeEnum
    };
}