import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TenantSynchronizationService } from "../services/tenants-synchronization.service";
import { CurrentTenantId, ExternalTenantId } from "../constants/headerNames";

@Injectable()
export class UserInitialisationInterceptor implements HttpInterceptor {
  constructor(private tenantSynchronizationService: TenantSynchronizationService) {

  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentTenantData = this.tenantSynchronizationService.getCurrentTenant;

    let modifiedReq = request.clone();

    if (currentTenantData?.tenantId && currentTenantData?.externalTenantId) {
      modifiedReq = modifiedReq.clone({
        headers: modifiedReq.headers.append(CurrentTenantId, currentTenantData.tenantId.toString())
          .append(ExternalTenantId, currentTenantData.externalTenantId)
      });
    }
    return next.handle(modifiedReq)
  }
}
