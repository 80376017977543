/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CodaLinkedIncomingInvoiceDTO { 
    id?: number;
    type?: string;
    invoiceNumber?: string;
    accountingReference?: string;
    paymentReference?: string;
    amountInclVat?: number;
    currencyCode?: string;
    issueDate?: Date;
    status?: CodaLinkedIncomingInvoiceDTO.StatusEnum;
}
export namespace CodaLinkedIncomingInvoiceDTO {
    export type StatusEnum = 1 | 2 | 3 | 4 | 5;
    export const StatusEnum = {
        NUMBER_1: 1 as StatusEnum,
        NUMBER_2: 2 as StatusEnum,
        NUMBER_3: 3 as StatusEnum,
        NUMBER_4: 4 as StatusEnum,
        NUMBER_5: 5 as StatusEnum
    };
}