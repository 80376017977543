/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Company } from './company';
import { Currency } from './currency';
import { IncomingInvoiceLine } from './incomingInvoiceLine';
import { InvoiceComment } from './invoiceComment';
import { InvoiceType } from './invoiceType';
import { PaymentMethod } from './paymentMethod';
import { TaxTotal } from './taxTotal';
import { ValidatedOperationalFileCost } from './validatedOperationalFileCost';

export interface IncomingInvoice { 
    rowCreatedTimestamp?: Date;
    lastRowModificationTimestamp?: Date;
    isDeleted?: boolean;
    id?: number;
    currencyId?: number;
    currency?: Currency;
    protestReason?: string;
    protestTimestamp?: Date;
    customerReference?: string;
    issueDate?: Date;
    dueDate?: Date;
    paidDate?: Date;
    sepaExportDate?: Date;
    orderReference?: string;
    supplierEmail?: string;
    supplierTelephone?: string;
    lineExtensionAmount?: number;
    payableAmount?: number;
    amountIncludingVat?: number;
    amountExcludingVat?: number;
    invoiceNumber?: string;
    draftNumber?: string;
    accountingNumber?: string;
    status?: IncomingInvoice.StatusEnum;
    exchangeRate?: number;
    externalDocumentFileName?: string;
    invoiceTypeId?: number;
    invoiceType?: InvoiceType;
    supplierId?: number;
    supplier?: Company;
    invoiceCommentId?: number;
    invoiceComment?: InvoiceComment;
    isDuplicate?: boolean;
    exportTimestamp?: Date;
    successfullyExported?: boolean;
    numberOfExportAttempts?: number;
    bookedTimestamp?: Date;
    paymentReference?: string;
    taxTotals?: Array<TaxTotal>;
    incomingInvoiceLines?: Array<IncomingInvoiceLine>;
    paymentMethods?: Array<PaymentMethod>;
    validatedOperationalFileCosts?: Array<ValidatedOperationalFileCost>;
    ocrSupplierData?: string;
}
export namespace IncomingInvoice {
    export type StatusEnum = 1 | 2 | 3 | 4 | 5;
    export const StatusEnum = {
        NUMBER_1: 1 as StatusEnum,
        NUMBER_2: 2 as StatusEnum,
        NUMBER_3: 3 as StatusEnum,
        NUMBER_4: 4 as StatusEnum,
        NUMBER_5: 5 as StatusEnum
    };
}