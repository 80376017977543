import { Inject, Injectable } from "@angular/core";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { StoredListViewProperties } from "../../models/utilities/stored-list-view-properties";
import { FilterItem } from "../../models/filter-item";
import { GridLayoutCacheService } from "./grid-layout-cache.service";
import { GridLayoutLookupDTO } from "../swagger-gen/fordesk";

@Injectable({ providedIn: "root" })
export class FilterCacheService<T> {

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService, private gridLayoutCacheService: GridLayoutCacheService) {
  }

  public storeCurrentlySelectedLayout(key: string, value: GridLayoutLookupDTO) {
    this.storage.set(key, value);
  }

  public getCurrentlySelectedLayout(key: string): GridLayoutLookupDTO {
    return this.storage.get(key);
  }

  public storeFilters(key: string, filterItems: FilterItem<T>[], notifyGridLayoutServiceForReset: boolean = true): void {
    const storedFilters: StoredListViewProperties<T> = this.getCurrentProperties(key);
    storedFilters.filters = filterItems;
    this.save(key, storedFilters, notifyGridLayoutServiceForReset);
  }

  public storePaging(key: string, pageSize: number, pageIndex: number, notifyGridLayoutServiceForReset: boolean = true): void {
    const storedFilters: StoredListViewProperties<T> = this.getCurrentProperties(key);
    storedFilters.currentPage = pageIndex;
    storedFilters.pageSize = pageSize;
    this.save(key, storedFilters, notifyGridLayoutServiceForReset);
  }

  public storeSorting(key: string, sortProperty: string, sortDirection: string, notifyGridLayoutServiceForReset: boolean = true): void {
    const storedFilters: StoredListViewProperties<T> = this.getCurrentProperties(key);
    storedFilters.sortDirection = sortDirection;
    storedFilters.sortProperty = sortProperty;
    this.save(key, storedFilters, notifyGridLayoutServiceForReset);
  }

  public get(key: string): StoredListViewProperties<T> {
    return this.storage.get(key);
  }

  public getFilter(key: string, description: string): any {
    const list = this.storage.get(key) as StoredListViewProperties<T>;
    if (!list || !list.filters) {
      return null;
    }

    return list.filters.find(x => x.description === description);
  }

  public remove(key: string, notifyGridLayoutServiceForReset: boolean = true): void {
    this.storage.remove(key);

    if (notifyGridLayoutServiceForReset) {
      this.gridLayoutCacheService.notifyGridChanged(key);
    }
  }

  public save(key: string, storedListViewProperties: StoredListViewProperties<T>, notifyGridLayoutServiceForReset: boolean = true): void {
    this.storage.set(key, storedListViewProperties);

    if (notifyGridLayoutServiceForReset) {
      this.gridLayoutCacheService.notifyGridChanged(key);
    }
  }

  private getCurrentProperties(key: string): StoredListViewProperties<T> {
    const storedFilters: StoredListViewProperties<T> = this.storage.get(key);
    return storedFilters ? storedFilters : {
      pageSize: 10,
      currentPage: 0,
      filters: null,
      sortProperty: "",
      sortDirection: "asc"
    };
  }
}
