export const incoming_invoices_displayed_columns_locale = {
  type: "Type",
  documentReference: "Doc. reference",
  fileReference: "Operational file nr.",
  invoiceNumber: "Invoice nr.",
  supplierName: "Supplier name",
  amountExclVat: "Amount excl. VAT",
  amountVat: "VAT amount",
  amountInclVat: "Amount incl. VAT",
  commentCode: "Comment code",
  status: "Status",
  accountingReference: "Accounting ref.",
  paymentReference: "Payment ref.",
  issueDate: "Issue date",
  dueDate: "Due date",
  creationTimestamp: "Creation date",
};
