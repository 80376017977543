export const of_list_locale = {
  TITLE: "Operational files",
  SELECT_CURRENT_PAGE: "Select all operational files on this page",
  SELECT_FILE: "Select file",
  FILENR: "File number",
  CUSTOMER: "Customer",
  CUSTOMER_REF: "Custom reference",
  FILE_TYPE: "File type",
  STATUS: "Status",
  STEPS: "Steps",
  STEPS_TEXT: "Completed steps / Total steps",
  NR_OF_CONTAINERS_SHORT: "Ctnrs.",
  GOODS_SHORT: "Goods",
  NR_OF_CONTAINERS_GOODS_TEXT: "Number of containers and goods",
  NR_OF_CONTAINERS: "# of containers",
  NR_OF_GOODS: "# of goods",
  CARRIER: "Carrier",
  VESSEL: "Vessel",
  POL: "Port of loading",
  POD: "Port of destination",
  ETA: "Estimated time of arrival",
  ETD: "Estimated time of departure",
  INCOTERMS: "Incoterms",
  CREATION_DATE: "Creation date",
  PLACE_OF_RECEIPT: "Place of Receipt",
  PLACE_OF_DESTINATION: "Place of Destination",
  AIRWAY_BILL_NR: "Airway bill number",
  FLIGHT_NR: "Flight number",
  FILE_DATE: "File date",
  SEARCH_OPERATIONAL_FILES: "Search operational files",
  FILE_NR: "Operational file nr.",
  CUSTOMER_NAME_SHORT: "Customer",
  CUSTOMER_REF_SHORT: "Ref.",
  FILETYPE: "File type",
  STEPS_NR_OF_CONTAINERS: "Steps / # of containers",
  CARRIER_SHORT: "Carrier",
  VESSEL_SHORT: "Vessel",
  AIRWAY_BILL_NR_SHORT: "Airway Bill Nr.",
  FLIGHT_NR_SHORT: "Flight nr.",
  PLACE_OF_RECEIPT_SHORT: "Pl.o.R",
  PLACE_OF_DESTINATION_SHORT: "Pl.o.D",
  PLACE_OF_RECEIPT_AND_DESTINATION_TEXT: "Place of Receipt / Place of Destination",
  BERTH_DATE: "Berth date",
  POL_SHORT: "P.o.L",
  POD_SHORT: "P.o.D",
  TERMINAL: "Terminal",
  POL_POD_TEXT: "Port of Loading / Port of Destination",
  ETD_SHORT: "ETD",
  ETA_SHORT: "ETA",
  ETD_ETA_TEXT: " Estimated Time of Departure / Estimated Time of Arrival",
  INCOTERMS_CREATIONDATE: "Incoterms / Creation date",
  STATUS_ACTIVE: "Active",
  STATUS_CANCELLED: "Cancelled",
  STATUS_CLOSED: "Closed",
  STATUS_BUDGET_CLOSED: "Closed budget",
  PLACEHOLDERS: {
    // Placeholders without underscores to match field names
    FILENUMBER: "File number",
    BOOKING: "Booking",
    BILLOFLADING: " Bill of lading",
    CARRIER: "Carrier",
    CONTAINERNUMBER: "Container number",
    CREATIONDATE: "Creation date",
    FILEDATE: "File date",
    CUSTOMER: "Customer",
    CURRENCY: "Currency",
    CUSTOMERREFERENCE: "Customer reference",
    FILETYPE: "File type",
    PORTOFDISCHARGE: "Port of discharge",
    PORTOFLOADING: "Port of loading",
    SHIPMENTTYPE: "Shipment type",
    STATUS: "Status",
    TRANSPORTTYPE: "Transport type",
    VESSEL: "Vessel",
    CREATIONDATERANGE: "Creation date",
    FILEDATERANGE: "File date",
    DOCUMENTDATE: "Document date",
    DEADLINEDATE: "Deadline date",
    VALIDDATE: "Valid date",
    ACCOUNTINGNUMBER: "Accounting number",
    INVOICECUSTOMER: "Invoice customer",
    PROSPECT: "Prospect",
    INTERNALSUBJECT: "Internal subject",
    HOUSEBLNR: "House Bl number",
    ISBUDGETCLOSED: "Budget status",
    FILENUMBERS: "Operational file number",
    OPERATIONALFILEDATES: "Operational file date",
    INVOICECUSTOMERS: "Invoice customer",
    INVOICECUSTOMERNUMBERS: "Invoice customer number",
    CUSTOMERS: "Customer",
    CUSTOMERNUMBERS: "Customer number",
    OPERATIONALFILETYPES: "File type",
    TRANSPORTTYPES: "Transport type",
    SHIPMENTTYPES: "Shipment type",
    PORTSOFLOADING: "Port of loading",
    PORTSOFDISCHARGE: "Port of discharge",
    STATUSES: "Status",
    INCOTERMS: "Incoterms",
    CREATEDBY: "Created by",
    PLACEOFDESTINATION: "Place of destination",
    PLACEOFLOADING: "Place of loading",
    PLACEOFRECEIPT: "Place of receipt",
    AIRWAYBILLNR: "Airway bill number",
    FLIGHTNR: "Flight number",
    STEPSCOMPLETION: "Steps completion"
  },
  ACTIONS: {
    CANCEL: "cancel file",
    COPY_WITH_DATE_TITLE: "Copy operational file(s)",
    COPY_WITH_DATE: "Choose an optional date for the copies.",
    EXTENDED_COPY_DESCRIPTION: "Please select an optional date and the sections you would like to copy to the new operational file(s)."
  },
  COPY_DIALOG: {
    INCLUDE_GENERAL: "Include general",
    INCLUDE_STEPS: "Include steps",
    INCLUDE_GOODS: "Include goods",
    INCLUDE_CONTAINERS: "Include containers",
    INCLUDE_PARTIES: "Include parties",
    INCLUDE_COSTS: "Include costs",
    INCLUDE_REVENUES: "Include revenues",
    INCLUDE_SHIPPING_INSTRUCTIONS: "Include shipping instructions",
    INCLUDE_BOOKINGS: "Include bookings",
    INCLUDE_TRANSPORTS: "Include transports"
  }
};
