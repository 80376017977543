/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PartyTypeLookupDTO { 
    id?: number;
    description?: string;
    masterDataStatus?: PartyTypeLookupDTO.MasterDataStatusEnum;
    code?: string;
    value?: PartyTypeLookupDTO.ValueEnum;
    isDeleted?: boolean;
    selectableCompanyTypes?: Array<PartyTypeLookupDTO.SelectableCompanyTypesEnum>;
    includeTerminals?: boolean;
}
export namespace PartyTypeLookupDTO {
    export type MasterDataStatusEnum = 0 | 1;
    export const MasterDataStatusEnum = {
        NUMBER_0: 0 as MasterDataStatusEnum,
        NUMBER_1: 1 as MasterDataStatusEnum
    };
    export type ValueEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25 | 26;
    export const ValueEnum = {
        NUMBER_0: 0 as ValueEnum,
        NUMBER_1: 1 as ValueEnum,
        NUMBER_2: 2 as ValueEnum,
        NUMBER_3: 3 as ValueEnum,
        NUMBER_4: 4 as ValueEnum,
        NUMBER_5: 5 as ValueEnum,
        NUMBER_6: 6 as ValueEnum,
        NUMBER_7: 7 as ValueEnum,
        NUMBER_8: 8 as ValueEnum,
        NUMBER_9: 9 as ValueEnum,
        NUMBER_10: 10 as ValueEnum,
        NUMBER_11: 11 as ValueEnum,
        NUMBER_12: 12 as ValueEnum,
        NUMBER_13: 13 as ValueEnum,
        NUMBER_14: 14 as ValueEnum,
        NUMBER_15: 15 as ValueEnum,
        NUMBER_16: 16 as ValueEnum,
        NUMBER_17: 17 as ValueEnum,
        NUMBER_18: 18 as ValueEnum,
        NUMBER_19: 19 as ValueEnum,
        NUMBER_20: 20 as ValueEnum,
        NUMBER_21: 21 as ValueEnum,
        NUMBER_22: 22 as ValueEnum,
        NUMBER_23: 23 as ValueEnum,
        NUMBER_24: 24 as ValueEnum,
        NUMBER_25: 25 as ValueEnum,
        NUMBER_26: 26 as ValueEnum
    };
    export type SelectableCompanyTypesEnum = '0' | '1' | '2' | '3';
    export const SelectableCompanyTypesEnum = {
        _0: '0' as SelectableCompanyTypesEnum,
        _1: '1' as SelectableCompanyTypesEnum,
        _2: '2' as SelectableCompanyTypesEnum,
        _3: '3' as SelectableCompanyTypesEnum
    };
}