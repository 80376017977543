import { LoadingOverlayService } from "../../../../../../../core/services/loading-overlay/loading-overlay.service";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { EntityDetailsComponent } from "../../../../../../../core/components/abstractions/entity-details.component";
import { ActivatedRoute } from "@angular/router";
import { forkJoin, from, Observable, of } from "rxjs";
import { NgbActiveModal, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { EditableOperationalFileService } from "../../../../../../../core/services/operational/editable-operational-file.service";
import { OperationalFileDocumentService } from "../../../../../../../core/services/operational/operational-file/operational-file-document.service";
import { distinctUntilChanged, map, pairwise, takeUntil } from "rxjs/operators";
import { FileExtensions } from "../../../../../../../core/enumerations/file-extentions.enum";
import { WordEditorComponent } from "../../../../../../partials/content/general/word-editor/word-editor.component";
import { NamedBlob } from "../../../../../../../core/models/files/named-blob";
import { OperationalFileDocumentsRefreshService } from "../../../../../../../core/services/operational/operational-file/operational-file-documents-refresh.service";
import { BillOfLadingType } from "../../../../../../../core/enumerations/bill-of-lading-type.enum";
import { EnumEx } from "../../../../../../../core/extensions/enum-extensions";
import { WordEditorButtonOptions } from "../../../../../../../core/models/dialogs/word-editor-button-options";
import { SearchObjectHelper } from "../../../../../../../core/helpers/search-object-helper";
import {
  DocumentLookup, DocumentTemplateService, FoundOperationalFileTransport, FoundShippingInstructionsDTO, GetAllResponseFoundOperationalFileContainerDTO, GetAllResponseOperationalFileGoodsDTO, LookupDTO, OperationalFileCompactDetailsDTO, OperationalFileContainerService, OperationalFileDocumentsService, OperationalFileGoodsDTO, OperationalFileGoodsService, OperationalFilePartyService, OperationalFileService, OperationalFileSummaryDTO, OperationalFileTransportsService, PagedResponseFoundOperationalFileTransport, PagedResponseFoundShippingInstructionsDTO, PartyLookupDTO, PreviewDocumentRequest, ShippingInstructionService,
  FoundOperationalFileTransportStop, OperationalFileTransportStopsService, LanguagesService, BookingsService, FoundBookingDTO, PagedResponseFoundBookingDTO, PartyAddressDTO, CompanyAddressesService, ContactPersonsService, CompanyRelatedEmailDTO, OutgoingInvoiceDocumentsService,
  OutgoingInvoicesService
} from "../../../../../../../core/services/swagger-gen/fordesk";
import { PreviewFileHelper } from "../../../../../../../core/services/operational/operational-file/preview-file-helper.service";
import { PdfViewerDialogComponent } from "../../../../../../partials/content/general/pdf-viewer-dialog/pdf-viewer-dialog.component";
import { PdfViewerButtonOptions } from "../../../../../../../core/models/dialogs/pdf-viewer-button-options";
import { UnsavedChangesService } from "../../../../../../../core/services/helpers/unsaved-changes.service";
import { PartyType } from "../../../../../../../core/enumerations/partytype.enum";
import { PartyAddressTypeEnum } from "../../../../../../../core/enumerations/party-address-type.enum";
import { DocumentEmailsAutocompleteSourceEnum } from "../../../../../../../core/enumerations/document-emails-autocomplete-source.enum";
import { IMailingSourceBehavior } from "../../../../../../../core/helpers/mailing/mailing-source-behavior";

@Component({
  selector: "document-new",
  templateUrl: "./document-new.component.html",
  styleUrls: ["./document-new.component.scss"]
})
export class DocumentNewComponent extends EntityDetailsComponent implements OnInit, AfterViewInit {

  @Input()
  public isOperationalFile: boolean = false;

  private OperationalCategory = "OP";
  private OutgoingInvoiceOverviewCategory = "OIO"

  private operationalFileId: number;
  public operationalFile: OperationalFileSummaryDTO;
  public documentTemplates: DocumentLookup[] = [];
  public languages: LookupDTO[] = [];
  public parties: PartyLookupDTO[] = [];
  public customsAgentParties: PartyLookupDTO[] = [];
  public allGoods: OperationalFileGoodsDTO[] = [];
  public goods: OperationalFileGoodsDTO[] = [];
  public containers: ContainerLookup[];
  public transports: FoundOperationalFileTransport[] = [];
  public shippingInstructions: FoundShippingInstructionsDTO[] = [];
  public bookings: FoundBookingDTO[] = [];
  public detailsForm: UntypedFormGroup;
  public selectedTemplate: DocumentLookup;
  public documentTemplatesLoading: boolean = false;
  public languagesLoading: boolean = false;
  public isContactPersonsLoading: boolean = false;
  public blNumbers: string[] = [];
  public operationalFileTransportStops: FoundOperationalFileTransportStop[] = [];
  public transportStops: FoundOperationalFileTransportStop[] = [];
  public customerAddresses: PartyAddressDTO[] = [];
  public contactPersons: PartyAddressDTO[] = [];

  public isLanguageSelectorEnabled: boolean = true;
  public isSaveEnabled: boolean = true;
  public isMailEnabled: boolean = null;
  public mailingSourceBehavior?: IMailingSourceBehavior;
  public isSaveUnpaidInvoiceEnabled: boolean = false;

  public allowSaveOfRelatedOutgoingInvoiceDocument: boolean = false;

  public getEmailsAutocompleteSuggestions?: (
    text: string
  ) => Observable<CompanyRelatedEmailDTO[]>;

  private documentRelatedOfIds: number[];

  public get addressType() {
    return PartyAddressTypeEnum;
  }

  constructor(
    private outgoingInvoiceDocumentsService: OutgoingInvoiceDocumentsService,
    public documentService: OperationalFileDocumentsService,
    public activatedRoute: ActivatedRoute,
    public dialogRef: NgbActiveModal,
    public searchObjectHelper: SearchObjectHelper,
    private fb: UntypedFormBuilder,
    private documentTemplateService: DocumentTemplateService,
    private operationalFileService: OperationalFileService,
    private partyService: OperationalFilePartyService,
    private modal: NgbModal,
    private overlayService: LoadingOverlayService,
    private operationalFileDocumentService: OperationalFileDocumentService,
    private operationalFileDocumentsService: OperationalFileDocumentsService,
    private containerService: OperationalFileContainerService,
    public editableService: EditableOperationalFileService,
    public operationalFileDocumentsRefreshService: OperationalFileDocumentsRefreshService,
    private shippingInstructionsService: ShippingInstructionService,
    private operationalFileTransportsService: OperationalFileTransportsService,
    private operationalFileGoodsService: OperationalFileGoodsService,
    public cdRef: ChangeDetectorRef,
    private filePreviewHelper: PreviewFileHelper,
    unsavedChangedService: UnsavedChangesService,
    private operationalFileTransportStopsService: OperationalFileTransportStopsService,
    private outgoingInvoicesService: OutgoingInvoicesService,

    private bookingService: BookingsService,
    private companyAddressService: CompanyAddressesService,
    private contactPersonService: ContactPersonsService,
    private languagesService: LanguagesService
  ) {
    super(activatedRoute, unsavedChangedService);
  }

  public ngOnInit() {
    this.createForm();

    this.editableService
      .isFileEditable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (res) => {
          if (!res) {
            this.isClosed = true;
            this.detailsForm.disable();
          } else {
            this.detailsForm.enable();
          }
        });
  }

  public ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  private createForm(): void {
    this.detailsForm = this.fb.group({
      documentTemplateId: [null, Validators.required],
      languageId: [null],
      operationalFilePartyId: [null, Validators.required],
      operationalFileCustomsAgentPartyId: [null],
      operationalFileGoodsIds: [[]],
      operationalfileContainerIds: [[]],
      bookingId: [],
      transportId: [],
      operationalFileShippingInstructionsId: [],
      billOfLadingNumbers: [[]],
      additionalTextValue: [''],
      transportStopId: [],
      customerAddressId: [null],
      contactPersonId: [null],
    });

    const handlers: ValueChangesHandler[] = [
      {
        fieldName: "customerAddressId",
        handle: (oldValue: any, newValue: any) => this.selectedCustomerAddressChanged(newValue)
      }
    ];

    handlers.forEach(handler => {
      this.detailsForm
        .get(handler.fieldName)
        .valueChanges
        .pipe(
          takeUntil(this.unsubscribe),
          distinctUntilChanged(),
          pairwise()
        )
        .subscribe(([oldValue, newValue]: [number, number]) => handler.handle(oldValue, newValue));
    });
  }

  private selectedCustomerAddressChanged(selectedCompanyAddress: PartyAddressDTO) {
    if (!this.selectedTemplate.hasContactPerson) {
      return;
    }

    const selectedCompanyAddressId = selectedCompanyAddress?.id;
    this.loadContactPersons(selectedCompanyAddressId);

    if (this.detailsForm.controls["contactPersonId"].value) {
      this.detailsForm.controls["contactPersonId"].reset();
    }
  }

  public loadOperationalFileTemplates(operationalFileId: number): void {
    this.operationalFileId = operationalFileId;
    this.loadAvailableOperationalFileTemplates();
    this.loadLanguages();
  }

  public loadOutgoingInvocesOverviewTemplates(): void {
    this.loadAvailableOutgoingInvocesOverviewTemplates();
    this.loadLanguages();
  }

  private loadAvailableOperationalFileTemplates() {
    this.handleTemplateLoading(() => this.documentTemplateService.getOperationalFileDocumentTemplates(this.operationalFileId, this.OperationalCategory));
  }

  private loadAvailableOutgoingInvocesOverviewTemplates() {
    this.handleTemplateLoading(() => this.documentTemplateService.getDocumentTemplatesByCategory(this.OutgoingInvoiceOverviewCategory));
  }

  private handleTemplateLoading(sourceFunc: () => Observable<Array<DocumentLookup>>) {
    this.documentTemplatesLoading = true;

    sourceFunc()
      .subscribe({
        next: res => {
          this.documentTemplates = res ? res : [];
        },
        error: () => this.documentTemplatesLoading = false,
        complete: () => this.documentTemplatesLoading = false
      });
  }

  private loadLanguages() {
    this.languagesLoading = true;

    this.languagesService.getMainLanguages()
      .subscribe({
        next: res => {
          this.languages = res?.results ?? [];
        },
        error: () => this.languagesLoading = false,
        complete: () => this.languagesLoading = false
      });
  }

  private loadLookupsForDocumentTemplate(
    withParties: boolean,
    withGoods: boolean,
    withTransports: boolean,
    withContainers: boolean,
    withShippingInstructions: boolean,
    withBillOfLadingNumbers: boolean,
    withTransportStops: boolean,
    withCustomsAgentParty: boolean,
    withBooking: boolean,
    withCustomerAddress: boolean
  ): void {
    this.loading = true;
    forkJoin({
      parties: withParties ? this.partyService.getFiltered(this.operationalFileId) : of(<PartyLookupDTO[]>null),
      goods: withGoods ? this.operationalFileGoodsService.getAllOperationalFileGoods(this.operationalFileId, null, true) as Observable<GetAllResponseOperationalFileGoodsDTO> : of(<GetAllResponseOperationalFileGoodsDTO>null),
      transports: withTransports ? this.operationalFileTransportsService.get(this.operationalFileId) : of(<PagedResponseFoundOperationalFileTransport>null),
      containers: withContainers ? this.containerService.getOperationalFileContainers(this.operationalFileId) : of(<GetAllResponseFoundOperationalFileContainerDTO>null),
      shippingInstructions: withShippingInstructions ? this.shippingInstructionsService.getShippingInstruction(this.operationalFileId) : of(<PagedResponseFoundShippingInstructionsDTO>null),
      blNumbers: withBillOfLadingNumbers ? this.operationalFileService.getCompactDetails(this.operationalFileId) : of(<OperationalFileCompactDetailsDTO>null),
      operationalFileTransportStops: withTransportStops ? this.operationalFileTransportStopsService.getOperationalFileTransportStopsByOperationalFile(this.operationalFileId, 0) : of(<Array<FoundOperationalFileTransportStop>>null),
      customsAgentParties: withCustomsAgentParty ? this.partyService.getFiltered(this.operationalFileId, [PartyType.CustomsAgent]) : of(<PartyLookupDTO[]>null),
      bookings: withBooking ? this.bookingService.getBookings(this.operationalFileId) : of(<PagedResponseFoundBookingDTO>null),
      customerAddresses: withCustomerAddress ? this.outgoingInvoicesService.getPartyAddressesUsedForInvoices() : of(<PartyAddressDTO[]>null),
    })
      .pipe(
        takeUntil(this.unsubscribe),
        map(data => {
          this.parties = data.parties ? data.parties : [];
          this.transports = data.transports && data.transports && data.transports.items ? data.transports.items : [];

          this.containers = data.containers && data.containers.results ? data.containers.results.map(x => {
            return {
              id: x.id,
              description: `${x.containerNumber ? x.containerNumber : ""} - ${x.sizeType}`,
              goodsIds: x.goodsIds,
            };
          }) : [];

          this.allGoods = data.goods && data.goods.results ? data.goods.results : [];
          this.goods = this.allGoods;

          this.shippingInstructions = data.shippingInstructions && data.shippingInstructions.items ? data.shippingInstructions.items : [];
          this.bookings = data.bookings && data.bookings.items ? data.bookings.items : [];
          this.blNumbers = data.blNumbers && data.blNumbers.billOfLadingNumbers ? data.blNumbers.billOfLadingNumbers : [];
          this.operationalFileTransportStops = data.operationalFileTransportStops ? data.operationalFileTransportStops : [];
          this.customsAgentParties = data.customsAgentParties ? data.customsAgentParties : [];
          this.customerAddresses = data.customerAddresses ? data.customerAddresses : [];
        }), takeUntil(this.unsubscribe)
      )
      .subscribe({
        error: () => {
          this.loading = false;
        },
        complete: () => {
          this.loading = false;
        }
      });
  }

  private loadContactPersons(companyAddress: any) {
    this.contactPersons = [];

    if (!companyAddress) {
      return;
    }

    if (this.customerAddresses?.length > 0) {
      const addressId = companyAddress?.id ?? companyAddress;
      const company = this.customerAddresses.map(c => c.companyAddress).find(x => x.id === addressId);

      if (!company) {
        return;
      }

      this.isContactPersonsLoading = true;
      this.contactPersonService.getAllByCompanyId(company.companyId)
        .pipe(
          takeUntil(this.unsubscribe),
          map((loadedContactPersons) => {
            this.contactPersons = loadedContactPersons.results ? loadedContactPersons.results : [];
          }))
        .subscribe({
          error: () => {
            this.isContactPersonsLoading = false;
          },
          complete: () => {
            this.isContactPersonsLoading = false;
          }
        });
    }
  }

  private getFileFormat(extension: FileExtensions): PreviewDocumentRequest.FileFormatEnum {
    switch (extension) {
      case FileExtensions.pdf:
        return PreviewDocumentRequest.FileFormatEnum.NUMBER_0;
      case FileExtensions.doc:
        return PreviewDocumentRequest.FileFormatEnum.NUMBER_1;
      case FileExtensions.docx:
        return PreviewDocumentRequest.FileFormatEnum.NUMBER_2;
    }
    return null;
  }

  public preview(): void {
    if (!this.validateAllFormFields(this.detailsForm)) {
      return;
    }
    this.overlayService.startLoading();
    const detailsFormValue = this.detailsForm.value;
    const previewDocument: PreviewDocumentRequest = {
      fileFormat: this.getFileFormat(FileExtensions.docx),
      operationalFilePartyId: detailsFormValue.operationalFilePartyId,
      operationalFileCustomsAgentPartyId: detailsFormValue.operationalFileCustomsAgentPartyId,
      operationalFileGoodsIds: detailsFormValue.operationalFileGoodsIds,
      operationalfileContainerIds: detailsFormValue.operationalfileContainerIds,
      bookingId: detailsFormValue.bookingId,
      transportId: detailsFormValue.transportId,
      operationalFileShippingInstructionsId: detailsFormValue.operationalFileShippingInstructionsId,
      documentTemplateId: detailsFormValue.documentTemplateId,
      billOfLadingNumbers: detailsFormValue.billOfLadingNumbers,
      additionalTextValue: detailsFormValue.additionalTextValue,
      transportStopId: detailsFormValue.transportStopId,
      languageId: detailsFormValue.languageId,
      customerAddressId: detailsFormValue.customerAddressId?.id,
      contactPersonId: detailsFormValue.contactPersonId,
    };

    const docLoadOperation = this.operationalFileId
      ? this.operationalFileDocumentsService.previewOperationalFileDocument(previewDocument, this.operationalFileId, { observe: "response" })
      : this.documentTemplateService.previewDocument(previewDocument, { observe: "response" });
    const previewOperation = this.filePreviewHelper.getPreviewDocument(docLoadOperation)
      .pipe(takeUntil(this.unsubscribe));

    if (this.allowSaveOfRelatedOutgoingInvoiceDocument) {
      this.outgoingInvoiceDocumentsService.getUnpaidInvoicesRelatedOfIds(previewDocument.customerAddressId)
        .pipe(takeUntil(this.unsubscribe)).subscribe((x: number[]) => this.documentRelatedOfIds = x);
    }

    previewOperation
      .pipe(
        map(loadedFile => {
          const extension = this.getFileExtension(loadedFile.blob.fileName);
          if (extension === FileExtensions.pdf) {
            this.loadReadOnly(of<NamedBlob>(loadedFile.blob));
          } else if (extension === FileExtensions.doc || extension === FileExtensions.docx) {
            this.loadEditable(loadedFile.blob, previewDocument.documentTemplateId);
          } else {
            // tslint:disable-next-line: no-use-before-declare
            throw new FileExtensionNotSupportedError();
          }
        }), takeUntil(this.unsubscribe)
      ).subscribe();
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  protected saveInternal() {
    this.preview();
  }

  private getFileExtension(fileName: string): string {
    if (!fileName) {
      return null;
    }

    const startIndex: number = fileName.lastIndexOf(".") + 1;
    if (fileName.length <= startIndex) {
      return fileName;
    }

    return fileName.substr(startIndex);
  }

  // Leaving this here for future reference
  private loadReadOnly(docLoadOperation: Observable<NamedBlob>): void {
    const modalRef = this.modal.open(PdfViewerDialogComponent, {
      backdrop: 'static',
      keyboard: false,
      size: <any>"xl"
    });
    const pdfViewerComponent: PdfViewerDialogComponent = (<PdfViewerDialogComponent>modalRef.componentInstance);

    if (this.operationalFileId) {
      pdfViewerComponent.getDocuments = () => this.documentService.getAllOperationalFileDocumentsWithSubdirectories(this.operationalFileId).pipe(takeUntil(this.unsubscribe));
    }

    pdfViewerComponent.saveDocument = (document: Blob, fileName: string) => this.operationalFileDocumentService.save(this.operationalFileId, fileName, document);
    pdfViewerComponent.getEmailsAutocompleteSuggestions =
      this.getEmailsAutocompleteSuggestionsSource();
    pdfViewerComponent.mailingSourceBehavior = this.mailingSourceBehavior;
    const buttonOptions = {
      isDownloadEnabled: true,
      isMailEnabled: this.isMailEnabled ?? true,
      isPrintEnabled: true
    } as PdfViewerButtonOptions;
    pdfViewerComponent.tryLoadPdf(docLoadOperation,
      buttonOptions,
      () => {
        this.dialogRef.close();
        this.overlayService.stopLoading();
      });
  }

  private loadEditable(file: NamedBlob, documentTemplateId: number): void {
    const modalRef: NgbModalRef = this.modal.open(WordEditorComponent, { size: <any>"xl", backdrop: "static", keyboard: false });
    const editor: WordEditorComponent = (<WordEditorComponent>modalRef.componentInstance);
    editor.isOperationalFile = this.isOperationalFile
    const wordEditorOptions: WordEditorButtonOptions = {
      isDownloadEnabled: true,
      isSaveEnabled: this.isSaveEnabled,
      isMailEnabled: this.isMailEnabled ?? false,
      isPrintEnabled: true,
      isSaveUnpaidInvoiceEnabled: this.isSaveUnpaidInvoiceEnabled
    };
    editor.documentRelatedOfIds = this.documentRelatedOfIds;
    editor.saveDocument = (document: Blob, path: string) => this.operationalFileDocumentService.save(this.operationalFileId, file.fileName, document, documentTemplateId, path);
    editor.getEmailsAutocompleteSuggestions = this.getEmailsAutocompleteSuggestionsSource();

    if (this.operationalFileId) {
      editor.getDocuments = () => this.documentService.getAllOperationalFileDocumentsWithSubdirectories(this.operationalFileId).pipe(takeUntil(this.unsubscribe));
      editor.uploadPdfFileAfterSendAction$ = (args) => {
        return this.operationalFileDocumentsService.uploadAfterEmail(this.operationalFileId, args.pdfFileName, args.pdfFile, args.emails, args.subject, args.message);
      };
    }

    editor.loadTemplatedDocument(file, documentTemplateId, wordEditorOptions)
      .subscribe();

    from(modalRef.result)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.operationalFileDocumentsRefreshService.refresh();
        this.modal.dismissAll();
      });
  }

  public selectedTransportChanged(): void {
    this.transportStops = this.operationalFileTransportStops.filter(i => i.operationalFileTransportId === this.detailsForm.controls["transportId"].value);
  }

  public selectedDocumentTemplateChanged(): void {
    if (this.detailsForm.controls["documentTemplateId"].value == null) {
      this.selectedTemplate = null;
      this.togleControls();
      return
    }
    this.selectedTemplate = this.documentTemplates.filter(i => i.id === this.detailsForm.controls["documentTemplateId"].value)[0];
    this.togleControls();

    this.detailsForm.updateValueAndValidity();
    this.loadLookupsForDocumentTemplate(
      this.selectedTemplate.hasParty,
      this.selectedTemplate.hasGoods,
      this.selectedTemplate.hasTransport,
      this.selectedTemplate.hasContainers,
      this.selectedTemplate.hasShippingInstructions,
      this.selectedTemplate.hasBillOfLadingNumbers,
      this.selectedTemplate.hasTransportStops,
      this.selectedTemplate.hasCustomsAgentParty,
      this.selectedTemplate.hasBooking,
      this.selectedTemplate.hasCustomerAddress,
    );
  }

  private togleControls() {
    if (this.selectedTemplate == null) {
      this.resetForm();
      this.toggleControl(false, "operationalFileGoodsIds");
      this.toggleControl(false, "OperationalFileCustomsAgentPartyId");
      this.toggleControl(false, "operationalFilePartyId");
      this.toggleControl(false, "bookingId");
      this.toggleControl(false, "transportId");
      this.toggleControl(false, "operationalfileContainerIds");
      this.toggleControl(false, "operationalFileShippingInstructionsId");
      this.toggleControl(false, "transportStopId");
      this.toggleControl(false, "customerAddressId");
      this.toggleControl(false, "contactPersonId");
    } else {
      this.toggleControl(this.selectedTemplate.hasGoods, "operationalFileGoodsIds", this.selectedTemplate.isGoodsRequired);
      this.toggleControl(this.selectedTemplate.hasCustomsAgentParty, "OperationalFileCustomsAgentPartyId", this.selectedTemplate.isCustomsAgentPartyRequired);
      this.toggleControl(this.selectedTemplate.hasParty, "operationalFilePartyId", this.selectedTemplate.isPartyRequired);
      this.toggleControl(this.selectedTemplate.hasBooking, "bookingId", this.selectedTemplate.isBookingRequired);
      this.toggleControl(this.selectedTemplate.hasTransport, "transportId", this.selectedTemplate.isTransportRequired);
      this.toggleControl(this.selectedTemplate.hasContainers, "operationalfileContainerIds", this.selectedTemplate.isContainersRequired);
      this.toggleControl(this.selectedTemplate.hasShippingInstructions, "operationalFileShippingInstructionsId", this.selectedTemplate.isShippingInstructionsRequired);
      this.toggleControl(this.selectedTemplate.hasTransportStops, "transportStopId", this.selectedTemplate.isTransportStopRequired);
      this.toggleControl(this.selectedTemplate.hasCustomerAddress, "customerAddressId", this.selectedTemplate.isCustomerAddressRequired);
      this.toggleControl(this.selectedTemplate.hasContactPerson, "contactPersonId", this.selectedTemplate.isContactPersonRequired);
    }
  }

  private toggleControl(enabled: boolean, controlName: string, required: boolean = true): void {
    const control: AbstractControl = this.detailsForm.controls[controlName];
    if (!control) {
      return;
    }

    if (enabled) {
      control.enable();
      if (required) {
        control.setValidators([Validators.required]);
      } else {
        control.clearValidators();
      }
    } else {
      control.clearValidators();
      control.disable();
    }
    control.updateValueAndValidity({ onlySelf: true });
  }

  public displayBillOfLading(billOfLadingType: BillOfLadingType): string {
    return billOfLadingType != null ? EnumEx.getNamesAndValues(BillOfLadingType, true).filter(e => e.value === billOfLadingType).map(e => e.name)[0] : null;
  }

  public selectAllContainers() {
    const selectedIds = this.containers.map(item => item.id);
    this.f.operationalfileContainerIds.patchValue(selectedIds);
  }

  public clearAllContainers() {
    this.f.operationalfileContainerIds.patchValue([]);
  }

  public selectAllGoods() {
    const selectedIds = this.goods.map(item => item.id);
    this.f.operationalFileGoodsIds.patchValue(selectedIds);
  }

  public clearAllGoods() {
    this.f.operationalFileGoodsIds.patchValue([]);
  }

  private getEmailsAutocompleteSuggestionsSource(): (
    text: string
  ) => Observable<CompanyRelatedEmailDTO[]> {
    if (
      this.selectedTemplate.emailsAutocompleteSource ==
      DocumentEmailsAutocompleteSourceEnum.CustomerAddress &&
      this.selectedTemplate.hasCustomerAddress
    ) {
      return (text: string) => {
        const customerAddressId =
          this.detailsForm?.value?.customerAddressId?.id;
        return customerAddressId && text
          ? this.companyAddressService.getEmailSuggestions(
            customerAddressId,
            text,
            5
          )
          : of([]);
      };
    }

    if (this.getEmailsAutocompleteSuggestions) {
      return (text: string) => {
        return this.getEmailsAutocompleteSuggestions(text);
      };
    }

    return null;
  }

  public handleContainerChange(containers: ContainerLookup[]) {
    if (!this.selectedTemplate.isGoodsDependOnContainers || !this.allGoods.length) {
      return;
    }

    const selectedContainerIds = containers.map(container => container.id);
    const notSelectedContainerGoodsIds = this.containers
      .filter(container => !selectedContainerIds.includes(container.id))
      .map(container => container.goodsIds || [])
      .reduce((acc, goodsIds) => acc.concat(goodsIds), []);

    if (containers.length) {
      // The goods selector should include only goods from selected containers and goods not assigned to any container.
      this.goods = this.allGoods
        .filter(good => !notSelectedContainerGoodsIds.includes(good.id));
    } else {
      this.goods = this.allGoods;
    }

    const selectedContainerGoodsIds = Array.from(new Set(containers
      .map(container => container.goodsIds || [])
      .reduce((acc, goodsIds) => acc.concat(goodsIds), [])));

    const currentSelectedContainerGoodsIds = this.detailsForm.controls.operationalFileGoodsIds.value;
    const selectedNonContainerGoodsIds = currentSelectedContainerGoodsIds
      .filter(goodsId => !selectedContainerGoodsIds.includes(goodsId) && !notSelectedContainerGoodsIds.includes(goodsId))

    // Select goods from selected containers and keep any selected goods that not assigned to containers.
    this.detailsForm.controls.operationalFileGoodsIds.setValue(selectedContainerGoodsIds.concat(selectedNonContainerGoodsIds));
  }
}

export class FileExtensionNotSupportedError extends Error { }

class ValueChangesHandler {
  public fieldName: string;
  public handle: Function;
}

interface ContainerLookup extends LookupDTO {
  goodsIds: Array<number>;
}