import { of_customs_locale } from "./en/operational/of-customs.en";
import { of_party_locale } from "./en/operational/of-parties.en";
import { of_general_locale } from "./en/operational/of-general.en";
import { of_goods_locale } from "./en/operational/of-goods.en";
import { of_costs_locale } from "./en/operational/of-costs.en";
import { of_containers_locale } from "./en/operational/of-containers.en";
import { of_budget_locale } from "./en/operational/of-budget.en";
import { of_documents_locale } from "./en/operational/of-documents.en";
import { of_transports_locale } from "./en/operational/of-transports.en";
import { enums_locale } from "./en/general/enums.en";
import { of_revenues_locale } from "./en/operational/of-revenues.en";
import { of_shipping_locale } from "./en/operational/of-shipping.en";
import { of_booking_locale } from "./en/operational/of-booking.en";
import { of_steps_locale } from "./en/operational/of-steps.en";
import { of_list_locale } from "./en/operational/of-list.en";
import { outgoing_invoices_list_locale } from "./en/finance/outgoing-invoice-list.en";
import { outgoing_invoices_new_locale } from "./en/finance/outgoing-invoice-new.en";
import { of_edit_locale } from "./en/operational/of-edit.en";
import { masterdata_locale } from "./en/masterdata/masterdata.en";
import { incoming_invoices_list_locale } from "./en/finance/incoming-invoice-list.en";
import { login_locale } from "./en/login.en";
import { dashboards_locale } from "./en/dashboards/dashboards.en";
import { documents_locale } from "./en/documents/doc.en";
import { incoming_invoices_details_locale } from "./en/finance/incoming-invoice-details.en";
import { invoices_lines_locale } from "./en/finance/invoice-line.en";
import { reporting_list_locale } from "./en/reporting/reporting.en";
import { mail_component_locale } from "./en/components/mail.en";
import { validation_locale } from "./en/validation.en";
import { export_finance_data_locale } from "./en/finance/export-finance-data.en";
import { yearly_budget_overview_locale } from "./en/reporting/yearly-budget-overview.en";
import { list_quotes_locale, new_quotes_locale, quote_revenues_locale } from "./en/quotes/quotes.en";
import { quote_goods_locale } from "./en/quotes/goods.en";
import { quote_containers_locale } from "./en/quotes/quote-containers.en";
import { budget_details_overview_locale } from "./en/reporting/budget-details-overview.en";
import { quote_costs_locale } from "./en/quotes/quote-costs.en";
import { quote_budget_locale } from "./en/quotes/quote-budget.en";
import { quote_parties_locale } from "./en/quotes/quote-parties.en";
import { digital_archive_component_locale } from "./en/components/digital-archive.en";
import { of_courier_instructions_locale } from "./en/operational/of-courier-instructions.en";
import { destination_card_locale } from "./en/components/destination-card.en";
import { quote_budget_details_overview_locale } from "./en/reporting/quote-budget-details-overview.en";
import { operational_file_overview_locale } from "./en/reporting/operational-file-overview.en";
import { budget_per_activity_code_overview_locale } from "./en/reporting/budget-per-activity-code.en";
import { file_upload_fail_component_locale } from "./en/components/file-upload-fail-component.en";
import { notifications_list_locale } from "./en/notifications/notifications-list.en";
import { prospects_list_locale } from "./en/sales/prospects/prospects-list.en";
import { prospects_details_locale } from "./en/sales/prospects/prospects.en";
import { incoming_invoices_displayed_columns_locale } from "./en/finance/incoming-invoice-displayed-columns.en";
import { of_displayed_columns } from "./en/operational/of-displayed-columns.en";
import { quotes_displayed_columns } from "./en/quotes/quotes-displayed-columns.en";
import { outgoing_invoices_displayed_columns_locale } from "./en/finance/outgoing-invoice-displayed-columns.en";
import { quote_costs_displayed_columns_locale } from "./en/quotes/quote_costs_displayed_columns_locale.en";
import { quote_revenues_displayed_columns_locale } from "./en/quotes/quote_revenues_displayed_columns_locale.en";
import { of_costs_displayed_columns_locale } from "./en/operational/of-costs-displayed-columns.en";
import { incoming_invoices_new_locale } from "./en/finance/incoming_invoices_new.en";
import { of_revenues_displayed_columns_locale } from "./en/operational/of-revenues-displayed-columns.en";
import { of_goods_displayed_columns_locale } from "./en/operational/of_goods_displayed_columns_locale";
import { of_containers_displayed_columns_locale } from "./en/operational/of_containers_displayed_columns_locale";
import { of_parties_displayed_columns_locale } from "./en/operational/of_parties_displayed_columns_locale";
import { of_customs_displayed_columns_locale } from "./en/operational/of_customs_displayed_columns_locale";
import { of_transports_displayed_columns_locale } from "./en/operational/of_transports_displayed_columns_locale";
import { of_shipping_instructions_displayed_columns_locale } from "./en/operational/of_shipping_instructions_displayed_columns_locale";
import { of_transport_stops_displayed_columns_locale } from "./en/operational/of_transport_stops_displayed_columns_locale";
import { of_courier_instructions_displayed_columns_locale } from "./en/operational/of_courier_instructions_displayed_columns_locale";
import { prospects_displayed_columns_locale } from "./en/sales/prospects/prospects_displayed_columns_locale";
import { company_displayed_columns_locale } from "./en/masterdata/companies/company_displayed_columns_locale";
import { company_list_locale } from "./en/masterdata/companies/company_list_locale";
import { quotes_containers_displayed_columns_locale } from "./en/quotes/quotes_containers_displayed_columns_locale";
import { quotes_goods_displayed_columns_locale } from "./en/quotes/quotes_goods_displayed_columns_locale";
import { quotes_parties_displayed_columns_locale } from "./en/quotes/quotes_parties_displayed_columns_locale";
import { of_notes_locale } from "./en/operational/of_notes.en";
import { extra_booking_confirm } from "./en/modals/extra-booking.modal.en";
import { grid_layouts_locale } from "./en/components/grid-layouts.en";
import { synchronization } from "./en/config/synchronization.en";
import { of_booking_displayed_columns_locale } from "./en/operational/of_booking_displayed_columns_locale";
import { import_export_finance_locale } from "./en/finance/import-export-finance.en";
import { import_finance_data_locale } from "./en/finance/import-finance-data.en";
import { incoming_invoices_export_locale } from "./en/finance/incoming-invoice-export.en";
import { integrations } from "./en/config/integrations.en";
import { users } from "./en/config/users.en";

/* tslint:disable */
// USA
export const locale = {
  lang: "en",
  data: {
    ENUMS: enums_locale,
    UNKNOWN: "Unknown",
    LOADING_DATA: "Loading...",
    GOODS: "Goods",
    CONTAINER_NUMBER: "Container number",
    CONTAINER_NUMBERS: "Container numbers",
    CONTAINERS: "Containers",
    KG_UNIT: "kg",
    METERS_UNIT: "m",
    DEGREES_CELCIUS: "°C",
    CUBIC_METERS_UNIT: "m³",
    IS_DELETED: "Is deleted",
    IS_BLOCKED: "Is blocked",
    TYPE: "Type",
    SIZE: "Size",
    TILL: "till",
    TO: "to",
    DESCRIPTION: "Description",
    ORDER: "Order",
    NUMBER: "Nr.",
    PERCENTAGE: "%",
    DATE_FORMAT: "DD-MM-YYYY",
    MORE_ELLIPSIS: "more ...",
    SUGGESTED: "Suggested",
    EUR: "EUR",
    USD: "USD",
    STATUS: "Status",
    DETAILS: "Details",
    // TODO: move to ACTIONS
    TYPEAHEAD_3_MIN: "Enter min 3 characters",
    TYPE_TO_SEARCH: "Type to search",
    NO_ITEMS_FOUND: "No items found",
    TYPEAHEAD_MAKE_CHOICE: "Please make a choice",
    TRANSLATOR: {
      SELECT: "Select your language"
    },
    NO_NUMBER: "<no number>",
    NOT_APPLICABLE: "n/a",
    ACTIONS: {
      INVITE_USER: "Invite user",
      BACK_TO_LIST: "back to list",
      OPEN_UPDATED_VERSION: "open updated version",
      SAVE_TO_CP: "save to the client portal folder",
      SAVE_ACTIONS: "save",
      ADD_TO_TENANT: "Add to tenant",
      DEACTIVATE: "Deactivate",
      REACTIVATE: "Reactivate",
      MORE_ACTIONS: "More actions",
      ACCEPT: "accept",
      OVERWRITE: "overwrite",
      RENAME: "rename",
      CHANGE_STATUS: "status",
      EDIT: "edit",
      DELETE: "delete",
      SAVE: "save",
      SAVE_AS: "save as",
      SAVE_AS_DOCX: "save as docx",
      SAVE_AS_PDF: "save as pdf",
      CANCEL: "cancel",
      REFRESH: "refresh",
      ADD: "add",
      KEEP: "Keep",
      USE: "Use",
      ADD_ALL: "add all",
      NEXT: "next",
      COPY: "copy",
      UPDATE: "update",
      VIEW_DETAILS: "view details",
      EDIT_DETAILS: "edit details",
      SEND: "send",
      UPLOAD: "upload",
      DOWNLOAD: "download",
      IMPORT: "import",
      SKIP: "skip",
      FINISH: "finish",
      PREVIOUS: "previous",
      IMPORT_FROM_CSV: "import from CSV",
      IMPORT_FROM_FILE: "import from file",
      SEARCH: "search",
      OK: "OK",
      PROCEED: "proceed",
      YES: "yes",
      CAPITAL_YES: "Yes",
      CAPITAL_NO: "No",
      EXCEL_EXPORT: "export grid to excel",
      NO: "no",
      COMPLETE: "complete",
      BLOCK: "block",
      REMOVE: "remove",
      GO_TO_OVERVIEW: "go to overview",
      GO_TO_DETAILS_PAGE: "go to details page",
      OCR_UPLOAD: "OCR upload",
      PREVIEW: "preview",
      VIEW_OPERATIONAL_FILE: "view operational file",
      SELECT_OPTION: "Please select an option",
      REMOVE_FILTER: "remove filter",
      VALIDATE: "validate",
      BOOK: "book",
      UNBOOK: "unbook",
      VIEW_DOCUMENT: "view document",
      VIEW_DOCUMENT_IN_SIDEBAR: "view document in sidebar",
      VIEW_DOCUMENT_IN_NEW_WINDOW: "view document in new window",
      NO_DOCUMENT: "no document uploaded",
      RESET: "reset",
      SAVE_UNPAID_INVOICE_DOCUMENT: "save unpaid invoice document",
      PROTEST: "protest",
      UNPROTEST: "unprotest",
      ADD_NEW_VIEW: "Add new view",
      MANAGE_VIEWS: "Manage views",
      OPERATIONAL_FILES: {
        ADD_HINT: "Add a new operational file",
        COPY_HINT: "Make copies of the selected operational files",
        CANCEL_HINT: "Cancels the selected operational files",
        SEARCH_HINT: "Search operational files with the selected filters",
        CREATE_QUOTE: "Create quote",
        SHOW_ONLY_MY_FILES: "Show only my files",
        SHOW_ONLY_DEADLINE_EXPIRED_FILES: "Show only files with overdue steps",
      },
      QUOTES: {
        SEARCH_HINT: "Search quote files with the selected filters",
        SHOW_ONLY_MY_FILES: "Show only my files",
      },
      CLEAR_ALL_HINT: "Clear all the selected filters",
      GOODS: {
        DISPLAY_IN_CREATION_ORDER: "Display in creation order",
      },
      UNCOMPLETE: "uncomplete",
      UNBLOCK: "unblock",
      SPLIT: "split",
      PRINT: "print",
      MAIL: "mail",
      SEND_EMAIL: "send e-mail",
      EXPORT: "export",
      ACTIONS: "actions",
      NAVIGATE: "Navigate",
      CLOSE: "close",
      SAVE_AND_ADD: "save and add",
      APPLY: "apply",
      COPY_TO_CLIPBOARD: "copy to clipboard",
      SELECT_ALL: "select all",
      CLEAR_ALL: "clear all",
      REJECT: "reject",
      APPROVE: "approve",
      SENT: "sent",
      RECALCULATE: "recalculate",
      INSERT: "insert",
      CONFIGURE: "configure",
      DOWNLOAD_TEMPLATE: "Download the template to get started",
      FILTER: "filter",
      SAVE_CHANGES: "Save changes and continue",
      DISCARD_CHANGES: "Discard changes and continue",
      REMAIN_ON_PAGE: "Remain on page",
      REMAIN_AND_FIX_ERRORS: "Remain on page (and fix errors)",
      SYNCHRONIZE: "synchronize",
      MARK_AS_PAID: "mark as paid",
      MARK_AS_UNPAID: "mark as unpaid",
      CREATE_DOCUMENT: "create document"
    },
    MESSAGES: {
      DATA_CALCULATED_IN_BACKGROUND: "The data you requested is being calculated and will be generated in the background.You do not need to remain on this page: you will receive a notification when your data is ready.",
      PARTY_IS_USED_BY_STEPS: "The party {{name}} is used by one or more steps. Do you want to delete these steps?",
      THIS_PARTY_IS_USED_BY_STEPS: "This party is used by one or more steps. Do you want to delete these steps?",
      ONE_OR_MORE_STEPS_LINKED: "One or more steps are linked to the \"{{type}}\" party type. You are adding a new party of this type. Do you want to duplicate these steps and bind them to this new party?",
      SELECT_PAGINATION: "Please select the number of items to export",
      SELECT_BOOKING_TEMPLATE: "Please select the booking template",
      LOADING: "Loading",
      SUCCESS: "Success",
      WARNING: "Attention",
      ERROR: "Error",
      INFO: "Information",
      COPIED_TO_CLIPBOARD: "Copied '{{ value }}' to clipboard.",
      NO_SEARCH_RESULTS: "No results found.",
      CHANGES_SAVED: "Your changes have been successfully saved.",
      SUCCESSFULLY_UPLOADED: "Your document has been successfully uploaded.",
      SUCCESSFULLY_IMPORTED: "Your file has been successfully imported.",
      SUCCESSFULLY_SYNCED: "Your file has been successfully imported. Currently the system is still synchronizing in the background. A notification will be sent when all tasks have been completed.",
      SUCCESSFULLY_DELETED: "Your document has been successfully deleted.",
      GENERAL_ERROR: "Something went wrong, please try again.",
      CONFIRM_TO_CONTINUE: "Please confirm to continue.",
      PICK_ONE_OF_OPTIONS: "Please pick one of the following options.",
      CONFIRM_CLOSE_OPEN_BUDGET: "This operational file still has open budget. Would you like to close it anyway?",
      OPTIONALLY_PROVIDE_REASON: "Optionally, you can provide a reason:",
      NEW_ITEM_WITH_NAME_ADDED: "'{{ name }}' has been added successfully!",
      PLEASE_SAVE_CHANGES: "Please save your changes before continuing.",
      NO_CHANGES_DETECTED: "No changes detected.",
      UNSAVED_CHANGES: "You have unsaved changes. Are you sure you want to leave?",
      UNSAVED_CHANGES_ON_CLOSE_OF: "You have unsaved changes. Are you sure you want to reset your form?",
      UNSAVED_CHANGES_ERRORS: "Cannot save changes because there are validation errors on the current page. Please fix those before saving.",
      INTERNET_DOWN: "Connection lost. Please check your internet connection",
      // confirm delete
      CONFIRM_DELETE_SINGULAR: "Are you sure you want to delete this item?",
      DELETE_CONFIRMED_SINGULAR: "Item was successfully deleted!",
      CONFIRM_DELETE_PLURAL: "Are you sure you want to delete these {{numberItems}} items?",
      DELETE_CONFIRMED_PLURAL: "{{numberItems}} items were successfully deleted!",
      // confirm cancel
      CONFIRM_CANCEL_SINGULAR: "Are you sure you want to cancel this item?",
      CANCEL_CONFIRMED_SINGULAR: "Item was successfully cancelled!",
      CONFIRM_CANCEL_PLURAL: "Are you sure you want to cancel these {{numberItems}} items?",
      CANCEL_CONFIRMED_PLURAL: "{{numberItems}} items were successfully cancelled!",
      //confirm update
      CONFIRM_UPDATE_SINGULAR: "Are you sure you want to update this item?",
      UPDATE_CONFIRMED_SINGULAR: "Item was successfully updated!",
      CONFIRM_UPDATE_PLURAL: "Are you sure you want to update these {{numberItems}} items?",
      UPDATE_CONFIRMED_PLURAL: "{{numberItems}} items were successfully updated!",
      //confirm copy
      CONFIRM_COPY_SINGULAR: "Are you sure you want to copy this item?",
      COPY_CONFIRMED_SINGULAR: "Item was successfully copied!",
      CONFIRM_COPY_PLURAL: "Are you sure you want to copy these {{numberItems}} items?",
      COPY_CONFIRMED_PLURAL: "{{numberItems}} items were successfully copied!",
      //confirm recalculate
      CONFIRM_RECALCULATE: "Would you like to recalculate the budget lines?",
      CONFIRM_RECALCULATE_SINGULAR: "Are you sure you want to recalculate this item?",
      RECALCULATE_CONFIRMED_SINGULAR: "Item was successfully recalculated!",
      CONFIRM_RECALCULATE_PLURAL: "Are you sure you want to recalculate these {{numberItems}} items?",
      RECALCULATE_CONFIRMED_PLURAL: "{{numberItems}} items were successfully recalculated!",
      CORRECT_SURE: "Are you sure this is correct?",
      GENERIC_QUERY_ERROR: "An error occurred while retrieving data.",
      TRY_AGAIN: "Please try again.",
      INVALID_OR_MISSING_DATA: "Your request contained invalid or missing data.",
      CORRECT_AND_RETRY: "Please correct any errors and try again.",
      BAD_REQUEST: "Bad request",
      NOT_FOUND: "Not found",
      UPLOADED_DOCUMENT_NOT_RECOGNISED: "The uploaded document could not be recognised, please try uploading a more legible one",
      INCOMING_INVOICE_HAS_EXCHANGE_RATE: "This incoming invoice already has an exchange rate applied.",
      CONFIRM_VALIDATE_COMPANY: "Would you like to validate this company?",
    },
    ERRORS: {
      ID_NOT_FOUND: "Could not find id {{ id }}!",
      NO_REPORT_DATA: "There is no data for the report.",
      FIELD_CONTAIN_ONLY_WHITESPACES: "The field cannot contain only whitespace.",
      SUPPLIER_NOT_FOUND: "Could not find the supplier",
      MIN_VALUE_MORE_THAN_MAX: "Min value cannot be more than max",
      MAX_VALUE_LESS_THAN_MIN: "Max value cannot be less than min",
      INVALID_TRANSPORT_STOP_DATE: "The date you entered is not possible in combination with the other pickup or delivery dates",
      NO_SELECTED_FILTERS: "Please select any filter first."
    },
    VALIDATION: validation_locale,
    MENU: {
      NEW: "New",
      ACTIONS: "Actions",
      REPORTS: "Reports",
      APPS: "Apps",
      DASHBOARDS: "Dashboards",
      MASTERDATA: "Masterdata",
      SEARCH: "Search",
      FINANCE: {
        NEW_OUTGOING_INVOICE: "Create outgoing invoice",
        NEW_INCOMING_INVOICE: "Create incoming invoice",
        OUTGOING_INVOICES: "Outgoing invoices",
        INCOMING_INVOICES: "Incoming invoices",
        EXPORT_DATA: "Export data",
        IMPORT_EXPORT: "Import/Export",
      },
      REPORTING: {
        OPERATIONAL_SUMMARY_PER_USER: "Operational summary per user",
        OPEN_BUDGET_PER_OPERATIONAL_FILE: "Open budget per operational file",
        BUDGET_DETAILS_PER_OPERATIONAL_FILE: "Budget details per operational file",
        BUDGET_DETAILS_PER_QUOTE: "Budget details per quote",
        YEARLY_BUDGET_OVERVIEW: "Yearly budget overview",
        OUTGOING_INVOICES_PER_OPERATIONAL_FILE: "Outgoing invoices per operational file",
        OPERATIONAL_FILES_OVERVIEW: "Operational files overview",
        BUDGET_PER_ACTIVITY_CODE: "Budget per activity code"
      },
      SALES: {
        SALES: "Sales",
        QUOTES: "Quotes",
        NEW_QUOTE_FOR_CUSTOMER: "New quote for a customer",
        NEW_QUOTE_FOR_PROSPECT: "New quote for a prospect",
        PROSPECTS: "Prospects",
        NEW_PROSPECT: "New prospect"
      }
    },
    AUTH: {
      GENERAL: {
        OR: "Or",
        SUBMIT_BUTTON: "Submit",
        NO_ACCOUNT: "Don't have an account?",
        SIGNUP_BUTTON: "Signup",
        FORGOT_PASSWORD_BUTTON: "forgot password?",
        BACK_BUTTON: "Back",
        PRIVACY: "Privacy",
        LEGAL: "Legal",
        CONTACT: "Contact",
        EMAIL: "Email",
        TAGLINE: "Diflexmo Forwarding",
        TAGLINE_SUBTITLE: "Making forwarding easy"
      },
      LOGIN: login_locale,
      FORGOT: {
        TITLE: "Forgotten password?",
        DESC: "Enter your email to reset your password"
      },
      REGISTER: {
        TITLE: "Sign Up",
        DESC: "Enter your details to create your account",
        SUCCESS: "Your account has been successfuly registered. Please use your registered account to login."
      },
      INPUT: {
        EMAIL: "Email",
        FULLNAME: "Fullname",
        PASSWORD: "Password",
        CONFIRM_PASSWORD: "Confirm Password"
      },
      ERROR: {
        UNSPECIFIED: "An error occurred while attempting to log in. Please try again.",
        NOT_AUTHORIZED: "Your session has expired. Please login again. You will be redirected shortly."
      }
    },
    OPERATIONAL: {
      SEND: "Send",
      NAME: "Name",
      DESCRIPTION: "Description",
      QUANTITY: "Quantity",
      QTY: "Qty.",
      PRICE: "Price",
      TOTAL: "Total",
      INVOICING: "Invoicing",
      NEW_FILE: "New operational file",
      STATUS: "Status",
      BUDGET_CLOSED: "Budget has been closed.",
      BUDGET_OPENED: "Budget has been opened.",
      POSITION: "Position",
      DISPLAYED_COLUMNS: of_displayed_columns,
      HEADER: {
        CURRENCY: "Currency",
        CUSTOMER: "Customer",
        INVOICE_CUSTOMER: "Invoiced customer",
        TYPE_COMBO: "Type",
        NR_OF_CONTAINERS: "Containers",
        CONTAINER_NUMBERS: "Container numbers",
        NR_OF_GOODS: "Goods",
        CUSTOMER_REF: "Customer ref",
      },
      LIST: of_list_locale,
      NEW: {
        CUSTOMER: "Customer",
        INVOICE_CUSTOMER_OWNS_GOODS: "Invoice customer owns goods",
        INVOICE_COMPANY: "Invoice company",
        CUSTOMER_REF: "Customer reference",
        DEPARTMENT: "Department",
        CURRENCY: "Currency",
        CARRIER: "Carrier",
        TRANSPORT_COMPANY: "Transport company",
        CUSTOMS: "Customs",
        FILE_TYPE: "File type",
        TRANSPORT_TYPE: "Transport type",
        SHIPMENT_TYPE: "Shipment type",
        ADD_STEP: "Add step",
        STEPS: "Steps",
        EDIT_STEP: "Edit this step",
        REMOVE_STEP: "Remove this step",
        NEW_STEP: "New step",
        NEW_STEP_ADDED: "A new step was added",
        STEP_DELETED: "A step was removed",
        FILE_DATE: "File date",
        WAREHOUSING_NEEDED: "Requires warehousing",
        MESSAGES: {
          NO_STEPS_FOUND: "No steps have been found for a combination of {{ fileType }}, {{ transportType }} and {{ shipmentType }}."
        }
      },
      EDIT: of_edit_locale,
      STEPS_LIST: {
        TITLE: "Steps",
        ADD_STEP: "add step",
        DEADLINE: "Deadline",
        DAYS: "Days",
        HOURS: "Hours",
        PREVIOUS_STEP: "Previous step",
      },
      NOTES_LIST: {
        NOTES: "notes",
        TITLE: "Notes",
        NO_NOTES_FOUND: "No notes have been found.",
      },
      GENERAL: of_general_locale,
      GOODS: of_goods_locale,
      GOODS_DISPLAYED_COLUMNS: of_goods_displayed_columns_locale,
      CUSTOMS: of_customs_locale,
      CUSTOMS_DISPLAYED_COLUMNS: of_customs_displayed_columns_locale,
      PARTY: of_party_locale,
      PARTIES_DISPLAYED_COLUMNS: of_parties_displayed_columns_locale,
      BUDGET: of_budget_locale,
      COST: of_costs_locale,
      COST_DISPLAYED_COLUMNS: of_costs_displayed_columns_locale,
      REVENUES: of_revenues_locale,
      REVENUES_DISPLAYED_COLUMNS: of_revenues_displayed_columns_locale,
      CONTAINERS: of_containers_locale,
      CONTAINERS_DISPLAYED_COLUMNS: of_containers_displayed_columns_locale,
      CONTAINERGOODS: {
        IMPORT_TITLE: "Import goods",
        TOTAL_NR_OF_COLLI: "Total nr of colli",
        PACKAGE_TYPE: "Package type",
        WEIGHT: "Weight",
        GOODS: "Goods",
        ADD_NEW_CONTAINER_GOOD: "Add good to container",
        UPDATE_CONTAINER_GOOD: "Update good in container",
        ADD_NEW_CONTAINER_GOOD_IN_CONTAINER: "Add good to container '{{containerNumber}}'",
        UPDATE_CONTAINER_GOOD_IN_CONTAINER: "Update good in container '{{containerNumber}}'",
        NO_AVAILABLE_GOODS_LEFT: "No available goods left.",
        CONTAINER_NUMBER_EMPTY: "The container nr. is empty.",
        MAX_WEIGHT: "There is only {{maxWeight}}kg assignable weight left.",
        MIN_WEIGHT: "The weight cannot be less than 0kg.",
        MIN_COLLIS: "The collis cannot be less than 1.",
        MAX_COLLIS: "The collis cannot be more than {{maxCollis}}.",
        VOLUME: "Volume",
        MIN_VOLUME: "The volume cannot be less than 0.",
        MAX_VOLUME: "The volume cannot be greater than {{maxVolume}}m³.",
        GROSS_WEIGHT: "Gross weight",
        ERRORS: {
          GOOD_HAS_NO_AVAILABLE_COLLIS: "The selected good has no available collis."
        }
      },
      GOODSCONTAINERS: {
        ADD_NEW_CONTAINER_GOOD: "Add good to container",
        ADD_NEW_CONTAINER_GOOD_IN_CONTAINER: "Add good to container",
        UPDATE_CONTAINER_GOOD: "Update good in container",
        UPDATE_CONTAINER_GOOD_IN_CONTAINER: "Update good in container",
        CONTAINERS: "Containers",
        TOTAL_NR_OF_COLLI: "Total nr of colli",
        MIN_COLLIS: "The collis cannot be less than 1.",
        MAX_COLLIS: "The collis cannot be more than {{maxCollis}}.",
        WEIGHT: "Weight",
        MIN_WEIGHT: "The weight cannot be less than 0kg.",
        MAX_WEIGHT: "There is only {{maxWeight}}kg assignable weight left.",
        VOLUME: "Volume",
        MIN_VOLUME: "The volume cannot be less than 0.",
        MAX_VOLUME: "The volume cannot be greater than {{maxVolume}}m³.",
        ERRORS: {
          GOOD_HAS_NO_AVAILABLE_COLLIS: "The good has no available collis left.",
          SAVE_NEW_GOODS_TO_LINK_TO_CONTAINER: "The newly added good should be saved before it is added to the container.",
          SAVE_GOODS_TO_LINK_TO_CONTAINER: "The good changes should be saved before it is added to the container.",
        },
        DIALOG: {
          GOODS_ADDED_TO_THE_CONTAINER: "Goods added to the container.",
          GOODS_UPDATED_IN_THE_CONTAINER: "Goods updated in the container.",
          GOODS_REMOVED_FROM_THE_CONTAINER: "Goods removed from the container.",
          RECALCULATE_CONTAINER: "Do you want to recalculate the weight and volume of the related container?",
          RECALCULATE_CONTAINER_WEIGHT: "Do you want to recalculate the weight of the related container?",
          RECALCULATE_CONTAINER_VOLUME: "Do you want to recalculate the volume of the related container?",
        }
      },
      SEALNUMBERS: {
        TYPE: "Type",
        VALUE: "Value",
        SEALNUMBERS: "Seal numbers",
        ADD_NEW_SEALNUMBER: "Add new seal nr.",
        SEALNUMBER_DETAILS: "Seal nr. details",
        ADD_SEALNUMBER: "add"
      },
      TRANSPORTS: of_transports_locale,
      TRANSPORTS_DISPLAYED_COLUMNS: of_transports_displayed_columns_locale,
      TRANSPORT_STOPS_DISPLAYED_COLUMNS: of_transport_stops_displayed_columns_locale,
      STEPS: of_steps_locale,
      NOTES: of_notes_locale,
      BOOKING: of_booking_locale,
      BOOKING_DISPLAYED_COLUMNS: of_booking_displayed_columns_locale,
      SHIPPING_INSTRUCTIONS: of_shipping_locale,
      SHIPPING_INSTRUCTIONS_DISPLAYED_COLUMNS: of_shipping_instructions_displayed_columns_locale,
      COURIER_INSTRUCTIONS: of_courier_instructions_locale,
      COURIER_INSTRUCTIONS_DISPLAYED_COLUMNS: of_courier_instructions_displayed_columns_locale,
      DOCUMENTS: of_documents_locale
    },
    MASTERDATA: masterdata_locale,
    FINANCE: {
      EXPORTED_ACCOUNTING_SOFTWARE: "Exported to accounting software: {{exportTimestamp}}",
      SUCCESSFULLY: "Successfully",
      UNSUCCESSFULLY: "Unsuccessfully",
      SHORT_CREDITED: "CN",
      SHORT_INVOICE: "INV",
      DUE_DATE: "Due date",
      ISSUE_DATE: "Issue date",
      CREATION_DATE: "Creation date",
      EXCHANGE_RATE: "Exchange rate",
      INVOICES_LINES: invoices_lines_locale,
      OUTGOING_INVOICES_LIST: outgoing_invoices_list_locale,
      OUTGOING_INVOICES_NEW: outgoing_invoices_new_locale,
      INCOMING_INVOICES_NEW: incoming_invoices_new_locale,
      INCOMING_INVOICES_LIST: incoming_invoices_list_locale,
      INCOMING_INVOICES_DETAILS: incoming_invoices_details_locale,
      IMPORT_EXPORT: import_export_finance_locale,
      IMPORT_DATA: import_finance_data_locale,
      INCOMING_INVOICES_EXPORT: incoming_invoices_export_locale,
      EXPORT_DATA: export_finance_data_locale,
      INCOMING_INVOICES_DISPLAYED_COLUMNS: incoming_invoices_displayed_columns_locale,
      OUTGOING_INVOICES_DISPLAYED_COLUMNS: outgoing_invoices_displayed_columns_locale
    },
    USERS: {
      USERMANAGEMENT_REDIRECT: "Are you sure you want to be redirected to user management? You will now exit the Fordesk application."
    },
    DIALOG: {
      CHANGEABLE_COLUMNS: {
        TITLE: "Please select the visible columns and their order"
      }
    },
    REPORTING: {
      REPORTING_LIST: reporting_list_locale,
      YEARLY_BUDGET_OVERVIEW: yearly_budget_overview_locale,
      BUDGET_DETAILS_OVERVIEW: budget_details_overview_locale,
      QUOTE_BUDGET_DETAILS_OVERVIEW: quote_budget_details_overview_locale,
      OPERATIONAL_FILES_OVERVIEW: operational_file_overview_locale,
      BUDGET_PER_ACTIVITY_CODE: budget_per_activity_code_overview_locale,
      ACTIONS: {
        DOWNLOAD: "Download report"
      }
    },
    DASHBOARDS: dashboards_locale,
    DOCUMENTS: documents_locale,
    PLACEHOLDERS: {
      COMMUNICATION_TYPE: "Communication type",
      CUSTOMER_COMPANY_NAME: "Customer company name",
      CUSTOMER_CONTACT_NAME: "Customer contact name",
      INVOICE_NUMBER: "Invoice number",
      INVOICE_AMOUNT_INC_VAT: "Invoice amount including VAT",
      INVOICE_AMOUNT_EXC_VAT: "Invoice amount excluding VAT",
      DUE_DATE: "Due date",
      ISSUE_DATE: "Issue date",
      OPERATIONAL_FILE_NUMBER: "Operational file number",
      OPERATIONAL_FILE_CUSTOMER_REFERENCE: "Operational file customer reference",
      SUBJECT: "Subject",
      TEXT: "Text",
      USER_NAME: "User Name",
      ALIAS: "Alias",
      REQUIRED: "Required",
      MAKE_A_CHOICE: "Please make a choice",
      ALIASHINT: "Name, reference, location",
      NEW_STEP: "Add a new step",
      CARRIER: "Carrier",
      CUSTOMS: "Customs",
      ORDER: "Order",
      TRANSPORTER: "Transporter",
      FILETYPE: "File type",
      SHIPMENTTYPE: "Shipment type",
      TRANSPORTTYPE: "Transport type",
      TEMPLATE_NAME: "Template name",
      STEPS: "Steps",
      ADDRESS_LINE1_HINT: "Street address, PO box",
      ADDRESS_LINE2_HINT: "Apartment, unit, building, floor",
      CONTAINERNR: "Container number",
      ADDRESS: "Address",
      ADDRESS_LINE1: "Address line 1",
      ADDRESS_LINE2: "Address line 2",
      CITY_TOWN: "City / town",
      STATE_PROVINCE_REGION: "State / province / region",
      ZIPCODE: "Zip or postal code",
      LOCATION: "Location",
      DATE: "Date",
      REFERENCE: "Reference",
      TOWN: "City / town",
      COUNTRY_SELECT: "Select a country",
      SEARCH_ITEM: "Search {{ name }}",
      SEARCH_CUSTOMS: "Search customs",
      SEARCH_CONTAINERS: "Search containers",
      SEARCH_MULTISTOPS: "Search multistops",
      SEARCH_SEALNUMBERS: "Search seal numbers",
      SEARCH_SUPPLIERS: "Search suppliers",
      SEARCH_ADDRESSES: "Search addresses",
      SEARCH_CONTACTS: "Search contacts",
      SEARCH_CONTACTPERSONTYPES: "Search contact person types",
      SEARCH_IMOCLASSIFICATIONS: "Search IMO classifications",
      SEARCH_ACTIVITYCODES: "Search activity codes",
      SEARCH_CONTAINER_ISO_CODES: "Search container ISO codes",
      SEARCH_COUNTRIES: "Search countries",
      SEARCH_CURRENCIES: "Search currencies",
      SEARCH_DEPOTS: "Search depots",
      SEARCH_HSCODES: "Search HS codes",
      SEARCH_DEPARTMENTS: "Search departments",
      SEARCH_UOM: "Search units of measurement",
      SEARCH_INCOTERMS: "Search incoterms",
      SEARCH_LANGUAGES: "Search languages",
      SEARCH_PARTYTYPES: "Search party types",
      SEARCH_PACKAGETYPES: "Search package types",
      SEARCH_PLACES: "Search places",
      SEARCH_PLACES_TYPEAHEAD: "Enter min 3 characters",
      SEARCH_PORTS: "Search ports",
      SEARCH_SEALNUMBERTYPES: "Search seal number types",
      SEARCH_VESSELS: "Search vessels",
      SEARCH_TERMINALS: "Search terminals",
      SEARCH_USERS: "Search users",
      SEARCH_STEPS: "Search steps",
      SEARCH_INVOICE_COMMENTS: "Search invoice comments",
      SEARCH_STEP_TEMPLATES: "Search step templates",
      SEARCH_CUSTOMERS: "Search customers",
      SEARCH_LOCATIONS: "Search locations",
      SEARCH_GOODS: "Search goods",
      SEARCH_PARTIES: "Search parties",
      SEARCH_TRANSPORTS: "Search transports",
      SEARCH_TRANSPORT_STOPS: "Search transport stops",
      SEARCH_REVENUES: "Search revenues",
      SEARCH_COSTS: "Search costs",
      SEARCH_TARIFFS: "Search tariffs",
      SEARCH_STANDARD_TEXTS: "Search standard texts",
      SEARCH_OCR_SETTINGS: "Search OCR settings",
      CODE: "Code",
      DESCRIPTION: "Description",
      CHOOSE_PORT: "Choose a port",
      CHOOSE_PLACE: "Choose a place",
      CHOOSE_ADDRESS: "Choose an address",
      PORT: "Port",
      PLACE: "Place",
      ACCOUNTING_NUMBER: "Account nr.",
      COMPANY: "Company",
      COMPANY_NAME: "Company name",
      VAT_NUMBER: "VAT nr.",
      MESSAGE_TYPE: "Message type",
      CARRIER_IDENTIFICATION: "Carrier Identification",
      GROUP: "Group",
      CHOOSE_COUNTRY: "Choose a country",
      CHOOSE_ACTIVITY_GROUP: "Choose an activity group",
      PHONE: "Phone",
      MOBILE: "Mobile",
      EMAIL: "E-mail",
      EORI_NUMBER: "EORI nr.",
      LANGUAGE: "Language",
      LEGACYACCOUNTINGNUMBER: "Legacy accounting nr.",
      CURRENCY: "Currency",
      PAYMENT_TERM: "Payment term",
      PARTYTYPE: "Party type",
      NAME: "Name",
      FIRST_NAME: "First name",
      LAST_NAME: "Last name",
      DISPLAY_NAME: "First name",
      COUNTRY: "Country",
      EXTERNAL_REFERENCE: "External reference",
      IBAN_NUMBER: "IBAN number",
      SWIFT_NUMBER: "SWIFT (BIC) code",
      CITY: "City",
      STREET: "Street",
      HOUSE_NUMBER: "House nr.",
      VALIDATION_OFFICE_CODE: "Validation office code",
      WATERWAY_SECTION: "Waterway section",
      QUAY_CODE: "Quay code",
      EXIT_OFFICE_CODE: "Exit office code",
      LOCATION_CODE: "Location code",
      NEW_CODE: "New code",
      COST_ACCOUNT: "Cost account",
      REVENUE_ACCOUNT: "Revenue account",
      COUNTRY_NAME: "Country name",
      ISO_CODE: "ISO code",
      EUROPEAN_UNION: "European Union",
      IS_EU: "Is European Union",
      SYMBOL: "Symbol",
      ZIP_CODE: "ZIP code",
      UNLO_CODE: "UN/LO code",
      IATA_CODE: "IATA code",
      LLOYD_NR: "Lloyd nr.",
      BOX: "Box",
      CONTACTPERSON_TYPE: "Contact person type",
      MAX_DATE: "Max date",
      MIN_DATE: "Min date",
      DATE_FORMAT: "DD-MM-YYYY",
      TODAY: "Today",
      OPERATIONAL_FILE_TYPE: "Operational file type",
      TRANSPORT_TYPE: "Transport type",
      SHIPMENT_TYPE: "Shipment type",
      ACTIVITY_CODE_GROUP: "Activity code group",
      SCAC_CODE: "SCAC code",
      INCLUDE_ON_CUSTOMS_DECLARATION: "Include on customs declaration?",
      STEP: "Step",
      REASON: "Reason",
      DEFAULT: "Default",
      REMARKS: "Remarks",
      CUSTOMER_NAME: "Customer name",
      CUSTOMER_REFERENCE: "Customer reference",
      VALID_DATE: "Valid date",
      QUOTE_NUMBER: "Quote number",
      OF_NUMBER: "OF number",
      RECEIPT_LOCATION: "Receipt location",
      DESTINATION: "Destination",
      DOCUMENT_DATE: "Document date",
      CONTACT_PERSON_NAME: "Contact person name",
      INCOTERM: "Incoterm",
      PORT_OF_LOADING: "Port of Loading",
      PORT_OF_DISCHARGE: "Port of Discharge",
      PLACE_OF_RECEIPT: "Place of Receipt",
      PLACE_OF_DESTINATION: "Place of Destination",
      FINANCE: "Finance",
      QUOTES: "Quotes",
    },
    MESSAGE_STATUS: {
      NOT_SEND_YET: "Not send yet",
      PROCESSING: "Processing",
      AWAITING_REPLY: "Awaiting reply",
      ACCEPTED: "Accepted",
      REFUSED: "Refused",
      ACKNOWLEDGED: "Acknowledged",
      CANCELLED: "Cancelled",
      DECLINED: "Declined",
      CONFIRMED: "Confirmed",
      PENDING: "Pending",
      CONDITIONALLY_ACCEPTED: "Conditionally accepted",
      REPLACED: "Replaced",
    },
    MAIL_COMPONENT: mail_component_locale,
    QUOTES: {
      NEW: new_quotes_locale,
      LIST: list_quotes_locale,
      DISPLAYED_COLUMNS: quotes_displayed_columns,
      QUOTE_REVENUES: quote_revenues_locale,
      REVENUES_DISPLAYED_COLUMNS: quote_revenues_displayed_columns_locale,
      GOODS: quote_goods_locale,
      CONTAINERS: quote_containers_locale,
      COSTS: quote_costs_locale,
      COSTS_DISPLAYED_COLUMNS: quote_costs_displayed_columns_locale,
      BUDGET: quote_budget_locale,
      PARTIES: quote_parties_locale,
      CONTAINERS_DISPLAYED_COLUMNS: quotes_containers_displayed_columns_locale,
      GOODS_DISPLAYED_COLUMNS: quotes_goods_displayed_columns_locale,
      PARTIES_DISPLAYED_COLUMNS: quotes_parties_displayed_columns_locale,
    },
    DIGITAL_ARCHIVE: digital_archive_component_locale,
    DESTINATION_CARD: destination_card_locale,
    FILE_UPLOAD_FAIL: file_upload_fail_component_locale,
    GRID_LAYOUTS: grid_layouts_locale,
    INCOMPLETE_ADDRESS: "Incomplete address",
    NOTIFICATIONS: {
      LIST: notifications_list_locale
    },
    PROSPECTS: {
      DETAILS: prospects_details_locale,
      LIST: prospects_list_locale,
      DISPLAYED_COLUMNS: prospects_displayed_columns_locale,
      PROMOTE_PROSPECT_COMPANY_TITLE: "Promote prospect company"
    },
    COMPANY: {
      LIST: company_list_locale,
      DISPLAYED_COLUMNS: company_displayed_columns_locale
    },
    CONFIG: {
      SYNCHRONIZATION: synchronization,
      USERS: users,
      INTEGRATIONS: integrations,
      DEPARTMENT_USERS: {
        LIST_TITLE: "Department Users",
        SUCCESSFULLY_ADDED: "The user has been successfully added to the department.",
        SUCCESSFULLY_REMOVED: "The user has been successfully removed from the department."
      }
    },
    MODALS: {
      PLEASE_CONFIGURE_YOUR_ACCOUNT_TITLE: "Plese fill in required data",
      PLEASE_CONFIGURE_YOUR_ACCOUNT_MESSAGE: "There are some details for your account, please fill them in.",
      DATA_MODIFIED_IN_ANOTHER_INSTANCE: "This Data has been modified in another instance, please refresh to see the changes.",
      PLEASE_SELECT_AN_OPTION: "Please select an option",
      ONLY_ONE_FILE_CAN_BE_SELECTED: "Only one file can be selected",
      INCORRECT_FILE_TYPE: "Incorrect file type",
      SELECT_FILE: "Please select a file",
      EXTRA_BOOKING_CONFIRMATION_MODAL: extra_booking_confirm,
      RELEASE_NOTES: "Release notes",
      FORDESK_HAS_BEEN_UPDATED: "Fordesk has been updated",
      SET_GOODS_OWNER: {
        TEXT: "Because this is an export to a country outside the european union, we need to know whether the invoice customer {{customerName}} is the owner of the goods being transported to be able to calculate the VAT codes."
      },
      COMPANY_DUPLICATES: {
        REVIEW_POSSIBLE_COMPANY_DUPLICATES_TITLE: "Review possible company duplicates",
        REVIEW_POSSIBLE_SUPPLIER_DUPLICATES_TITLE: "Review possible supplier duplicates",
        REVIEW_POSSIBLE_COMPANY_DUPLICATES_CREATION: "Check the list of companies similar to the company you are trying to create. Please decide whether you want to proceed with creating a new company or use one of the existing ones.",
        REVIEW_POSSIBLE_SUPPLIER_DUPLICATES_CREATION: "Check the list of suppliers similar to the supplier you are trying to create. Please decide whether you want to proceed with creating a new supplier or use one of the existing ones.",
        REVIEW_POSSIBLE_COMPANY_DUPLICATES_EDITING: "Check the list of companies similar to the company you are trying to update. Please decide whether you want to proceed with the changes.",
        REVIEW_POSSIBLE_SUPPLIER_DUPLICATES_EDITING: "Check the list of suppliers similar to the supplier you are trying to update. Please decide whether you want to proceed with the changes.",
        VAT_NUMBER: "VAT number",
      }
    }
  }
};
