import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, LOCALE_ID, NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { CoreModule } from "./core/core.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { GlobalHTTPErrorInterceptorService } from "./core/interceptors/global-http-error-interceptor.service";
import { registerLocaleData, Location } from "@angular/common";
import localeEnBe from "@angular/common/locales/en-BE";
import { LoaderInterceptor } from "./core/services/loading-overlay/loading-overlay.interceptor";
import { environment } from "../environments/environment";
import { JwtHelperService } from "@auth0/angular-jwt";
import { locale as enLang } from "./config/i18n/en";
import { TranslationService } from "./core/services/translation.service";
import { ApiModule as FordeskApiModule, BASE_PATH as FORDESK_BASE_PATH, TenantsService, TenantDTO } from "./core/services/swagger-gen/fordesk";
import { PagesModule } from "./content/pages/pages.module";
import { SharedModule } from "./content/shared/shared.module";
import { TranslateModule } from "@ngx-translate/core";
import { ToastrModule } from "ngx-toastr";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from "@angular/material/snack-bar";
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from "@angular/material/tooltip";
import { MatIconRegistry } from "@angular/material/icon";
import { ClickOutsideModule } from 'ng-click-outside';
import { MsalGuard, MsalModule, MsalService } from "@azure/msal-angular";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { AuthConfig, MSALConfig } from "./core/auth/fordesk/configuration/auth.configuration";
import { DfmInterceptor } from "./core/auth/fordesk/interceptors/dfm.interceptor";
import { UserManagementApiModule } from "./core/auth/user-mgt/api.module";
import { SelectedTenant, TenantSynchronizationService } from "./core/services/tenants-synchronization.service";
import { tap } from "rxjs";
import { UserInitialisationInterceptor } from "./core/interceptors/user-initialisation-interceptor";
import { SELECTED_TENANT } from "./core/services/helpers/session-storage-keys";

export function initApplication(
  translationService: TranslationService,
  location: Location,
  tenantsService: TenantsService,
  tenantSynchronizationService: TenantSynchronizationService,
  msalService: MsalService
) {
  return () => {
    if (location.path().startsWith('/config/integrations/exact-online/auth')) {
      // Load translations but skip OAuth-related initialization
      return translationService.loadTranslations(enLang).toPromise();
    } else {
      return translationService.loadTranslations(enLang)
        .toPromise()
        .then(() => {
          if (msalService.instance.getActiveAccount()) {
            //initialize user tenant
            return tenantsService.getAllowedTenants().pipe(tap((data: TenantDTO[]) => {
              tenantSynchronizationService.setAllowedTenants(data);
              //try set saved tenant
              const selectedTenantFromStorage = JSON.parse(localStorage.getItem(SELECTED_TENANT)) as SelectedTenant;
              if (data.some(x => x.id == selectedTenantFromStorage?.tenantId) && selectedTenantFromStorage?.externalTenantId && selectedTenantFromStorage?.tenantId) {
                tenantSynchronizationService.emitTenantChangedEvent(selectedTenantFromStorage);
              }
              //set default tenant
              else {
                const selectedTenant: SelectedTenant = {
                  tenantId: data[0].id,
                  externalTenantId: data[0].externalTenantId,
                }
                localStorage.setItem(SELECTED_TENANT, JSON.stringify(selectedTenant))
                tenantSynchronizationService.emitTenantChangedEvent(selectedTenant);
              }
            }))
              .pipe().toPromise();
          }
        });
    }
  };
}

registerLocaleData(localeEnBe);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    UserManagementApiModule.forRoot({ rootUrl: environment.authentication.userManagementApiUrl }),
    ClickOutsideModule,
    TranslateModule.forRoot(),
    SharedModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
      enableHtml: true
    }),
    MsalModule.forRoot(
      new PublicClientApplication(MSALConfig),
      {
        // The routing guard configuration.
        interactionType: InteractionType.Redirect,
      },
      {
        // MSAL interceptor configuration.
        // The protected resource mapping maps your web API with the corresponding app scopes. If your code needs to call another web API, add the URI mapping here.
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map(AuthConfig.protectedApis.map((apis) => [apis.url, [apis.scope]])),
      },
    ),
    CoreModule,
    PagesModule,
    FordeskApiModule,
  ],
  providers: [
    MsalGuard,
    {
      provide: JwtHelperService, useValue: new JwtHelperService()
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 2500, verticalPosition: "top" }
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue:
      {
        showDelay: 500,
        disableTooltipInteractivity: true
      }
    },
    { provide: MAT_DATE_LOCALE, useValue: "en-BE" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DfmInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHTTPErrorInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UserInitialisationInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: "en-BE" },
    {
      provide: APP_INITIALIZER,
      useFactory: initApplication,
      deps: [TranslationService, Location, TenantsService, TenantSynchronizationService, MsalService],
      multi: true
    },
    { provide: FORDESK_BASE_PATH, useValue: environment.forDeskApiConfig.host },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(public matIconRegistry: MatIconRegistry) {
    matIconRegistry.registerFontClassAlias("lineawesome", "la");
    matIconRegistry.registerFontClassAlias("fontawesome", "fa");
  }
}
