/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { OperationalFileCustomsDeclaration } from './operationalFileCustomsDeclaration';

export interface OperationalFileChosenCustomsDocument { 
    rowCreatedTimestamp?: Date;
    lastRowModificationTimestamp?: Date;
    isDeleted?: boolean;
    id?: number;
    operationalFileDocumentType?: OperationalFileChosenCustomsDocument.OperationalFileDocumentTypeEnum;
    externalStoredDocument?: string;
    operationalFileCustomsDeclarationId?: number;
    operationalFileCustomsDeclaration?: OperationalFileCustomsDeclaration;
}
export namespace OperationalFileChosenCustomsDocument {
    export type OperationalFileDocumentTypeEnum = 0;
    export const OperationalFileDocumentTypeEnum = {
        NUMBER_0: 0 as OperationalFileDocumentTypeEnum
    };
}