import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ConflictDialogData } from "../../../core/models/dialogs/conflict-dialog-data";
import { DataConflictOptionsEnum } from "../../../core/models/dialogs/enums/data-confliect-options.enum";

@Component({
  selector: "data-conflict.dialog",
  templateUrl: "data-conflict.dialog.component.html"
})
export class DataConflictDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DataConflictDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConflictDialogData
  ) { }

  public options = DataConflictOptionsEnum;

  public submit(option: DataConflictOptionsEnum) {
    this.dialogRef.close(option)
  }
}
