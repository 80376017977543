/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Currency } from './currency';
import { Incoterm } from './incoterm';
import { OperationalFile } from './operationalFile';
import { OperationalFileUser } from './operationalFileUser';
import { Port } from './port';
import { QuoteFileContainer } from './quoteFileContainer';
import { QuoteFileCost } from './quoteFileCost';
import { QuoteFileGoods } from './quoteFileGoods';
import { QuoteFileParty } from './quoteFileParty';
import { QuoteFileRevenue } from './quoteFileRevenue';
import { QuoteFileStatus } from './quoteFileStatus';

export interface QuoteFile { 
    rowCreatedTimestamp?: Date;
    lastRowModificationTimestamp?: Date;
    isDeleted?: boolean;
    id?: number;
    customerReference?: string;
    fileNumber?: string;
    department?: string;
    remarks?: string;
    quoteFileStatusId?: number;
    quoteFileStatus?: QuoteFileStatus;
    operationalFileType?: QuoteFile.OperationalFileTypeEnum;
    transportType?: QuoteFile.TransportTypeEnum;
    shipmentType?: QuoteFile.ShipmentTypeEnum;
    documentDate?: Date;
    validUntil?: Date;
    quoteDocumentId?: string;
    currencyId?: number;
    departmentId?: number;
    currency?: Currency;
    exchangeRate?: number;
    internalSubject?: string;
    portOfDischargeId?: number;
    portOfDischarge?: Port;
    portOfLoadingId?: number;
    portOfLoading?: Port;
    placeOfReceiptId?: number;
    placeOfReceipt?: Port;
    placeOfDestinationId?: number;
    placeOfDestination?: Port;
    operationalFileUserId?: number;
    operationalFileUser?: OperationalFileUser;
    operationalFileId?: number;
    operationalFile?: OperationalFile;
    incotermId?: number;
    incoterm?: Incoterm;
    quoteFileGoods?: Array<QuoteFileGoods>;
    quoteFileContainers?: Array<QuoteFileContainer>;
    quoteFileRevenues?: Array<QuoteFileRevenue>;
    quoteFileCosts?: Array<QuoteFileCost>;
    quoteFileParties?: Array<QuoteFileParty>;
    deadline?: Date;
    reminderSentDate?: Date;
    rowVersion?: string;
}
export namespace QuoteFile {
    export type OperationalFileTypeEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6;
    export const OperationalFileTypeEnum = {
        NUMBER_0: 0 as OperationalFileTypeEnum,
        NUMBER_1: 1 as OperationalFileTypeEnum,
        NUMBER_2: 2 as OperationalFileTypeEnum,
        NUMBER_3: 3 as OperationalFileTypeEnum,
        NUMBER_4: 4 as OperationalFileTypeEnum,
        NUMBER_5: 5 as OperationalFileTypeEnum,
        NUMBER_6: 6 as OperationalFileTypeEnum
    };
    export type TransportTypeEnum = 0 | 1 | 2 | 3;
    export const TransportTypeEnum = {
        NUMBER_0: 0 as TransportTypeEnum,
        NUMBER_1: 1 as TransportTypeEnum,
        NUMBER_2: 2 as TransportTypeEnum,
        NUMBER_3: 3 as TransportTypeEnum
    };
    export type ShipmentTypeEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6;
    export const ShipmentTypeEnum = {
        NUMBER_0: 0 as ShipmentTypeEnum,
        NUMBER_1: 1 as ShipmentTypeEnum,
        NUMBER_2: 2 as ShipmentTypeEnum,
        NUMBER_3: 3 as ShipmentTypeEnum,
        NUMBER_4: 4 as ShipmentTypeEnum,
        NUMBER_5: 5 as ShipmentTypeEnum,
        NUMBER_6: 6 as ShipmentTypeEnum
    };
}