export const of_customs_locale = {
  TITLE: "Customs document",
  ADD: "Add a custom",
  EDIT: "Edit a custom",
  TYPE: "Type",
  DATE_SENT: "Date sent",
  DATE_READY: "Date ready",
  STATUS: "Status",
  DECLARATION_TYPE: "Declaration type",
  COMBINED_DECLARATION_TYPE: "Declaration type",
  DECLARATION_TYPE_PART_ONE: "Declaration type (part one)",
  DECLARATION_TYPE_PART_TWO: "Declaration type (part two)",
  ASSIGNMENT_DATE: "Assignment date",
  PROCEDURE_TYPE: "Procedure type",
  CUSTOMER: "Customer",
  CLEARANCE_PARTY: "Clearance party",
  CONSIGNOR_PARTY: "Consignor party",
  CONSIGNEE_PARTY: "Consignee party",
  CUSTOMS_AGENT: "Customs agent",
  REMARKS: "Remarks",
  CHOOSE_DOCS: "Choose documents",
  ADDED_DOCS: "Added documents",
  MESSAGES: {
    SEND_DOCS_TO_CUSTOMS: "{{count}} document(s) will be sent to the customs authority.",
    // TODO: Replace "PortMade" with other agency if needed based on tenant
    ALREADY_SENT_SELECTED: "Your selection contains documents that have already been sent. This will cause duplicates, after sending, please contact Remant.",
  },
  CUSTOMS_OFFICE_CONSIGNOR: "Customs office consignor",
  CUSTOMS_OFFICE_CONSIGNEE: "Customs office consignee",
  INLAND_TRANSPORT_MODE: "Inland transport mode",
  INVOICE_INFORMATION: "Invoice information",
  INVOICE_TYPE: "Invoice type",
  INVOICE_REFERENCE: "Invoice reference",
  INVOICE_AMOUNT: "Invoice amount",
  INVOICE_CURRENCY: "Invoice currency",
  INVOICE_DATE: "Invoice date",
  PREVIOUS_DOC_TYPE: "Previous document type",
  VAT_NUMBER: "Vat number",
  INVOICE_PARTY_NAME: "Invoice party name",
  LOCATION_CODE: "Location code",
  ARTICLE_NUMBER: "Article number",
  AGENT_CODE: "Agent code",
  ITEM_NUMBER: "Item number",
  STAY_NUMBER: "Stay number",
  DOCUMENT_EXCLUDED: "The document '{{documentName}}' has been moved, renamed, or deleted, so it has been excluded from the document selection. Please locate and add it again if needed",
  PREVIOUS_ARRANGEMENT_DOC_ART: "Previous arrangement document article",
  PREVIOUS_ARRANGEMENT_DOC_DATE: "Previous arrangement document date",
  PREVIOUS_ARRANGEMENT: "Previous arrangement",
  CONTAINERS: "Containers",
  CONTAINERS_NOT_FOUND: "No containers were found for your query. To add them, go to the 'Containers' tab.",
  CONTAINER_REQUIRED: "At least one container is required.",
  GOODS: "Goods",
  GOODS_NOT_FOUND: "The selected containers do not contain such goods.",
  GOOD_REQUIRED: "At least one good is required.",
  CONTAINER_GOODS: {
    OUTER_PACKAGE_TYPE_CODE_DESCRIPTION: "Outer package type code - description",
    GOODS_DESCRIPTION: "Goods description",
    NUMBER_OF_COLLIS: "Number of collis",
    GROSS_WEIGHT: "Gross weight",
    CONTAINER_NUMBER: "Container number",
    SHOW_CONTAINER_GOODS: "Show selected container goods details",
    HIDE_CONTAINER_GOODS: "Hide selected container goods details"
  }
};
