/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UpdateClientPortalUserPermission { 
    invitationId?: number;
    permission?: UpdateClientPortalUserPermission.PermissionEnum;
    isActive?: boolean;
}
export namespace UpdateClientPortalUserPermission {
    export type PermissionEnum = 0 | 1;
    export const PermissionEnum = {
        NUMBER_0: 0 as PermissionEnum,
        NUMBER_1: 1 as PermissionEnum
    };
}