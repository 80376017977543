import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { TenantPermissions } from '../enums/tenant-permissions';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionService {
  private userPermissions$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private userHasGlobalAdminPemissions$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  public get getPermissions(): string[] {
    return this.userPermissions$.value;
  }

  public emitPermissionsChanged(permissions: string[]) {
    this.userPermissions$.next(permissions)
  }

  public getPermissionsObservable$(): Observable<string[]> {
    return this.userPermissions$.asObservable();
  }

  public emitUserHasGlobalAdminPemissions(hasGlobalAdminPermission: boolean) {
    this.userHasGlobalAdminPemissions$.next(hasGlobalAdminPermission)
  }

  public getUserHasGlobalAdminPemissionsObservable$(): Observable<boolean> {
    return this.userHasGlobalAdminPemissions$.asObservable();
  }

  public userHasFordeskClientPortalConfiPermission$(): Observable<boolean> {
    return of(this.userPermissions$.value.includes(TenantPermissions.CanPerformClientPortalConfig));
  }

  public userHasDiflexmoAdminPermission$(): Observable<boolean> {
    return this.userHasGlobalAdminPemissions$.asObservable();
  }

  public userHasAdminPermission$(): Observable<boolean> {
    const hasPermission = this.userPermissions$.value.includes(TenantPermissions.CanPerformAdmin) || this.userHasGlobalAdminPemissions$.value
    return of(hasPermission);
  }

  public userHasUserManagerPermission$(): Observable<boolean> {
    const hasPermission = this.userPermissions$.value.includes(TenantPermissions.CanPerformUserManagement) || this.userHasGlobalAdminPemissions$.value
    return of(hasPermission);
  }

  public userHasUserManagerPermission(): boolean {
    const hasPermission = this.userHasGlobalAdminPemissions$.value || this.userPermissions$.value.includes(TenantPermissions.CanPerformUserManagement)
    return hasPermission;
  }

  public userHasAdminPermission(): boolean {
    const hasPermission = this.userPermissions$.value.includes(TenantPermissions.CanPerformAdmin) || this.userHasGlobalAdminPemissions$.value
    return hasPermission;
  }

  public userHasFinanceExtensionPermission$(): Observable<boolean> {
    return of(this.userPermissions$.value.includes(TenantPermissions.CanPerformFinanceExtension));
  }

  public userHasOCRUserPermission$(): Observable<boolean> {
    return of(this.userPermissions$.value.includes(TenantPermissions.CanPerformOcr));
  }

  public userHasNotificationsPermission$(): Observable<boolean> {
    return of(this.userPermissions$.value.includes(TenantPermissions.CanPerformNotifications));
  }

  public userHasPermission(permission: TenantPermissions): boolean {
    return this.userPermissions$.value.includes(permission);
  }
}
