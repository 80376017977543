/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CompanySearchCondition { 
    pageSize?: number;
    page?: number;
    readonly listSortDirection?: CompanySearchCondition.ListSortDirectionEnum;
    sortDirection?: string;
    sortProperty?: string;
    companyName?: Array<string>;
    legacyAccountingNumber?: Array<string>;
    stateProvinceRegion?: Array<string>;
    addressLine1?: Array<string>;
    addressLine2?: Array<string>;
    zipCode?: Array<string>;
    countryName?: Array<string>;
    vatNumber?: Array<string>;
    place?: Array<string>;
    messageType?: Array<string>;
    status?: Array<CompanySearchCondition.StatusEnum>;
}
export namespace CompanySearchCondition {
    export type ListSortDirectionEnum = 0 | 1;
    export const ListSortDirectionEnum = {
        NUMBER_0: 0 as ListSortDirectionEnum,
        NUMBER_1: 1 as ListSortDirectionEnum
    };
    export type StatusEnum = '0' | '1' | '2';
    export const StatusEnum = {
        _0: '0' as StatusEnum,
        _1: '1' as StatusEnum,
        _2: '2' as StatusEnum
    };
}