export const incoming_invoices_export_locale = {
  TITLE: "Export incoming invoices",
  EXPORT_METHOD: "Export method",
  SELECT_EXPORT_METHOD: "Select export method booking",
  ACCOUNT_HOLDERS_HAME: "Account holder's name",
  BUNDLE_OPTION_DESCRIPTION: "Payments via an XML can be bundled. Only one execution date can be specified per bundle, which is determined based on the invoice that expires first.",
  BUNDLE_OPTION: "Bundle (execution date: due date)",
  MARK_AS_PAID_OPTION: "Mark invoices as paid",
  PAYMENT_DATE: "Payment date",
};
