import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { ComponentBase } from "../../../../core/components/abstractions/component-base";
import { SelectedTenant, TenantSynchronizationService } from "../../../../core/services/tenants-synchronization.service";
import { TenantDTO } from "../../../../core/services/swagger-gen/fordesk";
import { FormControl, FormGroup } from "@angular/forms";
import { SELECTED_TENANT } from "../../../../core/services/helpers/session-storage-keys";
import { filter, takeUntil } from "rxjs";

@Component({
  selector: "allowed-tenants",
  templateUrl: "./allowed-tenants.component.html",
  changeDetection: ChangeDetectionStrategy.Default
})
export class AllowedTenantsComponent extends ComponentBase implements OnInit {
  public allowedTenants: TenantDTO[] = [];
  public tenantId = new FormControl(null, []);
  public form: FormGroup;

  constructor(
    public tenantSynchronizationService: TenantSynchronizationService,
    public cdRef: ChangeDetectorRef
  ) {
    super();
    this.form = new FormGroup({
      tenantId: this.tenantId,
    });
  }

  ngOnInit(): void {
    this.tenantSynchronizationService.getTenantChangedObservable$().pipe(filter((data: SelectedTenant) => !!data), takeUntil(this.unsubscribe)).subscribe((selectedTenant: SelectedTenant) => {
      this.allowedTenants = this.tenantSynchronizationService.getAllowedTenants;
      this.tenantId.patchValue(selectedTenant.tenantId, { emitEvent: false });
      const selectedTenantFromStorage = JSON.parse(localStorage.getItem(SELECTED_TENANT)) as SelectedTenant;
      if (this.allowedTenants.some(x => x.id == selectedTenantFromStorage.tenantId) && selectedTenantFromStorage?.externalTenantId && selectedTenantFromStorage?.tenantId) {
        this.tenantId.patchValue(selectedTenantFromStorage.tenantId, { emitEvent: false });
      }
    })

    this.tenantId.valueChanges.subscribe((id: string) => {
      const tenant = this.allowedTenants.find(t => t.id === Number.parseInt(id));
      const selectedTenant: SelectedTenant = {
        tenantId: tenant.id,
        externalTenantId: tenant.externalTenantId
      }
      localStorage.setItem(SELECTED_TENANT, JSON.stringify(selectedTenant));
      this.tenantSynchronizationService.emitTenantChangedEvent(selectedTenant)
      window.location.reload();
    });
  }
}
