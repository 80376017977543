import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { concatMap, map, takeUntil } from "rxjs/operators";
import { Observable, concat, forkJoin, of } from "rxjs";
import {
  AccountingGroupsService,
  CodedLookupDTO,
  CompaniesService,
  CompanyAddressesService,
  CountriesService,
  CurrenciesService,
  LanguagesService,
  LookupDTO,
  MasterDataService,
  MessageTypesService,
  PaymentTermsService,
  PlaceDTO,
  PlacesService,
  CompanyProspectService,
} from "../../../core/services/swagger-gen/fordesk";
import { CompanyDetailsBaseComponent } from "../../pages/masterdata/components/abstractions/company-details-base.component";
import { ActivatedRoute, Router } from "@angular/router";
import { PlaceLookupHelper } from "../../../core/helpers/place-lookup-helper";
import { ToasterService } from "../../layout/toaster/toaster.service";
import { UnsavedChangesService } from "../../../core/services/helpers/unsaved-changes.service";
import { CompanyDetailsComponent } from "../../pages/masterdata/components/companies/company-details/company-details.component";
import propertyOf from "../../../core/models/utilities/name-of.type";
import { ConfirmDialogResultData } from "../../../core/models/dialogs/confirm-dialog-data";
import { ConfirmDialog } from "../../dialogs/confirm/confirm.component";
import { CompanyDuplicatesDialogComponent } from "../company-duplicates-dialog/company-duplicates-dialog";
import { CompanyType } from "../../../core/enumerations/company-type.enum";

export class PromoteProspectCompanyDialogData {
  public companyId: number;
  public approveDialog?: {
    dialogTitle: string,
    confirmationText: string,
  }
}

export class PromoteProspectCompanyResultData {
  public isPromoted: boolean;
}

@Component({
  selector: "promote-prospect-company",
  templateUrl: "./promote-prospect-company.html",
  styleUrls: ["./promote-prospect-company.scss"]
})
export class PromoteProspectCompanyComponent extends CompanyDetailsBaseComponent implements OnInit, OnDestroy {
  public messageTypes: LookupDTO[] = [];
  public accountingGroups: CodedLookupDTO[] = [];
  public currencies: LookupDTO[] = [];
  public paymentTerms: LookupDTO[] = [];
  public companyProspectId: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: PromoteProspectCompanyDialogData,
    private dialogRef: MatDialogRef<PromoteProspectCompanyComponent>,
    protected fb: UntypedFormBuilder,
    protected translate: TranslateService,
    protected dialog: MatDialog,
    protected route: ActivatedRoute,
    protected router: Router,
    protected cdRef: ChangeDetectorRef,
    protected toaster: ToasterService,
    protected accountingGroupsService: AccountingGroupsService,
    protected currenciesService: CurrenciesService,
    protected countriesService: CountriesService,
    protected companiesService: CompaniesService,
    protected companyAddressesService: CompanyAddressesService,
    protected companyProspectService: CompanyProspectService,
    protected languesService: LanguagesService,
    protected masterDataService: MasterDataService,
    protected messageTypesService: MessageTypesService,
    protected paymentTermsService: PaymentTermsService,
    protected placeService: PlacesService,
    protected placeLookupHelper: PlaceLookupHelper,
    unsavedChangedService: UnsavedChangesService,
  ) {
    super(masterDataService,
      dialog,
      route,
      placeService,
      placeLookupHelper,
      router,
      translate,
      toaster,
      fb,
      cdRef,
      unsavedChangedService
    );
  }

  public ngOnInit() {
    this.createForm();
    this.loadFormData();
  }

  public ngOnDestroy() {
    this.unsavedChangedService.unsubscribeComponent(this);
    return super.ngOnDestroy();
  }

  public save() {
    if (!this.validate()) {
      return;
    }

    const confirmation$ = this.getSaveConfirmation();
    confirmation$
      .pipe(
        takeUntil(this.unsubscribe),
        concatMap((confirmation: ConfirmDialogResultData) => this.checkDuplicatedIfConfirmed(confirmation)),
        concatMap((confirmation: ConfirmDialogResultData) => this.promoteIfConfirmed(confirmation)),
      )
      .subscribe(promoted => {
        if (promoted.isPromoted) {
          this.dialogRef.close(promoted);
        }
      });
  }

  private getSaveConfirmation(): Observable<ConfirmDialogResultData> {
    if (this.data.approveDialog) {
      const dialogConfig = {
        disableClose: true,
        data: {
          dialogTitle: this.data.approveDialog.dialogTitle,
          confirmationText: this.data.approveDialog.confirmationText,
        },
      };
      return this.dialog.open(ConfirmDialog, dialogConfig).afterClosed();
    } else {
      return of({ isConfirmed: true });
    }
  }

  private checkDuplicatedIfConfirmed(confirmation: ConfirmDialogResultData): Observable<{ isConfirmed: boolean }> {
    if (confirmation?.isConfirmed) {
      const prospectCompany = this.detailsForm.value;
      return this.companiesService.searchCompanyDuplicates(prospectCompany.companyName, prospectCompany.vatNumber, CompanyType.Customer)
        .pipe(
          takeUntil(this.unsubscribe),
          concatMap(companies => {
            if (companies.length) {
              return this.dialog.open(CompanyDuplicatesDialogComponent, { disableClose: true, data: { companies: companies, companyType: CompanyType.Customer }, autoFocus: false, width: "700px" })
                .afterClosed()
                .pipe(takeUntil(this.unsubscribe));
            } else {
              return of({ isConfirmed: true });
            }
          })
        );
    } else {
      return of({ isConfirmed: false });
    }
  }

  private promoteIfConfirmed(confirmation: ConfirmDialogResultData): Observable<{ isPromoted: boolean }> {
    if (confirmation?.isConfirmed) {
      const prospectCompany = this.detailsForm.value;
      return this.companyProspectService.promoteProspectCompany(this.data.companyId, prospectCompany)
        .pipe(
          takeUntil(this.unsubscribe),
          map(() => ({ isPromoted: true })),
        );
    } else {
      return of({ isPromoted: false });
    }
  }

  public close() {
    this.dialogRef.close({ isPromoted: false });
  }

  protected saveInternal(): void {
    // Not used, but here for abstract function implementation
  }

  public clearPlace(): void {
    this.setFormValue("zipCode", null);
  }

  public accountingGroupSearch(search: string, item: CodedLookupDTO): boolean {
    search = search.toLocaleLowerCase();
    const propertiesToBeSearched: string = `${item.code} ${item.description}`;

    return propertiesToBeSearched.toLocaleLowerCase().includes(search);
  }

  private createForm() {
    this.detailsForm = this.fb.group({
      companyName: [null, [Validators.required, Validators.maxLength(256)]],
      addressLine1: [null, Validators.required],
      addressLine2: [],
      placeId: [null, Validators.required],
      stateProvinceRegion: [],
      zipCode: [null],
      countryId: [null, Validators.required],
      messageTypeId: [null, Validators.required],
      accountingGroupId: [null, Validators.required],
      languageId: [null, Validators.required],
      currencyId: [null, Validators.required],
      paymentTermId: [null, Validators.required],
      vatNumber: [],
    });
  }

  private loadFormData() {
    forkJoin({
      companyDetails: this.companiesService.getCompanyDetails(this.data.companyId),
      messageTypes: this.messageTypesService.getAllMessageTypes(),
      accountingGroups: this.accountingGroupsService.getCustomerGroups(),
      currencies: this.currenciesService.getAllCurrencies(),
      paymentTerms: this.paymentTermsService.getAllPaymentTerms(),
      languages: this.languesService.getAllLanguages(),
      countries: this.countriesService.getAllCountries(),
    }).pipe(
      takeUntil(this.unsubscribe),
      map(data => {
        this.messageTypes = data.messageTypes.results ?? [];
        this.accountingGroups = data.accountingGroups.results ?? [];
        this.currencies = data.currencies.results ?? [];
        this.paymentTerms = data.paymentTerms.results ?? [];
        this.languages = data.languages.results ?? [];
        this.countries = data.countries.results ?? [];
        this.companyProspectId = data.companyDetails.prospectId;

        const companyDetails = data.companyDetails;
        this.detailsForm.patchValue(companyDetails);

        this.refreshByCache(propertyOf<CompanyDetailsComponent>("countries"), this.countries, "countryId");

        if (companyDetails?.placeId) {
          this.placeService
            .getPlaceById(companyDetails.placeId)
            .subscribe((place: PlaceDTO) => {
              this.places$ = concat(of([place]), this.createPlacesLookup());
              this.setFormValue("placeId", place.id);
            });
        }
      })
    ).subscribe(() => this.cdRef.detectChanges());
  }
}
