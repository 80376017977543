export const of_goods_locale = {
  ADD_TITLE: "Add a new goods line",
  DIMENSIONS: "Dimensions",
  OOG_DIMENSIONS: "Out of gauge dimensions",
  IMO: "IMO",
  NR_OF_COLLIS: "Nr. of collis (outer)",
  NR_OF_COLLIS_COLUMN: "# collis",
  NR_OF_COLLIS_COMPACT: "collis",
  COLLIS_REMAINING: "collis rem.",
  PACKAGE_TYPE: "Package type",
  OUTER_PACKAGING: "Outer packaging",
  INNER_PACKAGING: "Inner packaging",
  HS_CODE: "HS Codes",
  GROSS_WEIGHT: "Gross weight",
  DESCRIPTION: "Description",
  GROSS_WEIGHT_COMPACT: "kg",
  NET_WEIGHT: "Net weight",
  CHARGEABLE_WEIGHT: "Chargeable weight",
  CUBAGE: "Cubage",
  CUBAGE_COMPACT: "m³",
  LENGTH: "Length",
  WIDTH: "Width",
  HEIGHT: "Height",
  HS_CODES: "Hs code(s)",
  IMO_CONTACT_PERSON: "Contact",
  IMO_CONTACT_PHONENUMBER: "Phone",
  LIMITED_QUANTITY: "Limited quantity",
  UN_CODE: "Un code(s)",
  MARKS_AND_NUMBERS: "Marks and numbers",
  EXTRA_REMARKS: "Extra remarks",
  CLASS: "Class",
  UN_CODE_SINGULAR: "UN code",
  PACKAGEGROUP: "Packagegroup",
  FLASHPOINT: "Flashpoint",
  CONTAINERS: "Containers",
  MSG_GOODS_ADDED: "The goods have been successfully added!",
  EXPANDED: {
    CONTAINER_NUMBER: "Container number",
    CONTAINER_SIZE: "Size",
    NUMBER_OF_COLLI: "# collis",
    WEIGHT: "Weight",
    NO_GOODS_CONTAINERS_FOUND: "This good is not used in any container."
  },
  VALIDATION: {
    MIN_ONE_COLLI: "At least one colli required.",
    MIN_COLLI: "There are {{collis}} collis assigned to container(s). You cannot enter less than {{collis}} collis.",
    MIN_WEIGHT: "There is {{minWeight}}kg assigned to container(s). You cannot enter less than {{minWeight}}kg.",
    IMO_CONTACT_PERSON_REQUIRED: "Contact person is required when selecting a UN code.",
    IMO_CONTACT_PHONE_REQUIRED: "Contact phone is required when selecting a UN code.",
    NR_OF_PIECES_NOT_REQUIRED_MUST_BE_POSITIVE: "Nr. of pieces is not required, however if you provide it, it must be greater than 0.",
    DESCRIPTION_OR_GROSS_WEIGHT_REQUIRED: "Either a description or gross weight are required.",
    ONE_OOG_DIMENSION_REQUIRED: "Either a length, width or height are required.",
    CUBAGE_PRECISION: "Cubage can have up to 4 precision.",
  },
  GOOD_HAS_CONTAINERGOODS: "This good has been assigned in a container. ",
  NO_GOODS_REMAINING: "No unassigned goods remaining. Add new goods or add Nr. of collis to goods before continuing.",
  NO_GOODS_WITH_AVAILABLE_COLLIS: "There are no goods with available number of collis.",
  SOME_GOODS_WAS_SKIPPED: "Some goods could not be added because they have no collies.",
  ADD_GOODS_ALREADY_ASSIGNED: "All goods in the operational file have already been assigned to containers.",
  COPY_GOODS_TIMES: "How many copies of these goods would you like?",
  COPY_GOODS_TITLE: "Goods copy",
  NO_OF_COPIES: "Number of copies",
  MESSAGES: {
    ADDED: "The goods have been successfully added!",
    COPIED: "The selected goods have been copied {{times}} times.",
    OPERATIONAL_FILE_GOODS_MAX_QUANTITY_EXCEEDED: "Operational files cannot contain more than 500 goods.",
    MARKS_AND_NUMBERS_OUT_OF_BOUNDS: "We recommend not exceeding 17 characters for best print results on the BL. Lines: {{lines}}",
    ONLY_UPLOAD_CSV: "Only .CSV files are allowed",
    UPLOAD_FILE: "Please select a file to upload"
  },
  NR_OF_PIECES: "Nr. of pieces (inner)",
  NO_GOODS_FOUND: "No goods in operational file.",
  GOODS: "goods",
  IMPORT_FROM_FILE: "Import goods from file",
  SELECT_CSV_OR_XLSX: "Please select a .CSV or .XLSX file to import goods in {{operationalFileNumber}}.",
  HAS_OOG_DIMENSIONS: "Out of gauge?",
  DIALOG: {
    YES_INCREASE: "Yes, update container",
    NO_INCREASE: "No, do not assign the extra goods",
    YES_REDUCE: "proceed",
    TITLE: "Goods amount was changed.",
    REDUCE: "You are reducing the amount of goods available but this good is already assigned to a container {{number}}. If you proceed, the container will be updated to reflect the change in goods.",
    INCREASE: "You have increased the amount available for this good, which is currently assigned to container {{number}}. Do you want to update the container to reflect this change?",
    HAS_LINKED_CONTAINERS: "This goods is split up into one or more containers. To reduce, first change the amounts in the containers.",
    IN_USE_AND_DATA_REQUIRED: "This goods is used by one or more containers and gross weight and number of collis is required."
  }
};
