export const OPERATIONAL_STORAGE = "OPERATIONAL_STORAGE";
export const OPERATIONAL_COLUMNS_STORAGE = "OPERATIONAL_COLUMNS_STORAGE";
export const OPERATIONAL_SELECTED_LAYOUT_STORAGE = "OPERATIONAL_SELECTED_LAYOUT_STORAGE";
export const INCOMING_INVOICE_STORAGE = "INCOMING_INVOICE_STORAGE";
export const INCOMING_INVOICE_COLUMNS_STORAGE = "INCOMING_INVOICE_COLUMNS_STORAGE";
export const INCOMING_INVOICE_SELECTED_LAYOUT_STORAGE = "INCOMING_INVOICE_SELECTED_LAYOUT_STORAGE";
export const INCOMING_INVOICE_FOR_CODA_STORAGE = "INCOMING_INVOICE_FOR_CODA_STORAGE";
export const INCOMING_INVOICE_FOR_CODA_COLUMNS_STORAGE = "INCOMING_INVOICE_FOR_CODA_COLUMNS_STORAGE";
export const INCOMING_INVOICE_FOR_CODA_SELECTED_LAYOUT_STORAGE = "INCOMING_INVOICE_FOR_CODA_SELECTED_LAYOUT_STORAGE";
export const IMPORT_PREVIEW_INCOMING_INVOICE_COLUMNS_STORAGE = "IMPORT_PREVIEW_INCOMING_INVOICE_COLUMNS_STORAGE";
export const OUTGOING_INVOICE_STORAGE = "OUTGOING_INVOICE_STORAGE";
export const OUTGOING_INVOICE_COLUMNS_STORAGE = "OUTGOING_INVOICE_COLUMNS_STORAGE";
export const OUTGOING_INVOICE_SELECTED_LAYOUT_STORAGE = "OUTGOING_INVOICE_SELECTED_LAYOUT_STORAGE";
export const OUTGOING_INVOICE_FOR_CODA_STORAGE = "OUTGOING_INVOICE_FOR_CODA_STORAGE";
export const OUTGOING_INVOICE_FOR_CODA_COLUMNS_STORAGE = "OUTGOING_INVOICE_FOR_CODA_COLUMNS_STORAGE";
export const OUTGOING_INVOICE_FOR_CODA_SELECTED_LAYOUT_STORAGE = "OUTGOING_INVOICE_FOR_CODA_SELECTED_LAYOUT_STORAGE";
export const OPEN_BUDGET_PER_OPERATIONAL_FILE_STORAGE = "OPEN_BUDGET_PER_OPERATIONAL_FILE_STORAGE";
export const BUDGET_DETAILS_PER_OPERATIONAL_FILE = "BUDGET_DETAILS_PER_OPERATIONAL_FILE";
export const BUDGET_DETAILS_PER_QUOTE = "BUDGET_DETAILS_PER_QUOTE";
export const YEARLY_BUDGET_OVERVIEW = "YEARLY_BUDGET_OVERVIEW";
export const QUOTE_STORAGE = "QUOTE_STORAGE";
export const QUOTE_COLUMNS_STORAGE = "QUOTE_COLUMNS_STORAGE";
export const QUOTE_SELECTED_LAYOUT_STORAGE = "QUOTE_SELECTED_LAYOUT_STORAGE";
export const PROSPECT_STORAGE = "PROSPECT_STORAGE";
export const PROSPECT_COLUMNS_STORAGE = "PROSPECT_COLUMNS_STORAGE";
export const OPERATIONAL_FILE_OVERVIEW = "OPERATIONAL_FILE_OVERVIEW";
export const OUTGOING_INVOICES_PER_OPERATIONAL_FILE = "OUTGOING_INVOICES_PER_OPERATIONAL_FILE";
export const BUDGET_PER_ACTIVITY_CODE = "BUDGET_PER_ACTIVITY_CODE";
export const OPERATIONAL_STEPS_STORAGE = "OPERATIONAL_STEPS_STORAGE";
export const OPERATIONAL_SUMMARY_PER_USER = "OPERATIONAL_SUMMARY_PER_USER";
export const OPERATIONAL_EDIT_STORAGE = "OPERATIONAL_EDIT_STORAGE";
export const QUOTES_COSTS_COLUMNS_STORAGE = "QUOTES_COSTS_COLUMNS_STORAGE";
export const COSTS_COLUMNS_STORAGE = "COSTS_COLUMNS_STORAGE";
export const LINKED_CREDIT_NOTES = "LINKED_CREDIT_NOTES_COLUMNS_STORAGE";
export const QUOTES_REVENUES_COLUMNS_STORAGE = "QUOTES_REVENUES_COLUMNS_STORAGE";
export const REVENUES_COLUMNS_STORAGE = "REVENUES_COLUMNS_STORAGE";
export const GOODS_DETAILS_STORAGE = "GOODS_DETAILS_STORAGE";
export const OPERATIONAL_FILE_GOODS_STORAGE = "OPERATIONAL_FILE_GOODS_STORAGE";
export const OPERATIONAL_FILE_GOODS_COLUMNS_STORAGE = "OPERATIONAL_FILE_GOODS_COLUMNS_STORAGE";
export const OPERATIONAL_FILE_CONTAINERS_COLUMNS_STORAGE = "OPERATIONAL_FILE_CONTAINERS_COLUMNS_STORAGE";
export const OPERATIONAL_FILE_PARTIES_COLUMNS_STORAGE = "OPERATIONAL_FILE_PARTIES_COLUMNS_STORAGE";
export const OPERATIONAL_FILE_CUSTOMS_COLUMNS_STORAGE = "OPERATIONAL_FILE_CUSTOMS_COLUMNS_STORAGE";
export const OPERATIONAL_FILE_TRANSPORTS_COLUMNS_STORAGE = "OPERATIONAL_FILE_TRANSPORTS_COLUMNS_STORAGE";
export const OPERATIONAL_FILE_BOOKING_COLUMNS_STORAGE = "OPERATIONAL_FILE_BOOKING_COLUMNS_STORAGE";
export const OPERATIONAL_FILE_SHIPPING_INSTRUCTIONS_COLUMNS_STORAGE = "OPERATIONAL_FILE_SHIPPING_INSTRUCTIONS_COLUMNS_STORAGE";
export const OPERATIONAL_FILE_TRANSPORT_STOPS_COLUMNS_STORAGE = "OPERATIONAL_FILE_TRANSPORT_STOPS_COLUMNS_STORAGE";
export const OPERATIONAL_FILE_COURIER_INSTRUCTIONS_COLUMNS_STORAGE = "OPERATIONAL_FILE_COURIER_INSTRUCTIONS_COLUMNS_STORAGE";
export const QUOTES_CONTAINERS_COLUMNS_STORAGE = "QUOTES_CONTAINERS_COLUMNS_STORAGE";
export const QUOTES_GOODS_COLUMNS_STORAGE = "QUOTES_GOODS_COLUMNS_STORAGE";
export const QUOTES_PARTIES_COLUMNS_STORAGE = "QUOTES_PARTIES_COLUMNS_STORAGE";
export const INCOMING_INVOICE_LINE_COLUMNS_STORAGE = "INCOMING_INVOICE_LINE_COLUMNS_STORAGE";
export const OUTGOING_INVOICE_LINE_COLUMNS_STORAGE = "OUTGOING_INVOICE_LINE_COLUMNS_STORAGE";
export const COMPANY_STORAGE = "COMPANY_STORAGE";
export const COMPANY_COLUMNS_STORAGE = "COMPANY_COLUMNS_STORAGE";