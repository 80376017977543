import { SilentRequest } from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';
import { Injectable } from '@angular/core';
import { forkJoin, from, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(private msalService: MsalService) { }

  private isInitialized = false;

  private initializeMsal(): Observable<void> {
    if (this.isInitialized) {
      return of(undefined);
    }

    return from(this.msalService.initialize()).pipe(
      tap(() => (this.isInitialized = true))
    );
  }

  // request both tokens
  public getBothTokens(): Observable<{ fordeskAccessToken: string; userManagementToken: string }> {
    const fordeskRequest: SilentRequest = {
      scopes: [environment.authentication.fordeskApiScope],
      redirectUri: environment.authentication.redirectUrl,
    };

    const userManagementRequest: SilentRequest = {
      scopes: [environment.authentication.userManagementScope],
      redirectUri: environment.authentication.redirectUrl,
    };

    return this.initializeMsal().pipe(
      switchMap(() =>
        forkJoin({
          fordeskAccessToken: this.msalService.acquireTokenSilent(fordeskRequest).pipe(
            map((result) => result.accessToken)
          ),
          userManagementToken: this.msalService.acquireTokenSilent(userManagementRequest).pipe(
            map((result) => result.accessToken)
          ),
        })
      ),
      catchError((error) => {
        this.msalService.logout();
        return throwError(() => new Error());
      })
    );
  }

  //request fordeskAccess token
  public getFordeskToken(): Observable<string> {
    const request: SilentRequest = {
      scopes: [environment.authentication.fordeskApiScope],
      redirectUri: environment.authentication.redirectUrl,
    };
    return this.initializeMsal().pipe(
      switchMap(() =>
        this.msalService.acquireTokenSilent(request).pipe(
          map((result) => result.accessToken)
        )
      )
    );
  }
}
