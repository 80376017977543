import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { UserService } from "../../../../../core/auth/fordesk/services/user.service";
import { AuthUser } from "../../../../../core/models/user.model";
import { filter } from "rxjs";
import { Router } from "@angular/router";

@Component({
  selector: "m-user-profile",
  templateUrl: "./user-profile.component.html",
  changeDetection: ChangeDetectionStrategy.Default
})
export class UserProfileComponent implements OnInit {
  @HostBinding("class")
  // tslint:disable-next-line:max-line-length
  public classes: string = "m-nav__item m-topbar__user-profile m-topbar__user-profile--img m-dropdown m-dropdown--medium m-dropdown--arrow m-dropdown--header-bg-fill m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light";

  @HostBinding("attr.m-dropdown-toggle")
  public attrDropdownToggle: string = "click";

  @Input() public avatar: string = "./assets/app/media/img/users/user4.jpg";
  @Input() public avatarBg: SafeStyle = "";

  @ViewChild("mProfileDropdown", { static: false }) public mProfileDropdown: ElementRef;

  public firstname: string;
  public lastname: string;
  public email: string;
  private userId: string;
  constructor(
    private sanitizer: DomSanitizer,
    private userService: UserService,
    public cd: ChangeDetectorRef,
    private router: Router,
  ) { }

  public ngOnInit(): void {
    if (!this.avatarBg) {
      this.avatarBg = this.sanitizer.bypassSecurityTrustStyle("url(./assets/app/media/img/misc/user_profile_bg.jpg)");
    }

    this.userService.authUser$.asObservable()
      .pipe(filter((res: AuthUser) => !!res))
      .subscribe((res: AuthUser) => {
        this.email = res.email;
        this.firstname = res.givenName;
        this.lastname = res.surname;
        this.userId = res.id;
        this.cd.detectChanges();
      });
  }

  public logout() {
    this.userService.logout();
  }

  public openProfile() {
    this.router.navigate(['config', 'users', this.userId, 'details']);
  }
}
