/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DateRange } from './dateRange';

export interface IncomingInvoiceSearchCondition { 
    pageSize?: number;
    page?: number;
    readonly listSortDirection?: IncomingInvoiceSearchCondition.ListSortDirectionEnum;
    sortDirection?: string;
    sortProperty?: string;
    fileReference?: Array<string>;
    invoiceNumber?: Array<string>;
    accountingNumber?: Array<string>;
    commentCode?: Array<string>;
    amountInclVat?: Array<number>;
    currency?: Array<number>;
    status?: Array<IncomingInvoiceSearchCondition.StatusEnum>;
    supplierName?: Array<string>;
    issueDate?: Array<DateRange>;
    bookedOperationalFileNumber?: Array<string>;
    type?: Array<string>;
    paid?: Array<IncomingInvoiceSearchCondition.PaidEnum>;
    sepaExported?: Array<IncomingInvoiceSearchCondition.SepaExportedEnum>;
    paymentReference?: Array<string>;
}
export namespace IncomingInvoiceSearchCondition {
    export type ListSortDirectionEnum = 0 | 1;
    export const ListSortDirectionEnum = {
        NUMBER_0: 0 as ListSortDirectionEnum,
        NUMBER_1: 1 as ListSortDirectionEnum
    };
    export type StatusEnum = '1' | '2' | '3' | '4' | '5';
    export const StatusEnum = {
        _1: '1' as StatusEnum,
        _2: '2' as StatusEnum,
        _3: '3' as StatusEnum,
        _4: '4' as StatusEnum,
        _5: '5' as StatusEnum
    };
    export type PaidEnum = '0' | '1';
    export const PaidEnum = {
        _0: '0' as PaidEnum,
        _1: '1' as PaidEnum
    };
    export type SepaExportedEnum = '0' | '1';
    export const SepaExportedEnum = {
        _0: '0' as SepaExportedEnum,
        _1: '1' as SepaExportedEnum
    };
}