import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PagesComponent } from "./pages.component";
import { ActionComponent } from "./header/action/action.component";
import { ProfileComponent } from "./header/profile/profile.component";
import { NotfoundComponent } from "./error/notfound/notfound.component";
import { DashboardSectionGuard } from "../../core/auth/authguards/dashboard-section.guard";
import { DefaultPageRoutingComponent } from "./default-page-routing.component";
import { PageReloadComponent } from "./page-reload.component";
import { ExactOnlineIntegrationAuthCallbackComponent } from "./auth-callbacks/exact-online-integration-auth-callback/exact-online-integration-auth-callback.component";
import { MsalGuard } from "@azure/msal-angular";

const routes: Routes = [
  {
    path: "config/integrations/exact-online/auth",
    component: ExactOnlineIntegrationAuthCallbackComponent
  },
  {
    path: "",
    component: PagesComponent,
    canActivate: [MsalGuard],
    canActivateChild: [MsalGuard],
    children: [
      {
        path: "",
        pathMatch: "full",
        component: DefaultPageRoutingComponent,
      },
      {
        path: "operational",
        loadChildren: () => import("./operational/operational.module").then(o => o.OperationalModule)
      },
      {
        path: "finance",
        loadChildren: () => import("./finance/finance.module").then(o => o.FinanceModule)
      },
      {
        path: "masterdata",
        loadChildren: () => import("./masterdata/masterdata.module").then(o => o.MasterDataModule)
      },
      {
        path: "reporting",
        loadChildren: () => import("./reporting/reporting.module").then(o => o.ReportingModule)
      },
      {
        path: "prospects",
        loadChildren: () => import("./sales/prospects/prospects.module").then(o => o.ProspectsModule)
      },
      {
        path: "quotes",
        loadChildren: () => import("./quotes/quotes.module").then(o => o.QuotesModule)
      },
      {
        path: "notifications",
        loadChildren: () => import("./notifications/notifications.module").then(o => o.NotificationsModule)
      },
      {
        path: "dashboards",
        canActivate: [DashboardSectionGuard],
        loadChildren: () => import("./dashboards/dashboards.module").then(o => o.DashboardsModule)
      },
      {
        path: "config",
        loadChildren: () => import("./config/config.module").then(o => o.ConfigModule)
      },
      {
        path: "header/actions",
        component: ActionComponent
      },
      {
        path: "profile",
        component: ProfileComponent
      },
      {
        path: "reload",
        component: PageReloadComponent
      },
    ]
  },
  {
    path: "not-found",
    component: NotfoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule {
}
