import { Injectable } from "@angular/core";
import { Subject, Observable, of } from "rxjs";
import { debounceTime, distinctUntilChanged, tap, switchMap, map, catchError } from "rxjs/operators";
import { PlaceDTO, PlacesService } from "../services/swagger-gen/fordesk";


@Injectable({ providedIn: "root" })
export class PlaceLookupHelper {
  private debounceInputTime: number = 400;
  private maxLength: number = 3950;

  constructor() { }

  public getPlacesLookupWithCountry(service: PlacesService, input: Subject<string>, countryId: number, onStart: Function, onComplete: Function): Observable<PlaceDTO[]> {
    return input.pipe(
      debounceTime(this.debounceInputTime),
      distinctUntilChanged(),
      switchMap((term: string) => {
        if (term && term.length > 2 && term.length < this.maxLength) {
          onStart();
          return service
            .searchInCountry(term, countryId)
            .pipe(map(res => res && res.results ? res.results : []),
              catchError(() => of<PlaceDTO[]>([])), // empty list on error
              tap(() => onComplete?.())
            );
        } else {
          onComplete?.();
          return of<PlaceDTO[]>([]);
        }
      })
    );
  }

  public getPlacesLookup(service: PlacesService, input: Subject<string>, onStart: Function, onComplete: Function): Observable<PlaceDTO[]> {
    return input.pipe(
      debounceTime(this.debounceInputTime),
      distinctUntilChanged(),
      switchMap((term: string) => {
        if (term && term.length > 2 && term.length < this.maxLength) {
          onStart();
          return service
            .findPlacesByName(term)
            .pipe(map(response => response ? response.results : []),
              catchError(_ => of<PlaceDTO[]>([])),
              tap(() => onComplete?.())
            );
        } else {
          onComplete?.();
          return of<PlaceDTO[]>([]);
        }
      })
    );
  }
}
