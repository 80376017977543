import { Component, OnInit, HostBinding, Input, AfterViewInit, ChangeDetectionStrategy } from "@angular/core";
import { LayoutConfigService } from "../../../../core/services/layout-config.service";
import * as objectPath from "object-path";
import { TenantSynchronizationService } from "../../../../core/services/tenants-synchronization.service";
import { UserPermissionService } from "../../../../core/auth/fordesk/services/user.permissions.service";
import { filter, switchMap, take, takeUntil } from "rxjs";

@Component({
  selector: "m-topbar",
  templateUrl: "./topbar.component.html",
  changeDetection: ChangeDetectionStrategy.Default
})
export class TopbarComponent implements OnInit, AfterViewInit {
  @HostBinding("id") public id: string = "m_header_nav";
  @HostBinding("class")
  public classes: string = "m-stack__item m-stack__item--fluid m-header-head";

  @Input() public searchType: any;

  public hasNotificationsPermission: boolean = false;

  constructor(
    private layoutConfigService: LayoutConfigService,
    private permissionsService: UserPermissionService,
    public tenantSynchronizationService: TenantSynchronizationService
  ) {
    this.layoutConfigService.onLayoutConfigUpdated$.subscribe(model => {
      const config = model.config;
      this.searchType = objectPath.get(config, "header.search.type");
    });
  }

  public ngOnInit(): void {
    this.permissionsService.getPermissionsObservable$()
      .pipe(filter(x => x?.length > 0),
        take(1),
        switchMap(() => {
          return this.permissionsService.userHasNotificationsPermission$()
            .pipe(
              take(1))
        }))
      .subscribe(hasRole => this.hasNotificationsPermission = hasRole)
  }

  public ngAfterViewInit(): void { }
}
