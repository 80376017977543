/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface QuoteFileWidgetDataDTO { 
    id?: number;
    fileNumber?: string;
    fileType?: QuoteFileWidgetDataDTO.FileTypeEnum;
    shipmentType?: QuoteFileWidgetDataDTO.ShipmentTypeEnum;
    transportType?: QuoteFileWidgetDataDTO.TransportTypeEnum;
    deadline?: Date;
    expirationStatus?: QuoteFileWidgetDataDTO.ExpirationStatusEnum;
    warehousingNeeded?: boolean;
}
export namespace QuoteFileWidgetDataDTO {
    export type FileTypeEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6;
    export const FileTypeEnum = {
        NUMBER_0: 0 as FileTypeEnum,
        NUMBER_1: 1 as FileTypeEnum,
        NUMBER_2: 2 as FileTypeEnum,
        NUMBER_3: 3 as FileTypeEnum,
        NUMBER_4: 4 as FileTypeEnum,
        NUMBER_5: 5 as FileTypeEnum,
        NUMBER_6: 6 as FileTypeEnum
    };
    export type ShipmentTypeEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6;
    export const ShipmentTypeEnum = {
        NUMBER_0: 0 as ShipmentTypeEnum,
        NUMBER_1: 1 as ShipmentTypeEnum,
        NUMBER_2: 2 as ShipmentTypeEnum,
        NUMBER_3: 3 as ShipmentTypeEnum,
        NUMBER_4: 4 as ShipmentTypeEnum,
        NUMBER_5: 5 as ShipmentTypeEnum,
        NUMBER_6: 6 as ShipmentTypeEnum
    };
    export type TransportTypeEnum = 0 | 1 | 2 | 3;
    export const TransportTypeEnum = {
        NUMBER_0: 0 as TransportTypeEnum,
        NUMBER_1: 1 as TransportTypeEnum,
        NUMBER_2: 2 as TransportTypeEnum,
        NUMBER_3: 3 as TransportTypeEnum
    };
    export type ExpirationStatusEnum = 0 | 1 | 2 | 3 | 4;
    export const ExpirationStatusEnum = {
        NUMBER_0: 0 as ExpirationStatusEnum,
        NUMBER_1: 1 as ExpirationStatusEnum,
        NUMBER_2: 2 as ExpirationStatusEnum,
        NUMBER_3: 3 as ExpirationStatusEnum,
        NUMBER_4: 4 as ExpirationStatusEnum
    };
}