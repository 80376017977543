export enum AvailabilityType {
  Unavailable,
  Available,
}

export class AuthUser {

  givenName: string = '';

  surname: string = '';

  email: string = '';

  displayName: string = '';

  id: string = '';

  properties: Record<string, string> = {};

  constructor(
    givenName: string,
    id: string,
    surname: string,
    displayName: string,
    email: string,
    properties: Record<string, string>,
  ) {
    this.givenName = givenName;
    this.id = id;
    this.properties = properties;
    this.surname = surname;
    this.displayName = displayName;
    this.email = email;
  }
}
