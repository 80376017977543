/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface SealNumberType { 
    rowCreatedTimestamp?: Date;
    lastRowModificationTimestamp?: Date;
    isDeleted?: boolean;
    id?: number;
    masterDataStatus?: SealNumberType.MasterDataStatusEnum;
    timeOfBlocking?: Date;
    code?: string;
    description?: string;
    value?: SealNumberType.ValueEnum;
    sortValue?: number;
}
export namespace SealNumberType {
    export type MasterDataStatusEnum = 0 | 1;
    export const MasterDataStatusEnum = {
        NUMBER_0: 0 as MasterDataStatusEnum,
        NUMBER_1: 1 as MasterDataStatusEnum
    };
    export type ValueEnum = 0 | 1 | 2 | 3 | 4;
    export const ValueEnum = {
        NUMBER_0: 0 as ValueEnum,
        NUMBER_1: 1 as ValueEnum,
        NUMBER_2: 2 as ValueEnum,
        NUMBER_3: 3 as ValueEnum,
        NUMBER_4: 4 as ValueEnum
    };
}