/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface OperationalFileStepsWidgetDataDTO { 
    id?: number;
    operationalFileId?: number;
    operationalFileNumber?: string;
    description?: string;
    deadline?: Date;
    expirationStatus?: OperationalFileStepsWidgetDataDTO.ExpirationStatusEnum;
}
export namespace OperationalFileStepsWidgetDataDTO {
    export type ExpirationStatusEnum = 0 | 1 | 2 | 3 | 4;
    export const ExpirationStatusEnum = {
        NUMBER_0: 0 as ExpirationStatusEnum,
        NUMBER_1: 1 as ExpirationStatusEnum,
        NUMBER_2: 2 as ExpirationStatusEnum,
        NUMBER_3: 3 as ExpirationStatusEnum,
        NUMBER_4: 4 as ExpirationStatusEnum
    };
}