import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, switchMap } from 'rxjs';
import { MsalBroadcastService, MsalInterceptor, MsalService } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';
import { Location } from '@angular/common';
import { TokenService } from '../services/token.service';
import { AuthConfig } from '../configuration/auth.configuration';
import { environment } from '../../../../../environments/environment';

export const BYPASS_INTERCEPTOR = new HttpContextToken<boolean>(() => false);

@Injectable()
export class DfmInterceptor extends MsalInterceptor implements HttpInterceptor {
  constructor(
    authService: MsalService,
    location: Location,
    msalBroadcastService: MsalBroadcastService,
    private tokensService: TokenService
  ) {
    super(
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map(AuthConfig.protectedApis.map((apis) => [apis.url, [apis.scope]])),
        authRequest: {
          redirectUri: `${environment.authentication.redirectUrl}`,
        },
      },
      authService,
      location,
      msalBroadcastService,
      document,
    );
  }

  override intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.context.get(BYPASS_INTERCEPTOR)) {
      return next.handle(request);
    }

    return this.tokensService.getBothTokens().pipe(
      map(({ fordeskAccessToken, userManagementToken }) => {
        let modifiedRequest = request;
        //Set both tokens when we send request to the fordesk api
        if (request.url.startsWith(environment.forDeskApiConfig.host)) {
          modifiedRequest = request.clone({
            headers: request.headers
              .set('Authorization', `Bearer ${fordeskAccessToken}`)
              .set('Authorization-User-Management', `Bearer ${userManagementToken}`),

          });
        }
        //Set authorization token when we send request to the user MGT from UI
        else if (request.url.startsWith(environment.authentication.userManagementApiUrl)) {
          modifiedRequest = request.clone({
            headers: request.headers
              .set('Authorization', `Bearer ${userManagementToken}`)
          })
        }

        return modifiedRequest;
      }),
      switchMap((modifiedRequest) => next.handle(modifiedRequest))
    );
  }
}
