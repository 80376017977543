import { ChangeDetectorRef, Directive } from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { EntityDetailsComponent } from "../../../../../core/components/abstractions/entity-details.component";
import { PlaceLookupHelper } from "../../../../../core/helpers/place-lookup-helper";
import { TranslateService } from "@ngx-translate/core";
import { ToasterService } from "../../../../layout/toaster/toaster.service";
import { UntypedFormBuilder } from "@angular/forms";
import { LookupDTO, MasterdataLookupDTO, MasterDataService, PlaceDTO, PlacesService } from "../../../../../core/services/swagger-gen/fordesk";
import { UnsavedChangesService } from "../../../../../core/services/helpers/unsaved-changes.service";
import { MasterDataEntityTypeEnum } from "../../../../../core/enumerations/master-data-entity-type.enum";
import { map, switchMap, takeUntil, tap } from "rxjs/operators";
import { ConfirmDialogData } from "../../../../../core/models/dialogs/confirm-dialog-data";
import { ConfirmDialog } from "../../../../dialogs/confirm/confirm.component";
import { MatDialog } from "@angular/material/dialog";

@Directive()
export abstract class CompanyDetailsBaseComponent extends EntityDetailsComponent {
  protected constructor(
    protected masterDataService: MasterDataService,
    protected dialog: MatDialog,
    protected route: ActivatedRoute,
    protected placesService: PlacesService,
    protected placeLookupHelper: PlaceLookupHelper,
    protected router: Router,
    protected translate: TranslateService,
    protected toaster: ToasterService,
    protected fb: UntypedFormBuilder,
    protected cdRef: ChangeDetectorRef,
    unsavedChangedService: UnsavedChangesService
  ) {
    super(route, unsavedChangedService);
  }

  public countries: MasterdataLookupDTO[] = [];
  public languages: LookupDTO[] = [];
  public cachableTypes: string[] = ["placeId", "countryId"];

  public places$: Observable<PlaceDTO[]>;
  public placeLoading: boolean = false;
  public placeInput$: Subject<string> = new Subject<string>();

  public countrySelected(selectedCountryId: number) {
    if (selectedCountryId) {
      this.detailsForm.controls.placeId.enable();
    } else {
      this.detailsForm.controls.placeId.disable();
    }
    this.setFormValue("placeId", null);
    this.setFormValue("zipCode", null);
    this.places$ = this.createPlacesLookup();
  }

  public placeSelected(selectedPlace: PlaceDTO) {
    if (selectedPlace) {
      const countryId = this.getFormValue("countryId") as number;

      if (selectedPlace.countryId !== countryId) {
        this.setFormValue("countryId", selectedPlace.countryId);
      }

      const zipCode = selectedPlace.zipCode;
      if (zipCode) {
        this.setFormValue("zipCode", zipCode);
      }
    }
  }

  public createPlacesLookup() {
    return this.placeLookupHelper.getPlacesLookupWithCountry(
      this.placesService,
      this.placeInput$,
      this.detailsForm.value.countryId ? this.detailsForm.value.countryId : null,
      () => {
        this.placeLoading = true;
        this.cdRef.detectChanges();
      },
      () => {
        this.placeLoading = false;
        this.cdRef.detectChanges();
      }).pipe(this.filterOutBlockedTypesPipe("placeId"),
        takeUntil(this.unsubscribe));
  }

  public checkReferences(id: number, type: MasterDataEntityTypeEnum): Observable<boolean> {
    if (!this.isDirty) {
      return of(true);
    }

    return this.masterDataService.checkReferences(id, type).pipe(
      takeUntil(this.unsubscribe),
      switchMap(isUsed => {
        if (isUsed) {
          const enumName = MasterDataEntityTypeEnum[type].toString().split(/(?=[A-Z])/).join(" ");

          const dialogData: ConfirmDialogData = {
            dialogTitle: this.translate.instant("MASTERDATA.CONFIRM_OPERATION"),
            confirmationText: this.translate.instant("MASTERDATA.ENTITY_USES_MESSAGE", { entityName: enumName }),
          };

          return this.dialog.open(ConfirmDialog, { disableClose: true, data: dialogData }).afterClosed().pipe(
            takeUntil(this.unsubscribe),
            map(data => data.isConfirmed),
            tap(isConfirmed => {
              if (!isConfirmed) {
                this.unsavedChangedService.backToPage(this);
              }
            })
          );
        }

        return of(true);
      })
    );
  }
}
