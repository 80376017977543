export const of_goods_displayed_columns_locale = {
  numberOfCollis: "# collis",
  packageType: "Package type",
  description: "Description",
  grossWeight: "Gross weight",
  chargeableWeight: "Chargeable weight",
  netWeight: "Net weight",
  cubage: "Cubage",
  length: "Length",
  width: "Width",
  height: "Height"
};
