export const quote_goods_locale = {
  ADD: "Add goods",
  DETAILS: "Goods details",
  GOODS_LIST: "Goods",
  DESCRIPTION: "Description",
  GROSS_WEIGHT: "Gross weight",
  PACKAGE_TYPE: "Package type",
  NR_OF_COLLIS: "Nr. of collis (outer)",
  NR_OF_COLLIS_COLUMN: "# collis",
  NR_OF_COLLIS_COMPACT: "collis",
  VALIDATION: {
    DESCRIPTION_OR_GROSS_WEIGHT_REQUIRED: "Either a description or gross weight are required.",
    MIN_GROSS_WEIGHT: "Gross weight should be greater than 0."
  },
  MESSAGES: {
    COPIED: "The selected goods have been copied {{times}} times.",
    QUOTE_FILE_GOODS_MAX_QUANTITY_EXCEEDED: "Quote files cannot contain more than 500 goods."
  },
  COPY_GOODS_TIMES: "How many copies of these goods would you like?",
  COPY_GOODS_TITLE: "Goods copy"
};
