import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderComponent } from "./header/header.component";
import { AsideLeftComponent } from "./aside/aside-left.component";
import { FooterComponent } from "./footer/footer.component";
import { SubheaderComponent } from "./subheader/subheader.component";
import { BrandComponent } from "./header/brand/brand.component";
import { MenuSectionComponent } from "./aside/menu-section/menu-section.component";
import { TopbarComponent } from "./header/topbar/topbar.component";
import { UserProfileComponent } from "./header/topbar/user-profile/user-profile.component";
import { MenuHorizontalComponent } from "./header/menu-horizontal/menu-horizontal.component";
import { AsideRightComponent } from "./aside/aside-right/aside-right.component";
import { NotificationComponent } from "./header/topbar/notification/notification.component";
import { SharedModule } from "../shared/shared.module";
import { ReleasePreviewComponent } from './header/topbar/release-preview/release-preview.component';
import { AllowedTenantsComponent } from "./header/allowed-tenants/allowed-tenants.component";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SubheaderComponent,
    BrandComponent,

    // topbar components
    TopbarComponent,
    NotificationComponent,
    UserProfileComponent,

    // aside left menu components
    AsideLeftComponent,
    MenuSectionComponent,

    // horizontal menu components
    MenuHorizontalComponent,

    // allowed tenants selector
    AllowedTenantsComponent,

    // aside right component
    AsideRightComponent,

    ReleasePreviewComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SubheaderComponent,
    BrandComponent,

    // topbar components
    TopbarComponent,
    UserProfileComponent,

    // aside left menu components
    AsideLeftComponent,
    // MenuSectionComponent,

    // horizontal menu components
    MenuHorizontalComponent,

    // aside right component
    AsideRightComponent
  ],
  providers: [],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule
  ]
})
export class LayoutModule { }
