export const new_quotes_locale = {
  QUOTE: "Quote",
  NEW_QUOTE_FOR_CUSTOMER: "New quote for a customer",
  NEW_QUOTE_FOR_PROSPECT: "New quote for a prospect",
  NEW_QUOTE_ADDED: "New quote has been added.",
  DOCUMENT_DATE: "Document date",
  VALID_DATE: "Valid date",
  CUSTOMER_REFERENCE: "Customer reference",
  FILE_TYPE: "File type",
  TRANSPORT_TYPE: "Transport type",
  SHIPMENT_TYPE: "Shipment type",
  CUSTOMER: "Customer",
  PROSPECT: "Prospect",
  DEADLINE: "Deadline",
  CURRENCY: "Currency",
  EXCHANGE_RATE: "Exchange rate",
  PORT_OF_LOADING: "Port of loading",
  PORT_OF_DISCHARGE: "Port of discharge",
  INVOICE_CUSTOMER: "Invoice customer",
  COMPANY: "Company",
  MESSAGES: {
    CONFIRM_UPDATE: "Are you sure you want to {{status}} this/these quote(s)?",
    CONFIRM_NAVIGATE_TO_OPERATIONAL_FILE: "Operational file {{fileNumber}} has been created, would you like to open it?"
  },
  EXCHANGE_RATE_CURRENCY_CHANGED_WARNING: "After changing the currency or exchange rate, the quote file must be saved to recalculate costs and revenues. Changing status or costs/revenues is locked until changes are saved.",
  VAT_CHANGED_WARNING: "Please save the changes to allow for correct VAT calculation and further budget editing.",
  CUSTOMS_AGENT: "Customs agent",
  DOCUMENT_DATE_BEFORE_VALID_UNTIL: "Document date should be before valid until date.",
  DEPARTMENT: "Department",
  DOCUMENTS: "Documents",
  MARK_AS_CURRENT_VALID_QUOTE: "mark as current valid quote",
  MARK_SELECTED_AS_CURRENT_QUOTE_DOCUMENT: "Do you want to mark the selected file as current valid quote document?",
  MARK_ADDED_AS_CURRENT_QUOTE_DOCUMENT: "Do you want to mark the added file as current valid quote document?",
  MARK_AS_CURRENT_QUOTE_DOCUMENT_TITLE: "Mark as current valid quote document",
  ADD_FOR_CUSTOMER: "add for a customer",
  ADD_FOR_PROSPECT: "add for a prospect",
};

export const list_quotes_locale = {
  QUOTE_FILES: "Quote files",
  FILENUMBER: "Accounting number",
  PLACEOFRECEIPT: "Place of receipt",
  PLACEOFDESTINATION: "Place of destination",
  CUSTOMER_INVOICED_CUSTOMER_PROSPECT: "Customer / Invoice customer or Prospect",
  CUSTOMER: "Customer",
  INVOICE_CUSTOMER: "Invoice customer",
  CONTAINERS_GOODS: "Containers / Goods ",
  CONTAINERS: "Containers",
  GOODS: "Goods",
  PORTS: "P.o.L / P.o.D",
  POL: "Port of loading",
  POD: "Port of discharge",
  DATES: "Document date / Valid date",
  DOCUMENT_DATE: "Document date",
  DEADLINE_DATE: "Deadline date",
  VALID_DATE: "Valid date",
  TOTAL_EXCL_VAT: "Total excluding VAT",
  TOTAL_INCL_VAT: "Total including VAT",
  VAT: "VAT amount",
  INTERNAL_SUBJECT: "Internal subject",
  REMARKS: "Remarks",
  CARRIER: "Carrier",
  CUSTOMER_REFERENCE: "Customer reference",
  RESULT: "Result",
  CREATED_BY: "Created by",
  CREATION_DATE: "Creation date",
  NEW: "New",
  SENT: "Sent",
  CANCELLED: "Cancelled",
  REJECTED: "Rejected",
  APPROVED: "Approved",
  APPROVE_WITH_DATE_TITLE: "Approve quote file(s)",
  APPROVE_WITH_DATE: "Choose an optional date for the new operational file(s).",
  APPROVE_PROSPECT_TITLE: "Approve prospect quote file(s)",
  APPROVE_PROSPECT: "This is a quote for a prospect. If you want to approve, you need to create a customer. Do you want to proceed?",
  APPROVE_PROSPECT_SAVE_TITLE: "Approve prospect quote file(s)",
  APPROVE_PROSPECT_SAVE: "The Prospect information will be replaced with the Customer data in the Quote. Do you want to proceed?",
  COPY_QUOTES: "Copy quote files",
  PROSPECT: "Prospect",

  FILTER: {
    CURRENCY: "Currency",
    SHIPMENTTYPE: "Shipment type",
    FILETYPE: "File type",
    TRANSPORTTYPE: "Transport type",
    PORTOFLOADING: "Port of loading",
    PORTOFDISCHARGE: "Port of discharge",
    DOCUMENTDATE: "Document date",
    DEADLINEDATE: "Deadline date",
    VALIDDATE: "Valid date",
    ACCOUNTINGNUMBER: "Accounting number",
    CUSTOMER: "Customer",
    INVOICECUSTOMER: "Invoice customer",
    PROSPECT: "Prospect",
    INTERNALSUBJECT: "Internal subject",
    STATUS: "Status",
    PLACEOFDESTINATION: "Place of destination",
    PLACEOFRECEIPT: "Place of receipt",
    CARRIER: "Carrier",
    CUSTOMERREFERENCE: "Customer reference",
    CREATEDBY: "Created by"
  }
};

export const quote_revenues_locale = {
  QUOTE_REVENUES: "Revenues",
  ADD: "Add revenue",
  DETAILS: "Revenue details",
  NAME: "Name",
  PARTY_NAME: "Party name",
  QUANTITY: "Quantity",
  PRICE: "Price",
  TOTAL: "Total",
  TOTAL_EXCL_VAT: "Total excl. VAT",
  TOTAL_INCL_VAT: "Total incl. VAT",
  TOTAL_INCL_VAT_CONVERTED: "Total",
  COPY_TO_COSTS: "copy to costs",
  VAT: "Vat",
  QUOTE_REVENUE: "Revenue",
  UOM: "Unit of measurement",
  TOTAL_EURO: "Total (€)",
  RESULT_EXCL_VAT: "Result excluding VAT",
  RESULT_INCL_VAT: "Result including VAT",
  PARTY: "Party"
};
