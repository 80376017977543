/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DateRange } from './dateRange';

export interface QuoteFileSearchCondition { 
    pageSize?: number;
    page?: number;
    readonly listSortDirection?: QuoteFileSearchCondition.ListSortDirectionEnum;
    sortDirection?: string;
    sortProperty?: string;
    accountingNumber?: Array<string>;
    customer?: Array<string>;
    invoiceCustomer?: Array<string>;
    prospect?: Array<string>;
    documentDate?: Array<DateRange>;
    validDate?: Array<DateRange>;
    deadlineDate?: Array<DateRange>;
    createdBy?: Array<string>;
    status?: Array<QuoteFileSearchCondition.StatusEnum>;
    internalSubject?: Array<string>;
    portOfLoading?: Array<string>;
    portOfDischarge?: Array<string>;
    placeOfReceipt?: Array<string>;
    placeOfDestination?: Array<string>;
    carrier?: Array<string>;
    customerReference?: Array<string>;
    currency?: Array<number>;
    fileType?: Array<QuoteFileSearchCondition.FileTypeEnum>;
    shipmentType?: Array<QuoteFileSearchCondition.ShipmentTypeEnum>;
    transportType?: Array<QuoteFileSearchCondition.TransportTypeEnum>;
}
export namespace QuoteFileSearchCondition {
    export type ListSortDirectionEnum = 0 | 1;
    export const ListSortDirectionEnum = {
        NUMBER_0: 0 as ListSortDirectionEnum,
        NUMBER_1: 1 as ListSortDirectionEnum
    };
    export type StatusEnum = '0' | '1' | '2' | '3' | '4';
    export const StatusEnum = {
        _0: '0' as StatusEnum,
        _1: '1' as StatusEnum,
        _2: '2' as StatusEnum,
        _3: '3' as StatusEnum,
        _4: '4' as StatusEnum
    };
    export type FileTypeEnum = '0' | '1' | '2' | '3' | '4' | '5' | '6';
    export const FileTypeEnum = {
        _0: '0' as FileTypeEnum,
        _1: '1' as FileTypeEnum,
        _2: '2' as FileTypeEnum,
        _3: '3' as FileTypeEnum,
        _4: '4' as FileTypeEnum,
        _5: '5' as FileTypeEnum,
        _6: '6' as FileTypeEnum
    };
    export type ShipmentTypeEnum = '0' | '1' | '2' | '3' | '4' | '5' | '6';
    export const ShipmentTypeEnum = {
        _0: '0' as ShipmentTypeEnum,
        _1: '1' as ShipmentTypeEnum,
        _2: '2' as ShipmentTypeEnum,
        _3: '3' as ShipmentTypeEnum,
        _4: '4' as ShipmentTypeEnum,
        _5: '5' as ShipmentTypeEnum,
        _6: '6' as ShipmentTypeEnum
    };
    export type TransportTypeEnum = '0' | '1' | '2' | '3';
    export const TransportTypeEnum = {
        _0: '0' as TransportTypeEnum,
        _1: '1' as TransportTypeEnum,
        _2: '2' as TransportTypeEnum,
        _3: '3' as TransportTypeEnum
    };
}