/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface InvoiceType { 
    rowCreatedTimestamp?: Date;
    lastRowModificationTimestamp?: Date;
    isDeleted?: boolean;
    id?: number;
    value?: InvoiceType.ValueEnum;
    description?: string;
}
export namespace InvoiceType {
    export type ValueEnum = 0 | 1;
    export const ValueEnum = {
        NUMBER_0: 0 as ValueEnum,
        NUMBER_1: 1 as ValueEnum
    };
}