/**
 * Forwarding API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ActivityCodeCustomsTaxType } from './activityCodeCustomsTaxType';
import { ActivityCodeGroup } from './activityCodeGroup';
import { ActivityCodeTranslation } from './activityCodeTranslation';

export interface ActivityCode { 
    rowCreatedTimestamp?: Date;
    lastRowModificationTimestamp?: Date;
    isDeleted?: boolean;
    id?: number;
    masterDataStatus?: ActivityCode.MasterDataStatusEnum;
    timeOfBlocking?: Date;
    code?: string;
    newCode?: string;
    description?: string;
    costAccount?: string;
    revenueAccount?: string;
    includeOnCustomsDeclaration?: boolean;
    activityCodeGroupId?: number;
    activityCodeGroup?: ActivityCodeGroup;
    activityCodeTranslations?: Array<ActivityCodeTranslation>;
    linkedCustomsTaxTypes?: Array<ActivityCodeCustomsTaxType>;
}
export namespace ActivityCode {
    export type MasterDataStatusEnum = 0 | 1;
    export const MasterDataStatusEnum = {
        NUMBER_0: 0 as MasterDataStatusEnum,
        NUMBER_1: 1 as MasterDataStatusEnum
    };
}